export const menuItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Projects",
    // url: "/projects",
    submenu: [
      {
        title: "Tank Calculator",
        url: "tank-calculator",
      },
      {
        title: "Character Generator",
        url: "character-generator",
      },
      {
        title:"NPC Manager",
        url:"npc-manager"
      },
      {
        title:"Google Maps",
        url:"google-maps"
      },
      {
        title:"Photo Gallery",
        url:"photo-gallery"

      },
      // {
      //   title:"Dark Mode Test",
      //   url: "dark-mode-test"
      // }
    ],
  },
  //     {
  //       title: 'web design',
  //       url: 'web-design',
  //     },
  //     {
  //       title: 'web development',
  //       url: 'web-dev',
  //       submenu: [
  //         {
  //           title: 'Frontend',
  //           url: 'frontend',
  //         },
  //         {
  //           title: 'Backend',
  //           submenu: [
  //             {
  //               title: 'NodeJS',
  //               url: 'node',
  //             },
  //             {
  //               title: 'PHP',
  //               url: 'php',
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: 'SEO',
  //       url: 'seo',
  //     },
  //   ],
  // },
  // {
  //   title: 'About',
  //   url: '/about',
  //   submenu: [
  //     {
  //       title: 'Who we are',
  //       url: 'who-we-are',
  //     },
  //     {
  //       title: 'Our values',
  //       url: 'our-values',
  //     },
  //   ],
  // },
];
