import { Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Profile from "../routes/Profile";
import TankCalculator from "../routes/TankCalculator";
import CharacterGenerator from "../routes/CharacterGenerator";
import NPCManager from "../routes/NPCManager";
import Map from "../routes/Map";
import PhotoGallery from "../routes/PhotoGallery";

// import DarkModeTest from '../routes/DarkModeTest'

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Profile />} />
          <Route path="tank-calculator" element={<TankCalculator />} />
          <Route path="character-generator" element={<CharacterGenerator />} />
          <Route path="npc-manager" element={<NPCManager />} />
          <Route path="google-maps" element={<Map />} />
          <Route path="photo-gallery" element={<PhotoGallery />} />
          {/* <Route path="dark-mode-test" element={<DarkModeTest />} /> */}
          {/* <Route path="web-dev" element={<WebDev />} />
          <Route path="frontend" element={<Frontend />} />

          <Route path="*" element={<p>Not found!</p>} /> */}
        </Route>
      </Routes>
    </>
  );
};

export default App;
