import { Link } from "react-router-dom";

function Profile() {
  return (
    <div className="m-8 flex justify-center font-less lg:py-5 ">
      <div className="flex flex-col">
        <div
          id="name"
          className="py-4 font-noirReg text-6xl  text-light-thirty"
        >
          Dan Moriarty
        </div>
        <div id="profile" className="text-l">
          <div className="lg: mb-5 max-w-4xl text-xl">
            Welcome to my portfolio website!
          </div>
          <div className="lg: max-w-4xl indent-8">
            My current project involves{" "}
            <Link to="google-maps" className="font-lessBold text-light-ten">
              connecting to the Google Maps API and a Firebase DB
            </Link>
            , meant to gain experience working with both APIs and backends. I am
            also working on a{" "}
            <Link to="photo-gallery" className="font-lessBold text-light-ten">
              photo gallery
            </Link>{" "}
            to share some of my photos and learn more about backend storage.
          </div>
          <br />
          <div className="lg: max-w-4xl indent-8">
            As a self-taught developer with two years of experience, I have
            honed my skills in Javascript, HTML, and CSS. I've chosen to
            specialize in React and Tailwind frameworks to build attractive
            web-based projects quickly. I am always eager to learn and have also
            taken several courses on networking, cyber security, Linux, and data
            analysis. My proficiency in data analysis and network security is
            demonstrated by completing the{" "}
            <a
              className="font-lessBold text-light-ten"
              href="https://www.credly.com/badges/dc0b0dc4-8f9b-40a1-9e05-db74ab6dbb28?source=linked_in_profile"
            >
              Google Data Analysis certification
            </a>{" "}
            and ranking in the top 2% on{" "}
            <a
              className="font-lessBold text-light-ten"
              href="https://tryhackme.com/p/daniel.moriarty9"
            >
              TryHackMe.com
            </a>
            . I am confident that my passion for technology and ability to adapt
            quickly to new challenges would make me an invaluable asset to your
            team.
          </div>
          <br />
          <div className="lg: max-w-4xl indent-8">
            I'm fascinated by the endless possibilities software development
            offers. The ability to solve complex problems in new and innovative
            ways is what makes this field so compelling to me. What's more, the
            constant learning and experimentation required to excel in software
            development is a method of learning that resonates with me. I find
            that trying new things is the best way to gain new knowledge and
            skills. Additionally, the creative and curious nature of software
            development keeps me engaged and motivated, even during challenging
            or mundane tasks. I look forward to bringing my passion for
            problem-solving, innovation, and creativity to my work as a junior
            developer.
          </div>
          <br />
          <div className="lg: max-w-4xl indent-8">
            I have spent the last 6 years in the liquor industry. As cocktail
            room manager I found myself using data in order to direct the menu
            and events programming. In my spirits production role I learned to
            use Excel for recipe development, which opened my eyes to the
            possibilities that programming offers. I've since created a volume
            calculator app for the distillery's milk-tank-turned-mixing-tank,
            which quickly converts milk pounds to gallons and liters and is used
            almost daily. Rewriting that calculator in JS and later in React
            opened my eyes to the potential of web applications.
          </div>
          <br />
          <div className="lg: max-w-4xl indent-8">
            I'm excited to continue honing my skills in web development,
            programming, and other related areas. I'm eager to explore how these
            skills can be applied to other interests I have, such as 3D design
            or carpentry. Furthermore, I plan to leverage this platform to
            showcase some of my other passions, including photography and 3D
            modeling. Overall, I'm looking forward to growing as a developer and
            using my diverse skill set to create meaningful projects that
            reflect my unique background and interests.
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}

export default Profile;
