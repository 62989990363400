import {menuItems} from '../menuItems';
import MenuItems from './MenuItems';
import LogoButton from './LogoButton';



const Navbar = () => {
    return (
      <nav>
        <ul className="menus inline-block font-noirMed">
        <LogoButton />
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
        })}
        </ul>
      </nav>
    );
  };
  
  export default Navbar;