import ReactDOM from 'react-dom';
import CharSheet from './CharSheet';
import {PrinterIcon} from "@heroicons/react/24/outline";

const GenerateButton = ({ newCharacter }) => {

    const charSheetWindow = () =>  {
        const newWindow = window.open('', '_blank');
        newWindow.document.write(`
        <html lang="en">
          <head>
            <meta charset="utf-8">
            <title>${newCharacter.name.fullName}</title>
            <link rel="stylesheet" href='CharSheet.css' />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Grenze:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap">
          </head>
          <body>
            <div id="root"></div>
          </body>
        </html>
      `);
        ReactDOM.render(<CharSheet newCharacter={newCharacter}/>, newWindow.document.getElementById('root'));
    };

    return (
<button className="flex items-center justify-center" onClick={charSheetWindow}>
  <PrinterIcon className="w-5 h-5 inline-block mr-2" />
  <span className="text-center">Printable Character Sheet</span>
</button>
    );

};
export default GenerateButton;

