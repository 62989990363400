import { useState, useEffect } from "react";
import TankMath from "../components/TankMath";

function TankCalculator() {
  const [inputs, setInputs] = useState(
    () => JSON.parse(localStorage.getItem("TANK_INPUTS")) || {}
  );

  const [outputs, setOutputs] = useState({
    milkPounds: 0,
    liters: 0,
    gallons: 0,
  });
  const [showOutputs, setShowOutputs] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { inches = 0, fractions = 0 } = inputs;
    const { milkPounds, liters, gallons } = TankMath({ inches, fractions });
    setOutputs({ milkPounds, liters, gallons });
    setShowOutputs(true);
  };

  useEffect(() => {
    localStorage.setItem("TANK_INPUTS", JSON.stringify(inputs));
  }, [inputs]);

  return (
    <div className="m-4 mx-auto max-w-5xl rounded-sm  bg-light-sixty font-less lg:py-5">
      <div className="m-4 p-4 ">
        <div className="mb-2  pb-2 text-center font-noirReg text-6xl text-light-thirty">
          Milk Tank Volume Calculator
        </div>

        <div className="text-l">
          <div className="font-noirReg">
            This is a volume calculator written for use with the Meuller Model M
            500 gallon milk tank. Each milk tank is supposed to be calibrated on
            site after being positioned on a stable, level surface. The
            calculations used here are for a specific tank and so will likely
            vary if used on any other tank by at least a small amount. Since the
            tank that this calculator is for isn't used for milk, this
            calculator inputs inches of milk and outputs gallons and liters of
            liquid. The calculations are based on the assumption that 1 gallon
            of milk weighs 8.6lbs, which seems to be the industry standard. None
            of Muellers documentation indicates an assumed weight.
          </div>
          <div className="py-2 text-2xl ">
            To determine the volume of liquid in the milk tank, enter the
            measurement in inches as indicated by the tank measuring stick.{" "}
          </div>
        </div>
      </div>

      <div className="min-w-3xl flex flex-col items-center justify-center sm:m-4 sm:p-4">
        <form onSubmit={handleSubmit} className="flex flex-wrap">
          <label className="m-4  flex h-12 items-center">
            <div className="inline-block h-full rounded-tl-sm rounded-bl-sm border-2 border-light-thirty bg-light-thirty p-3 font-bold text-light-sixty">
              Inches
            </div>
            <input
              className="inline-block h-full w-20 rounded-tr-sm rounded-br-sm border-2 border-light-thirty p-3 text-center"
              type="number"
              name="inches"
              value={inputs.inches || ""}
              placeholder="0-32"
              onChange={handleChange}
              min="0"
              max="32"
            />
          </label>

          <label className="m-4 flex h-12 items-center">
            <div className="inline-block h-full rounded-tl-sm rounded-bl-sm border-2 border-light-thirty bg-light-thirty p-3 font-bold text-light-sixty">
              Fractions
            </div>
            <input
              className="inline-block h-full w-20 rounded-tr-sm rounded-br-sm border-2 border-light-thirty p-3 text-center"
              type="number"
              name="fractions"
              value={inputs.fractions || ""}
              placeholder="0-31"
              onChange={handleChange}
              min="0"
              max="31"
            />
          </label>

          <div className="flex flex-col items-center justify-center">
            <input
              className="m-4 flex h-12 cursor-pointer items-center rounded-sm border-2 border-transparent bg-light-ten p-2 font-bold text-light-sixty hover:border-light-ten hover:bg-light-sixty hover:text-light-ten"
              type="submit"
            />
          </div>
        </form>

        <div className="justify-center">
          {showOutputs && (
            <div className="m-4  mx-auto rounded-sm border-2 border-light-thirty bg-light-thirty p-4 text-center text-2xl text-light-sixty lg:text-6xl">
              <h3>{outputs.milkPounds} Milk Pounds</h3>
              <h3>{outputs.liters} Liters</h3>
              <h3>{outputs.gallons} Gallons</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TankCalculator;
