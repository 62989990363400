
import gitMark from './../images/github-mark.svg';
import linkedMark from './../images/linkedin-mark.svg';

function Socials() {
    return (
        <div className="container mx-auto md:mx-0 lg:px-6">
            <div className="flex justify-center md:justify-start">
                <a href="https://github.com/dm1940k" target="_blank" rel="noopener noreferrer">
                    <img src={gitMark} alt="Github Link" className="w-12 md:w-16 h-auto" />
                </a>
                <div className="px-2"></div>
                <a href="https://www.linkedin.com/in/d-moriarty" target="_blank" rel="noopener noreferrer">
                    <img src={linkedMark} alt="LinkedIn Link" className="w-12 md:w-16 h-auto" />
                </a>
            </div>
            <div className="p-4"></div>
        </div>
    );
}

export default Socials