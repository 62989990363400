import { Link } from "react-router-dom";
import Logo from "./Personal Logo 2023.svg";

const LogoButton = () => {
  return (
    <Link to="/" className=" p-2">
      <img
        className="inline-block"
        src={Logo}
        alt="Logo link"
        style={{ width: "100px", height: "auto"}}
      />
    </Link>
  );
};
export default LogoButton;
