import React, { useState, useEffect } from "react";
import { TrashIcon, PaintBrushIcon } from "@heroicons/react/24/outline";

function NPCManager() {
  const [npcs, setNpcs] = useState([]);

  useEffect(() => {
    const savedNpcs = JSON.parse(localStorage.getItem("NPC_DATA")) || [];
    if (savedNpcs.length > 0) {
      setNpcs(savedNpcs);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("NPC_DATA", JSON.stringify(npcs));
  }, [npcs]);

  const addNpc = () => {
    setNpcs([
      ...npcs,
      {
        name: "",
        totalhp: "",
        currenthp: "",
        armor: "",
        attack: "",
        notes: "",
        colorIndex: 0,
      },
    ]);
  };

  const handleNpcChange = (event, index) => {
    const { name, value } = event.target;
    const newNpcs = [...npcs];
    newNpcs[index][name] = value;
    setNpcs(newNpcs);
  };

  const colorList = [
    "bg-light-thirty",
    "bg-light-thirty-alt-one",
    "bg-light-thirty-alt-two",
    "bg-light-thirty-alt-three",
    "bg-light-thirty-alt-four",
    "bg-light-thirty-alt-five",
  ];
  const colorList2 = [
    "border-light-thirty",
    "border-light-thirty-alt-one",
    "border-light-thirty-alt-two",
    "border-light-thirty-alt-three",
    "border-light-thirty-alt-four",
    "border-light-thirty-alt-five",
  ];

  const handleNpcChangeAndColor = (event, index) => {
    const { name, value } = event.target;
    const element = event.target.closest("button");
    const card = element.parentElement.parentElement.parentElement;
    const newNpcs = [...npcs];
    const npc = newNpcs[index];

    // Update NPC data
    npc[name] = value;

    // Change NPC card color
    npc.colorIndex = (npc.colorIndex + 1) % colorList.length;
    card.classList.remove(...colorList);
    card.classList.remove(...colorList2);
    card.classList.add(colorList[npc.colorIndex]);
    card.classList.add(colorList2[npc.colorIndex]);

    setNpcs(newNpcs);
  };

  const deleteNpc = (index) => {
    const newNpcs = [...npcs];
    newNpcs.splice(index, 1);
    setNpcs(newNpcs);
  };

  return (
    <div className="m2 w-50 flex flex-row justify-center lg:m-10">
      <div className=" font-less">
        <div className="flex justify-center p-4 text-center font-noirReg text-6xl text-light-thirty lg:py-6">
          NPC Manager
        </div>
        <div className="m-2 grid gap-4  lg:grid-cols-3">
          {npcs.map((npc, index) => (
            <div
              className={`${colorList[npc.colorIndex]} ${
                colorList2[npc.colorIndex]
              } m-2 rounded-sm border-2`}
              key={index}
            >
              <label className="m-4 flex h-12 items-center">
                <div className="my-2 inline-block h-full w-full  p-3 font-bold text-light-sixty">
                  Name:
                </div>
                <input
                  className="w-50 inline-block h-full rounded-sm border-2 border-light-sixty p-3 text-center"
                  type="text"
                  name="name"
                  placeholder="NPC"
                  value={npc.name || ""}
                  onChange={(event) => handleNpcChange(event, index)}
                />
              </label>
              <label className="m-4 flex h-12 items-center">
                <div className="my-2 inline-block h-full w-full p-3 font-bold text-light-sixty">
                  Health:
                </div>
                <div className=" inline-flex h-12 items-center justify-center rounded-sm">
                  <input
                    className="w-20 rounded-sm border-2 border-light-sixty p-3 text-center"
                    type="text"
                    name="currentHp"
                    placeholder="0"
                    value={npc.currentHp || ""}
                    onChange={(event) => handleNpcChange(event, index)}
                  />
                  <span className="px-2 text-2xl font-bold text-light-sixty">
                    /
                  </span>
                  <input
                    className="w-20 rounded-sm border-2 border-light-sixty p-3 text-center"
                    type="text"
                    name="totalHp"
                    placeholder="0"
                    value={npc.totalHp || ""}
                    onChange={(event) => handleNpcChange(event, index)}
                  />
                </div>
              </label>
              <label className="m-4 flex h-12 items-center">
                <div className=" my-2 inline-block h-full w-full p-3 font-bold text-light-sixty">
                  Armor:
                </div>
                <input
                  className="inline-block h-full w-40 rounded-sm border-2 border-light-sixty p-3 text-center"
                  type="text"
                  name="armor"
                  placeholder="0"
                  value={npc.armor || ""}
                  onChange={(event) => handleNpcChange(event, index)}
                />
              </label>
              <label className="m-4 flex h-12 items-center">
                <div className="my-2 inline-block h-full w-full  p-3 font-bold text-light-sixty">
                  Attack:
                </div>
                <input
                  className="inline-block h-full w-40 rounded-sm border-2 border-light-sixty p-3 text-center"
                  type="text"
                  name="attack"
                  placeholder="1d6"
                  value={npc.attack || ""}
                  onChange={(event) => handleNpcChange(event, index)}
                />
              </label>
              <label className="mx-4 mt-4 flex flex-col">
                <div className="h-12 w-full  p-3 font-bold text-light-sixty">
                  Notes:
                </div>
                <textarea
                  className="inline-block h-full w-full resize-none rounded-sm border-2 border-light-sixty p-3"
                  rows="4"
                  cols="20"
                  type="text"
                  name="notes"
                  placeholder="Skills, Equipment, Loot, Etc..."
                  value={npc.notes || ""}
                  onChange={(event) => handleNpcChange(event, index)}
                />
              </label>

              <div className="flex">
                <div className="flex flex-1 justify-start">
                  <button
                    className="m-2 text-xl font-bold text-light-thirty"
                    onClick={(e) => handleNpcChangeAndColor(e, index)}
                  >
                    <PaintBrushIcon
                      className="m-2 h-10 w-10 stroke-light-sixty"
                      aria-hidden="true"
                    />
                  </button>
                </div>
                <div className="flex flex-1 justify-end">
                  <button
                    className="m-2 text-xl font-bold text-light-thirty "
                    onClick={() => deleteNpc(index)}
                  >
                    <TrashIcon
                      className="m-2 h-10 w-10 stroke-light-sixty"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          ))}
          <button
            className=" border-4 border-dashed border-light-ten px-40 py-60 text-6xl text-light-ten"
            onClick={addNpc}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
}

export default NPCManager;
