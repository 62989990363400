import { useMemo, useEffect, useState } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { firestore } from "../firebase";

function Home() {
  const [apiKey, setApiKey] = useState("");
  const [isApiKeyLoaded, setIsApiKeyLoaded] = useState(false);

  const fetchApiKey = async () => {
    const request = await firestore.collection("keys").doc("api").get();
    const data = request.data();
    const apiKey = data["google-maps"];
    setApiKey(apiKey);
    setIsApiKeyLoaded(true);
  };

  useEffect(() => {
    fetchApiKey();
  }, []);

  if (!isApiKeyLoaded) return <div>Loading...</div>;
  if (!apiKey) return <div>No API key found.</div>;

  return <Map apiKey={apiKey} />;
}

function Map({ apiKey }) {
  const center = useMemo(() => ({ lat: 44.95, lng: -93.2 }), []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  if (loadError) return <div className="font-less">Error loading maps</div>;
  if (!isLoaded) return <div className="font-less">Loading...</div>;

  return (

    
    <div className="font-less text-light-thirty">
      <div className=" text-center text-6xl font-noirMed">Google + Firebase</div>
      <div className="text-center text-xl ">Firebase Firestore is used to securely store a Google Maps API key. </div>
      <div className="text-center text-xl ">(More to come) </div>
      <div className="m-5 rounded-sm border-4 border-light-thirty">
        <GoogleMap
          zoom={10}
          center={center}
          mapContainerStyle={{ width: "100%", height: "100vh" }}
          options={{ apiKey: apiKey }}
        >
          <MarkerF position={center} />
        </GoogleMap>
      </div>
    </div>
  );
}
export default Home;
