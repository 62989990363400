import NavBar from "./NavBar";
function Header() {
    return (
        <header>
            <div className="bg-light-sixty m-4">
                <NavBar />
            </div>
        </header>
    )
}

export default Header