function CharacterFlavor() {
  var tracery = require("tracery-grammar");
  var grammar = tracery.createGrammar({
    traits: [
      "They have a calm demeanor and rarely lose their composure, even in difficult situations.",
      "They exude confidence and are never afraid to speak their mind.",
      "They are naturally curious and always eager to learn new things.",
      "They have a strong sense of empathy and are able to put themselves in other people's shoes.",
      "They are naturally friendly and enjoy meeting new people.",
      "They value honesty above all else and always tell the truth, even when it's difficult.",
      "They are introspective and enjoy spending time alone to reflect on their thoughts and feelings.",
      "They are naturally optimistic and always try to see the best in people and situations.",
      "They are passionate about their beliefs and will go to great lengths to defend them.",
      "They have a quick wit and a great sense of humor, always ready with a clever joke or quip.",
      "They are resourceful and always able to find a way to overcome challenges.",
      "They have a strong sense of justice and always strive to do what is right, even if it's not the easiest path.",
      "They are naturally curious and enjoy exploring new places and trying new things.",
      "They have a deep appreciation for beauty and art, and enjoy expressing themselves creatively.",
      "They are driven and always strive to achieve their goals, no matter how difficult.",
      "They are independent and enjoy doing things on their own, without relying on others.",
      "They have a strong sense of responsibility and always take their commitments seriously.",
      "They are naturally charismatic and have a way of drawing people to them.",
      "They are always willing to help others, and find fulfillment in making a positive impact in people's lives.",
      "They have a deep love of nature and enjoy spending time outdoors.",
      "They are adaptable and able to handle change and uncertainty with ease.",
      "They have a strong sense of curiosity and enjoy asking questions and seeking knowledge.",
      "They are introspective and enjoy exploring their own thoughts and feelings.",
      "They have a deep sense of loyalty and always stand by their friends and loved ones.",
      "They are naturally intuitive and able to sense the emotions of others without being told.",
      "They have a strong work ethic and always strive to give their best effort in everything they do.",
      "They are naturally inquisitive and enjoy exploring the unknown.",
      "They have a deep appreciation for tradition and history, and enjoy learning about the past.",
      "They have a strong sense of adventure and enjoy taking risks and trying new things.",
      "They have a natural talent for leadership and are able to inspire and motivate others to achieve their goals.",
      "They have a deep sense of compassion and are always willing to lend a listening ear to those in need.",
    ],

    flaws: [
      "They are irritable and short-tempered, easily provoked.",
      "They say exactly what they're thinking, even if it's not polite or appropriate.",
      "They are easily frightened and quick to run away from danger.",
      "They have a strong desire for food and drink, and have trouble controlling themselves.",
      "They habitually lie and deceive others for their own gain.",
      "They have a strong dislike or distrust of certain races, cultures, or individuals.",
      "They prioritize their own interests over others', and have little regard for their feelings.",
      "They hold grudges and seek revenge against those who have wronged them.",
      "They have an inflated sense of their own importance and abilities.",
      "They often forget things or get distracted easily, sometimes to the detriment of themselves or others.",
    ],
    bonds: [
      "They are fiercely loyal to their friends and allies.",
      "They have a deep respect for the traditions and values of their homeland.",
      "They have a specific goal or mission that they are committed to achieving.",
      "They have a family member or loved one whom they will do anything to protect.",
      "They owe a life debt to someone who saved their life or helped them in the past.",
      "They are seeking revenge against a specific person or group who wronged them.",
      "They have a close relationship with a deity or other powerful being.",
      "They are a member of a secret society or organization that they are committed to protecting.",
      "They are searching for a specific object or artifact of great importance.",
      "They have sworn an oath or made a promise that they must keep at all costs.",
    ],
    ideals: [
      "They believe in justice and the rule of law, and work to uphold these principles.",
      "They value compassion and strive to help those in need, regardless of race or status.",
      "They are committed to protecting their community and standing up against oppression.",
      "They believe in honor and duty, and always keep their word.",
      "They seek to live in harmony with nature and protect the environment.",
      "They strive for self-improvement and seek to better themselves and the world around them.",
      "They value knowledge and believe that understanding and education are the keys to progress.",
      "They believe in freedom and personal autonomy, and reject all forms of tyranny and oppression.",
      "They seek to bring order to a chaotic world, and are willing to make sacrifices for the greater good.",
      "They are driven by a strong sense of faith and seek to spread their beliefs to others.",
    ],
  });

  grammar.addModifiers(tracery.baseEngModifiers);

  return {
    personalityOne: grammar.flatten("#traits#"),
    personalityTwo: grammar.flatten("#traits#"),
    flaw: grammar.flatten("#flaws#"),
    bond: grammar.flatten("#bonds#"),
    ideal: grammar.flatten("#ideals#"),
  };
}

export default CharacterFlavor;
