import React, { useState, useEffect } from "react";
// import shrek from "../images/shrek.jpg";
import { storage } from "../firebase";

function PhotoGallery() {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const openModal = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setShowModal(false);
  };

  useEffect(() => {
    // Create a reference to the Firebase Storage file
    const fileRef = storage.ref("Photographs");

    // Get the URLs of the images in the file
    fileRef.listAll().then((res) => {
      const urls = [];
      res.items.forEach((itemRef) => {
        itemRef.getDownloadURL().then((url) => {
          urls.push(url);
          setImageUrls([...urls]);
        });
      });
    });
  }, []);

  return (
    <div>
      <div>
        <div className="m-5 font-less text-center text-light-thirty"> 
          <div className="p-5 font-noirReg text-6xl  ">
            Photo Gallery
          </div>
          <div className="p-5">
            In Progress
          </div>
        </div>
        <div className="m-5 grid grid-cols-2 gap-5 md:grid-cols-3 lg:grid-cols-4  ">
          {imageUrls.map((url, index) => (
            <div className="">
              <img
                key={index}
                className={` rounded-md transition duration-300 hover:scale-105 w-full object-cover object-center lg:h-screen/2 md:h-screen/3 h-screen/4`}
                src={url}
                alt={`Image ${index}`}
                onClick={() => openModal(url)}
              />
            </div>
          ))}
        </div>
      </div>
      {showModal && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
          onClick={closeModal}
        >
          <img
            src={selectedImage}
            alt="Enlarged Image"
            className="max-h-full max-w-full"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </div>
  );
}
export default PhotoGallery;
