import Socials from "./Socials";

function Footer() {

    return(
        <div className="bg-light-sixty" >
            <footer>
                <Socials />
            </footer>
        </div>
    )
}

export default Footer