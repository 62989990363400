import CharacterFlavor from "./CharacterFlavor";

const CharacterMath = ({ level, charClass, race, alignment, background }) => {
  //9/22/22 - Things left to do:

  /*Compile the class function
    
    Add saving throw proficiencies to class, race, anywhere else
    
    Add flaws, bonds, personality, and bonds (low priority)*/

  //Names
  let human_first_name_male = [
    "Arando",
    "Bram",
    "Cale",
    "Dalkon",
    "Daylen",
    "Dodd",
    "Dungarth",
    "Dyrk",
    "Eandro",
    "Falken",
    "Feck",
    "Fenton",
    "Gryphero",
    "Hagar",
    "Jeras",
    "Krynt",
    "Lavant",
    "Leyten",
    "Madian",
    "Malfier",
    "Markus",
    "Meklan",
    "Namen",
    "Navaren",
    "Nerle",
    "Nilus",
    "Ningyan",
    "Norris",
    "Quentin",
    "Semil",
    "Sevenson",
    "Steveren",
    "Talfen",
    "Tamond",
    "Taran",
    "Tavon",
    "Tegan",
    "Vanan",
    "Vincent",
  ];
  let human_first_name_female = [
    "Azura",
    "Brey",
    "Hallan",
    "Kasaki",
    "Lorelei",
    "Mirabel",
    "Pharana",
    "Remora",
    "Rosalyn",
    "Sachil",
    "Saidi",
    "Tanika",
    "Tura",
    "Tylsa",
    "Vencia",
    "Xandrilla",
    "Gerdi",
    "Anabel",
    "Giana",
    "Kimble",
    "Gulja",
    "Nadine",
    "Julchen",
    "Clarissa",
    "Petra",
    "Nikoletta",
    "Savannah",
    "Sage",
    "Mine",
    "Shaniya",
    "Carry",
    "Esra",
  ];
  let human_last_name = [
    "Arkalis",
    "Armanci",
    "Bilger",
    "Blackstrand",
    "Brightwater",
    "Carnavon",
    "Caskajaro",
    "Coldshore",
    "Coyle",
    "Cresthill",
    "Cuttlescar",
    "Daargen",
    "Dalicarlia",
    "Danamark",
    "Donoghan",
    "Drumwind",
    "Dunhall",
    "Ereghast",
    "Falck",
    "Fallenbridge",
    "Faringray",
    "Fletcher",
    "Fryft",
    "Goldrudder",
    "Grantham",
    "Graylock",
    "Gullscream",
    "Hindergrass",
    "Iscalon",
    "Kreel",
    "Kroft",
    "Lamoth",
    "Leerstrom",
    "Lynchfield",
    "Moonridge",
    "Netheridge",
    "Oakenheart",
    "Pyncion",
    "Ratley",
    "Redraven",
    "Revenmar",
    "Roxley",
    "Sell",
    "Seratolva",
    "Shanks",
    "Shattermast",
    "Shaulfer",
    "Silvergraft",
    "Stavenger",
    "Stormchapel",
    "Strong",
    "Swiller",
    "Talandro",
    "Targana",
    "Towerfall",
    "Umbermoor",
    "Van-Devries",
    "Van-Gandt",
    "Van-Hyden",
    "Varcona",
    "Varzand",
    "Voortham",
    "Vrye",
    "Webb",
    "Welfer",
    "Wilxes",
    "Wintermere",
    "Wygarthe",
    "Zatchet",
    "Zethergyll",
  ];
  let orc_first_name_male = [
    "Grog",
    "Shug",
    "Gukk",
    "Lok",
    "Drorak",
    "Gagrab",
    "Jobol",
    "Orlab",
    "Sharlad",
    "Ovoll",
    "Bun",
    "Rhod",
    "Jun",
    "Bhag",
    "Dharakk",
    "Ghoggoll",
    "Zhanzukk",
    "Drangak",
    "Gomzar",
    "Zhutrull",
    "Ghol",
    "Zod",
    "Dhug",
    "Bokk",
    "Zhukzakk",
    "Zokzud",
    "Bhozukk",
    "Bhakzoll",
    "Grobrob",
    "Obrun",
    "Ghukk",
    "Brag",
    "Bak",
    "Murzob",
    "Bhumzok",
    "Dazug",
    "Zhajud",
    "Druggub",
    "Drogran",
    "Dhakk",
  ];
  let orc_first_name_female = [
    "Dhem",
    "Rhaf",
    "Kuam",
    "Kil",
    "Iggal",
    "Dothi",
    "Dhauthiv",
    "Dhadvau",
    "Nuwgouh",
    "Bhinzik",
    "Son",
    "Maov",
    "Niv",
    "Sah",
    "Eggim",
    "Semdev",
    "Dhithi",
    "Bamdik",
    "Odao",
    "Bhadva",
    "Bheng",
    "Zyem",
    "Rhiz",
    "Sing",
    "Zudvuaz",
    "Kewgo",
    "Nogveeh",
    "Bhaomduf",
    "Zudaum",
    "Mevryez",
    "Mun",
    "Duk",
    "Dav",
    "Gul",
    "Nudguk",
    "Uwgu",
    "Ghegzom",
    "Nodvoh",
    "Shaamduz",
    "Viwgauk",
  ];
  let orc_last_name = [
    "Blood Render",
    "The Bitter",
    "The Miscreant",
    "Iron Squasher",
    "The Repulsive",
    "Kill Dissector",
    "The Defiant",
    "The Unsightly",
    "Beast Clobberer",
    "Scale Quasher",
    "Beast Cleaver",
    "The Maniac",
    "The Butcher",
    "Toe Quelcher",
    "Chest Queller",
    "Foot Defacer",
    "The Angry",
    "The Cruel",
    "War Despoiler",
    "The Prime",
    "The Wild",
    "The Macabre",
    "Tooth Masher",
    "Head Cleaver",
    "The Venomous",
    "Sorrow Shatterer",
    "The Turbulent",
    "The Swift",
    "Storm Cutter",
    "The Grim",
    "The Wrathful",
    "Sorrow Despoiler",
    "Foot Shatterer",
    "Power Lance",
    "Tooth Flayer",
    "Chin Squisher",
    "Joint Shatterer",
    "The Forsaken",
    "Flesh Splitter",
    "Knee Despoiler",
  ];
  let elf_first_name_male = [
    "Herhice",
    "Wranydark",
    "Waeslen",
    "Dorfir",
    "Qizumin",
    "Sylneiros",
    "Omapetor",
    "Aeqen",
    "Cramenor",
    "Norzumin",
    "Ralotumal",
    "Sylren",
    "Glynmenor",
    "Wranric",
    "Fenren",
    "Herpeiros",
    "Morjeon",
    "Vasalor",
    "Morbalar",
    "Lujor",
    "Waesquinal",
    "Kelneiros",
    "Aeyarus",
    "Olonan",
    "Raloquinal",
    "Leoberos",
    "Keamyar",
    "Yinmyar",
    "Patumal",
    "Ianceran",
    "Sardithas",
    "Rolar",
    "Ralosalor",
    "Baljeon",
    "Daepetor",
    "Cracan",
    "Daepeiros",
    "Cranelis",
    "Thevalur",
    "Raloren",
  ];
  let elf_first_name_female = [
    "Vallynn",
    "Loracaryn",
    "Liarona",
    "Lorabanise",
    "Magrona",
    "Daeleth",
    "Gilmoira",
    "Ensys",
    "Heletris",
    "Enna",
    "Xyrwynn",
    "Xyrna",
    "Yesgwyn",
    "Sylfina",
    "Dadove",
    "Holarieth",
    "Triscyne",
    "Maglynn",
    "Qithana",
    "Miaroris",
    "Nericaryn",
    "Inadi",
    "Neriwenys",
    "Krisdove",
    "Ulana",
    "Reyxina",
    "Xyrcyne",
    "Valrona",
    "Zinfiel",
    "Keydove",
    "Triswenys",
    "Holaxina",
    "Ravana",
    "Gilna",
    "Torrona",
    "Valwenys",
    "Greleth",
    "Phirel",
    "Xyrgella",
    "Liasatra",
  ];
  let elf_last_name = [
    "Woodhold",
    "Amberheart",
    "Woodshadow",
    "Stillshadow",
    "Shadowflow",
    "Viere",
    "Rosiscar",
    "Flomalenn",
    "Dialdahan",
    "Maxelathrosh",
    "Gemhold",
    "Swiftbell",
    "Sagewatcher",
    "Crimsonsinger",
    "Fargift",
    "Ostril",
    "Kithronthri",
    "Eisesti",
    "Soldrel",
    "Uldish",
    "Elderdream",
    "Sageshadow",
    "Evenspark",
    "Ashstar",
    "Hazelmind",
    "Xeshua",
    "Heylas",
    "Fluriil",
    "Estith",
    "Keyldrirren",
    "Evenpride",
    "Flowerbreath",
    "Ashsense",
    "Aldershard",
    "Lunarflower",
    "Mistrexalko",
    "Shollo",
    "Waeso",
    "Hialolkoront",
    "Wurathre",
  ];
  let dwarf_first_name_male = [
    "Bhelduhr",
    "Garrak",
    "Hjolnus",
    "Thurdain",
    "Banbek",
    "Ummyr",
    "Bennir",
    "Bunthran",
    "Baerdram",
    "Thomond",
    "Brammund",
    "Branmek",
    "Tormyl",
    "Hjalgurn",
    "Adrigg",
    "Krommand",
    "Hjulron",
    "Kramthran",
    "Bheldor",
    "Muirgron",
    "Muirmar",
    "Belnir",
    "Bromnik",
    "Vongrom",
    "Galnom",
    "Benmiir",
    "Muirdrus",
    "Gerdrak",
    "Urmbrek",
    "Hortharn",
    "Tynam",
    "Thynam",
    "Murkyl",
    "Bhalkom",
    "Balmek",
    "Bramdrus",
    "Thulnik",
    "Ermgrun",
    "Bhelmand",
    "Fargran",
  ];
  let dwarf_first_name_female = [
    "Tyshmyl",
    "Tyshbera",
    "Bronwin",
    "Reddelle",
    "Bronvia",
    "Runlin",
    "Bartin",
    "Tishras",
    "Reyntyn",
    "Misnora",
    "Jinwynn",
    "Daermura",
    "Edri",
    "Reynwaen",
    "Brilva",
    "Tasres",
    "Naerros",
    "Reynsyl",
    "Tyshnura",
    "Elnas",
    "Tyswynn",
    "Lasnas",
    "Tazsyl",
    "Arva",
    "Dimgwyn",
    "Redbelle",
    "Lesnyss",
    "Barleil",
    "Eindeth",
    "Jindelle",
    "Brulnip",
    "Tasnar",
    "Tasva",
    "Jenra",
    "Lyesla",
    "Nysgwyn",
    "Gwanthel",
    "Naerlin",
    "Jynra",
    "Elrin",
  ];
  let dwarf_last_name = [
    "Blackshield",
    "Bravesong",
    "Evenhelm",
    "Sternboots",
    "Evenpride",
    "Calbrikick",
    "Dulbragert",
    "Cozzonann",
    "Culbrenn",
    "Thraengakaln",
    "Trollfists",
    "Broodale",
    "Sternkith",
    "Loudheart",
    "Hardgrace",
    "Gunhunor",
    "Glurikug",
    "Fefdam",
    "Borovuhr",
    "Datgur",
    "Boldfront",
    "Ironkind",
    "Frozenmane",
    "Firstfeast",
    "Marblebleeder",
    "Ballivert",
    "Thrathgark",
    "Caduln",
    "Grefdahin",
    "Grellerk",
    "Blacktankard",
    "Irontale",
    "Stormbleeder",
    "Steelstand",
    "Bravebelt",
    "Luthgikark",
    "Leham",
    "Dererr",
    "Dengogack",
    "Threngort",
  ];
  let gnome_first_name_male = [
    "Warser",
    "Jebis",
    "Urivyn",
    "Panapos",
    "Bilpip",
    "Xotix",
    "Yoscorin",
    "Grajin",
    "Jeyur",
    "Dorpos",
    "Calben",
    "Brini",
    "Orpip",
    "Iparug",
    "Davjin",
    "Oruner",
    "Wregim",
    "Felver",
    "Umbar",
    "Jeyur",
    "Farkas",
    "Albar",
    "Mervyn",
    "Xalzu",
    "Urigim",
    "Farrick",
    "Garyn",
    "Rasrug",
    "Saltor",
    "Urikas",
    "Corwin",
    "Sinxif",
    "Briver",
    "Gratix",
    "Enitor",
    "Yosrick",
    "Salfiz",
    "Calmorn",
    "Panafiz",
    "Cormin",
  ];
  let gnome_first_name_female = [
    "Taroe",
    "Kridysa",
    "Pripine",
    "Xayore",
    "Yloxis",
    "Isorhana",
    "Faezyre",
    "Yonove",
    "Prisys",
    "Welpine",
    "Reqaryn",
    "Jelqys",
    "Qixis",
    "Quesys",
    "Klosany",
    "Zinsys",
    "Faeza",
    "Carmiphi",
    "Loriqaryn",
    "Arikasys",
    "Elgyra",
    "Tali",
    "Carna",
    "Orla",
    "Lorisys",
    "Hesgyra",
    "Ylolys",
    "Yoroe",
    "Faekasys",
    "Inatra",
    "Venkini",
    "Jelhani",
    "Daphirhana",
    "Daphibi",
    "Minqys",
    "Eltra",
    "Xanoa",
    "Xaqys",
    "Zinqys",
    "Priqys",
  ];
  let gnome_last_name = [
    "Glittermantle",
    "Silverblock",
    "Marblesong",
    "Marblestitch",
    "Tinkertop",
    "Limmusidell",
    "Tercadol",
    "Songgasoba",
    "Dammlunibi",
    "Ungubers",
    "Copperdrop",
    "Togglepitch",
    "Shortmask",
    "Sparklebraid",
    "Wobblestone",
    "Niddlegeddle",
    "Zirkusebin",
    "Paefureton",
    "Bercitorand",
    "Noonggader",
    "Glitterstand",
    "Togglespring",
    "Brassspell",
    "Sparkleflow",
    "Squigglehold",
    "Humplasebell",
    "Musgatin",
    "Peresaden",
    "Mobblezabble",
    "Tamplelomple",
    "Dazzlegem",
    "Strongtwist",
    "Quillthread",
    "Boldtwist",
    "Deepcheek",
    "Mukkosibel",
    "Oppasarend",
    "Olnosabol",
    "Effiras",
    "Hogglepiggle",
  ];
  let halfling_first_name_male = [
    "Yargin",
    "Merwrick",
    "Anyas",
    "Perrin",
    "Oribin",
    "Idonad",
    "Wilorin",
    "Garser",
    "Garumo",
    "Belwrick",
    "Riry",
    "Wenpher",
    "Pimyas",
    "Sanfer",
    "Conbin",
    "Pimdak",
    "Ricbul",
    "Quinrich",
    "Barmin",
    "Elsire",
    "Wenhace",
    "Gozin",
    "Idoumo",
    "Quinnan",
    "Davpos",
    "Linzor",
    "Zalmo",
    "Yender",
    "Wenry",
    "Belhace",
    "Baros",
    "Corkas",
    "Flynlos",
    "Tarton",
    "Cordon",
    "Sharmin",
    "Merras",
    "Jodak",
    "Erwan",
    "Pimdon",
  ];
  let halfling_first_name_female = [
    "Eraara",
    "Belgrace",
    "Yoyra",
    "Diakis",
    "Yoeni",
    "Riyola",
    "Xivyre",
    "Xanhaly",
    "Idamia",
    "Vafice",
    "Lidora",
    "Wikis",
    "Uvitina",
    "Froris",
    "Nedmia",
    "Graola",
    "Qujen",
    "Diagrace",
    "Nedgrace",
    "Isada",
    "Salyse",
    "Unazana",
    "Hahaly",
    "Eolie",
    "Jaytrix",
    "Paenys",
    "Trynda",
    "Prumia",
    "Shaemita",
    "Shaecaryn",
    "Ariola",
    "Graula",
    "Paezira",
    "Wina",
    "Diari",
    "Malora",
    "Jayna",
    "Lebyn",
    "Kelyola",
    "Uvida",
  ];
  let halfling_last_name = [
    "Elderearth",
    "Teabough",
    "Laughingbrace",
    "Truebloom",
    "Hogblossom",
    "Cherrybottle",
    "Stonecheeks",
    "Rosevale",
    "Biglade",
    "Amberwillow",
    "Undershadow",
    "Longdancer",
    "Earthman",
    "Nighthands",
    "Goldpot",
    "Marbletop",
    "Lightmouse",
    "Reedsky",
    "Mossshine",
    "Hillflower",
    "Springcloak",
    "Greatsun",
    "Bronzesong",
    "Thornhand",
    "Littlevale",
    "Emberace",
    "Tallbraid",
    "Clearhill",
    "Commonspark",
    "Warmsurge",
    "Laughingace",
    "Ravenmane",
    "Fastflow",
    "Hogbough",
    "Heartcheeks",
    "Humblesky",
    "Mossgrove",
    "Sunblossom",
    "Bigstride",
    "Goodtop",
  ];
  let dragonborn_first_name_male = [
    "Golrustaajin",
    "Shuchiadash",
    "Kliccedath",
    "Kilkeacmarrir",
    "Thiphek",
    "Myelrindrareth",
    "Drostoxemaath",
    "Gualteten",
    "Lurialen",
    "Niltaashkmis",
    "Clithtac",
    "Cuchited",
    "Kleldeth",
    "Nyeampheshkmic",
    "Thuris",
    "Myuumphesh",
    "Krulrith",
    "Tirduatimac",
    "Nestuul",
    "Tilthud",
    "Lustaath",
    "Klalmindrodoth",
    "Tocestek",
    "Cephendires",
    "Myordul",
    "Dilkul",
    "Forthok",
    "Kuamtandar",
    "Cruristath",
    "Nuthtukmarec",
    "Drarnintherod",
    "Campush",
    "Giambin",
    "Irdesh",
    "Geankiaxuc",
    "Eltuth",
    "Celxiad",
    "Gerandaman",
    "Finkucmirruc",
    "Shoxar",
  ];
  let dragonborn_first_name_female = [
    "Mexak",
    "Oldeak",
    "Echur",
    "Ilmuukmuar",
    "Vemmaajash",
    "Nyachuukadon",
    "Mernur",
    "Myeamphuak",
    "Nordicmek",
    "Lixik",
    "Vixuukash",
    "Shuuldror",
    "Nencik",
    "Clarjunthurgin",
    "Dreanxesh",
    "Muampexemer",
    "Pralthucmolear",
    "Muapajar",
    "Iphas",
    "Faldash",
    "Inkar",
    "Laxual",
    "Drithtakmash",
    "Shincanthes",
    "Taalkar",
    "Drirthuus",
    "Ilkur",
    "Drerjiser",
    "Kraalkecnaar",
    "Krimpalak",
    "Nyephoriak",
    "Falthekmumok",
    "Nyethtaas",
    "Furjan",
    "Myanxaandik",
    "Eamphadeth",
    "Dualmath",
    "Drilres",
    "Kruumpinthunek",
    "Yeastir",
  ];
  let dragonborn_last_name = [
    "Pabroth",
    "Sulziros",
    "Lorlasar",
    "Medhazar",
    "Lumivroth",
    "Trouqiroth",
    "Brendorim",
    "Yoryax",
    "Erahazar",
    "Heseth",
    "Morqull",
    "Alihazar",
    "Tazlasar",
    "Rhogar",
    "Vyurash",
    "Morghull",
    "Nalasar",
    "Wulsashi",
    "Argrax",
    "Hiturim",
    "Orlalin",
    "Kilwarum",
    "Grejhan",
    "Marsashi",
    "Balskan",
    "Orlaqull",
    "Udolin",
    "Narrakas",
    "Wuzavur",
    "Iorxiros",
    "Zratrin",
    "Medbarum",
    "Otiroth",
    "Brenyax",
    "Gorahadur",
    "Doroth",
    "Urojurn",
    "Morgrax",
    "Frowunax",
    "Tozire",
  ];

  //Utilities
  function randomize(x) {
    var random_number = Math.floor(Math.random() * x.length);
    return random_number;
  }

  // function capitalizeFirstLetter(string) {
  //     return string.charAt(0).toUpperCase() + string.slice(1);
  // }

  function roll_dice(sides, rolls) {
    var numbers = [];
    for (let i = 0; i < rolls; i++) {
      numbers.push(Math.ceil(Math.random() * sides));
    }
    return numbers;
  }

  function namer(gender, char_race) {
    const characterName = new Object();
    switch (char_race) {
      case "dragonborn":
        if (gender === "male") {
          characterName.firstName =
            dragonborn_first_name_male[randomize(dragonborn_first_name_male)];
        } else if (gender === "female") {
          characterName.firstName =
            dragonborn_first_name_female[
              randomize(dragonborn_first_name_female)
            ];
        } else {
          let nameConcat = dragonborn_first_name_female.concat(
            dragonborn_first_name_male
          );
          characterName.firstName = nameConcat[randomize(nameConcat)];
        }
        characterName.lastName =
          dragonborn_last_name[randomize(dragonborn_last_name)];
        break;
      case "dwarf":
        if (gender === "male") {
          characterName.firstName =
            dwarf_first_name_male[randomize(dwarf_first_name_male)];
        } else if (gender === "female") {
          characterName.firstName =
            dwarf_first_name_female[randomize(dwarf_first_name_female)];
        } else {
          let nameConcat = dwarf_first_name_female.concat(
            dwarf_first_name_male
          );
          characterName.firstName = nameConcat[randomize(nameConcat)];
        }
        characterName.lastName = dwarf_last_name[randomize(dwarf_last_name)];
        break;
      case "elf":
        if (gender === "male") {
          characterName.firstName =
            elf_first_name_male[randomize(elf_first_name_male)];
        } else if (gender === "female") {
          characterName.firstName =
            elf_first_name_female[randomize(elf_first_name_female)];
        } else {
          let firstName = elf_first_name_female.concat(elf_first_name_male);
          characterName.firstName = firstName[randomize(firstName)];
        }
        characterName.lastName = elf_last_name[randomize(elf_last_name)];
        break;
      case "gnome":
        if (gender === "male") {
          characterName.firstName =
            gnome_first_name_male[randomize(gnome_first_name_male)];
        } else if (gender === "female") {
          characterName.firstName =
            gnome_first_name_female[randomize(gnome_first_name_female)];
        } else {
          let nameConcat = gnome_first_name_female.concat(
            gnome_first_name_male
          );
          characterName.firstName = nameConcat[randomize(nameConcat)];
        }
        characterName.lastName = gnome_last_name[randomize(gnome_last_name)];
        break;
      case "half-elf":
        if (gender === "male") {
          let firstName = elf_first_name_male.concat(human_first_name_male);
          characterName.firstName = firstName[randomize(firstName)];
        } else if (gender === "female") {
          let firstName = elf_first_name_female.concat(human_first_name_female);
          characterName.firstName = firstName[randomize(firstName)];
        } else {
          let firstName = elf_first_name_female.concat(
            elf_first_name_male.concat(
              human_first_name_male.concat(human_first_name_female)
            )
          );
          characterName.firstName = firstName[randomize(firstName)];
        }
        let lastName = elf_last_name.concat(human_last_name);
        characterName.lastName = lastName[randomize(lastName)];
        break;
      case "halfling":
        if (gender === "male") {
          characterName.firstName =
            halfling_first_name_male[randomize(halfling_first_name_male)];
        } else if (gender === "female") {
          characterName.firstName =
            halfling_first_name_female[randomize(halfling_first_name_female)];
        } else {
          let nameConcat = halfling_first_name_female.concat(
            halfling_first_name_male
          );
          characterName.firstName = nameConcat[randomize(nameConcat)];
        }
        characterName.lastName =
          halfling_last_name[randomize(halfling_last_name)];
        break;
      case "half-orc":
        if (gender === "male") {
          let firstName = orc_first_name_male.concat(human_first_name_male);
          characterName.firstName = firstName[randomize(firstName)];
        } else if (gender === "female") {
          let firstName = orc_first_name_female.concat(human_first_name_female);
          characterName.firstName = firstName[randomize(firstName)];
        } else {
          let firstName = orc_first_name_female.concat(
            orc_first_name_male.concat(
              human_first_name_male.concat(human_first_name_female)
            )
          );
          characterName.firstName = firstName[randomize(firstName)];
        }
        let lastNameConcat = orc_last_name.concat(human_last_name);
        characterName.lastName = lastNameConcat[randomize(lastNameConcat)];
        break;
      case "human":
        if (gender === "male") {
          characterName.firstName =
            human_first_name_male[randomize(human_first_name_male)];
        } else if (gender === "female") {
          characterName.firstName =
            human_first_name_female[randomize(human_first_name_female)];
        } else {
          let nameConcat = human_first_name_female.concat(
            human_first_name_male
          );
          characterName.firstName = nameConcat[randomize(nameConcat)];
        }
        characterName.lastName = human_last_name[randomize(human_last_name)];
        break;
      default:
        characterName.firstName = "Thwombly";
        characterName.lastName = "Juventas";
    }
    characterName.fullName =
      characterName.firstName + " " + characterName.lastName;
    return characterName;
  }

  function gender() {
    var gender_array = ["male", "female", "either"];
    var random_gender = gender_array[randomize(gender_array)];
    return random_gender;
  }

  //Attributes and Features
  /* Done */
  function rolledStats() {
    function stat_roller() {
      var rolled_stat = roll_dice(6, 4);
      rolled_stat.sort(function (a, b) {
        return b - a;
      });
      rolled_stat.pop();
      var total = 0;
      for (var i in rolled_stat) {
        total += rolled_stat[i];
      }
      return total;
    }
    let rolledStats = [];

    for (let i = 0; i < 6; i++) {
      rolledStats.push(stat_roller());
    }
    return rolledStats;
  }

  function characterAttributes(char_race, char_class, char_level) {
    let rolled = rolledStats();
    let raceStats = racer(char_race);
    let race = Object.values(raceStats.abilities);
    let stats = rolled.map((a, i) => a + race[i]);
    let finalStats = abilityScoreImprover(stats, char_class, char_level);
    function abilityScoreImprover(stats, char_class, char_level) {
      let improvements = 0;
      switch (char_class) {
        case "fighter":
          switch (char_level) {
            case 1:
            case 2:
            case 3:
              break;
            case 4:
            case 5:
              improvements = 1;
              break;
            case 6:
            case 7:
              improvements = 2;
              break;
            case 8:
            case 9:
            case 10:
            case 11:
              improvements = 3;
              break;
            case 12:
            case 13:
              improvements = 4;
              break;
            case 14:
            case 15:
              improvements = 5;
              break;
            case 16:
            case 17:
            case 18:
              improvements = 6;
              break;
            case 19:
            case 20:
              improvements = 7;
              break;
          }
          break;
        case "rogue":
          switch (char_level) {
            case 1:
            case 2:
            case 3:
              break;
            case 4:
            case 5:
            case 6:
            case 7:
              improvements = 1;
              break;
            case 8:
            case 9:
              improvements = 2;
              break;
            case 10:
            case 11:
              improvements = 3;
              break;
            case 12:
            case 13:
            case 14:
            case 15:
              improvements = 4;
              break;
            case 16:
            case 17:
            case 18:
              improvements = 5;
              break;
            case 19:
            case 20:
              improvements = 6;
              break;
          }
          break;
        case "barbarian":
        case "bard":
        case "cleric":
        case "druid":
        case "monk":
        case "paladin":
        case "ranger":
        case "sorcerer":
        case "warlock":
        case "wizard":
          switch (char_level) {
            case 1:
            case 2:
            case 3:
              break;
            case 4:
            case 5:
            case 6:
            case 7:
              improvements = 1;
              break;
            case 8:
            case 9:
            case 10:
            case 11:
              improvements = 2;
              break;
            case 12:
            case 13:
            case 14:
            case 15:
              improvements = 3;
              break;
            case 16:
            case 17:
            case 18:
              improvements = 4;
              break;
            case 19:
            case 20:
              improvements = 5;
              break;
          }
          break;
      }
      improvements = improvements * 2;
      let positioner;
      for (let i = 0; i < improvements; i++) {
        positioner = Math.floor(Math.random() * 6);

        if (stats[positioner] < 20) {
          stats[positioner] = stats[positioner] + 1;
        } else {
          i--;
        }
      }
      return stats;
    }
    let attributes = new Object();

    attributes.strength = finalStats[0];
    attributes.dexterity = finalStats[1];
    attributes.constitution = finalStats[2];
    attributes.intelligence = finalStats[3];
    attributes.wisdom = finalStats[4];
    attributes.charisma = finalStats[5];

    return attributes;
  }

  function modifiers(abilityScores) {
    const attributeMods = new Object();
    attributeMods.strengthMod = Math.floor((abilityScores.strength - 10) / 2);
    attributeMods.dexterityMod = Math.floor((abilityScores.dexterity - 10) / 2);
    attributeMods.constitutionMod = Math.floor(
      (abilityScores.constitution - 10) / 2
    );
    attributeMods.wisdomMod = Math.floor((abilityScores.wisdom - 10) / 2);
    attributeMods.intelligenceMod = Math.floor(
      (abilityScores.intelligence - 10) / 2
    );
    attributeMods.charismaMod = Math.floor((abilityScores.charisma - 10) / 2);
    return attributeMods;
  }

  function proficiencyBonus(level) {
    return Math.ceil(level / 4) + 1;
  }

  function hitdie(char_class) {
    let hit_die;

    switch (char_class) {
      case "barbarian":
        hit_die = 12;
        break;
      case "rogue":
      case "warlock":
      case "monk":
      case "bard":
      case "cleric":
      case "druid":
        hit_die = 8;
        break;
      case "fighter":
      case "paladin":
      case "ranger":
        hit_die = 10;
        break;
      case "sorcerer":
      case "wizard":
        hit_die = 6;
        break;
    }
    return hit_die;
  }

  function hitpoints(con_mod, char_level, char_class, hitDie) {
    var first_level = hitdie(char_class);
    var subsequent_rolls = roll_dice(hitDie, char_level);

    var sub_roll_total = 0;
    if (char_level > 1) {
      for (var i in subsequent_rolls) {
        sub_roll_total += subsequent_rolls[i];
      }
    }
    var con_mod_bonus = con_mod * char_level;

    if (con_mod < 1) {
      con_mod_bonus = 1 * char_level;
    }

    var hp = first_level + sub_roll_total + con_mod_bonus;
    return hp;
    //lowest a con mod bonus can go is 1, as asserted by D&D co-creator for 5E Mike Mearls here: https://twitter.com/mikemearls/status/582601826031132673
  }

  function aligner(input) {
    let alignmentArray = [
      "Lawful Good",
      "Lawful Neutral",
      "Lawful Evil",
      "Neutral Good",
      "True Neutral",
      "Neutral Evil",
      "Chaotic Good",
      "Chaotic Neutral",
      "Chaotic Evil",
    ];
    let randomAlignment = alignmentArray[randomize(alignmentArray)];

    if (input === "random") {
      input = randomAlignment;
    }
    return input;
  }
  /* Done */
  function leveler(input) {
    let level = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ];
    let randomLevel = level[randomize(level)];

    if (input === "random") {
      input = randomLevel;
    } else {
      Number(input);
    }
    return input;
  }
  /* Done */
  function proficiencies(classProfs, backgroundProfs, raceProfs) {
    const skills = [
      "acrobatics",
      "animal handling",
      "arcana",
      "athletics",
      "deception",
      "history",
      "insight",
      "intimidation",
      "investigation",
      "medicine",
      "nature",
      "perception",
      "performance",
      "persuasion",
      "religion",
      "sleight of hand",
      "stealth",
      "survival",
    ];

    let knownSkills = classProfs.concat(backgroundProfs.concat(raceProfs));
    let randomCount = 0;

    // Count number of "random" strings
    for (let i = 0; i < knownSkills.length; i++) {
      if (knownSkills[i] === "random") {
        randomCount++;
      }
    }

    // Remove "random" strings
    knownSkills = knownSkills.filter((skill) => skill !== "random");

    // Make the remaining skills unique
    const uniqueSkills = Array.from(new Set(knownSkills));

    // Add new skills to match number of "random" strings
    for (let i = 0; i < randomCount; i++) {
      let newSkill;
      do {
        newSkill = skills[Math.floor(Math.random() * skills.length)];
      } while (uniqueSkills.includes(newSkill));
      uniqueSkills.push(newSkill);
    }

    return uniqueSkills;
  }

  /* Needs work*/
  function skiller(charProficiencyBonus, charAttMods, characterProficiences) {
    const skills = new Object();

    skills.athletics = charAttMods.strengthMod;
    skills.acrobatics = charAttMods.dexterityMod;
    skills.sleightOfHand = charAttMods.dexterityMod;
    skills.stealth = charAttMods.dexterityMod;
    skills.arcana = charAttMods.intelligenceMod;
    skills.history = charAttMods.intelligenceMod;
    skills.investigation = charAttMods.intelligenceMod;
    skills.nature = charAttMods.intelligenceMod;
    skills.religion = charAttMods.intelligenceMod;
    skills.animalHandling = charAttMods.wisdomMod;
    skills.insight = charAttMods.wisdomMod;
    skills.medicine = charAttMods.wisdomMod;
    skills.perception = charAttMods.wisdomMod;
    skills.survival = charAttMods.wisdomMod;
    skills.deception = charAttMods.charismaMod;
    skills.intimidation = charAttMods.charismaMod;
    skills.performance = charAttMods.charismaMod;
    skills.persuasion = charAttMods.charismaMod;

    for (let i = 0; i < characterProficiences.length; i++) {
      switch (characterProficiences[i]) {
        case "athletics":
          skills.athletics += charProficiencyBonus;
          break;
        case "acrobatics":
          skills.acrobatics += charProficiencyBonus;
          break;
        case "sleight of hand":
          skills.sleightOfHand += charProficiencyBonus;
          break;
        case "stealth":
          skills.stealth += charProficiencyBonus;
          break;
        case "arcana":
          skills.arcana += charProficiencyBonus;
          break;
        case "history":
          skills.history += charProficiencyBonus;
          break;
        case "investigation":
          skills.investigation += charProficiencyBonus;
          break;
        case "nature":
          skills.nature += charProficiencyBonus;
          break;
        case "religion":
          skills.religion += charProficiencyBonus;
          break;
        case "animal handling":
          skills.animalHandling += charProficiencyBonus;
          break;
        case "insight":
          skills.insight += charProficiencyBonus;
          break;
        case "medicine":
          skills.medicine += charProficiencyBonus;
          break;
        case "perception":
          skills.perception += charProficiencyBonus;
          break;
        case "survival":
          skills.survival += charProficiencyBonus;
          break;
        case "deception":
          skills.deception += charProficiencyBonus;
          break;
        case "intimidation":
          skills.intimidation += charProficiencyBonus;
          break;
        case "performance":
          skills.performance += charProficiencyBonus;
          break;
        case "persuasion":
          skills.persuasion += charProficiencyBonus;
          break;
      }
    }
    return skills;
  }

  /* Could do with improvement */
  function featurer(char_class, char_level) {
    let features = [];

    switch (char_class) {
      case "barbarian":
        switch (char_level) {
          case 1:
            features = ["Rage", "Unarmored Defense"];
            break;
          case 2:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
            ];
            break;
          case 3:
          case 4:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
              "Primal Path",
            ];
            break;
          case 5:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
              "Primal Path",
              "Extra Attack",
              "Fast Movement",
            ];
            break;
          case 6:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
              "Primal Path",
              "Extra Attack",
              "Fast Movement",
              "Path feature",
            ];
            break;
          case 7:
          case 8:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
              "Primal Path",
              "Extra Attack",
              "Fast Movement",
              "Path feature",
              "Feral Instinct",
            ];
            break;
          case 9:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
              "Primal Path",
              "Extra Attack",
              "Fast Movement",
              "Path feature",
              "Feral Instinct",
              "Brutal Critical (1 die)",
            ];
            break;
          case 10:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
              "Primal Path",
              "Extra Attack",
              "Fast Movement",
              "Path feature x 2",
              "Feral Instinct",
              "Brutal Critical (1 die)",
            ];
            break;
          case 11:
          case 12:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
              "Primal Path",
              "Extra Attack",
              "Fast Movement",
              "Path feature x 2",
              "Feral Instinct",
              "Brutal Critical (1 die)",
              "Relentless Rage",
            ];
            break;
          case 13:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
              "Primal Path",
              "Extra Attack",
              "Fast Movement",
              "Path feature x 2",
              "Feral Instinct",
              "Brutal Critical (2 dice)",
              "Relentless Rage",
            ];
            break;
          case 14:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
              "Primal Path",
              "Extra Attack",
              "Fast Movement",
              "Path feature x 3",
              "Feral Instinct",
              "Brutal Critical (2 dice)",
              "Relentless Rage",
            ];
            break;
          case 15:
          case 16:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
              "Primal Path",
              "Extra Attack",
              "Fast Movement",
              "Path feature x 3",
              "Feral Instinct",
              "Brutal Critical (2 dice)",
              "Relentless Rage",
              "Persistent Rage",
            ];
            break;
          case 17:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
              "Primal Path",
              "Extra Attack",
              "Fast Movement",
              "Path feature x 3",
              "Feral Instinct",
              "Brutal Critical (3 dice)",
              "Relentless Rage",
              "Persistent Rage",
            ];
            break;
          case 18:
          case 19:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
              "Primal Path",
              "Extra Attack",
              "Fast Movement",
              "Path feature x 3",
              "Feral Instinct",
              "Brutal Critical (3 dice)",
              "Relentless Rage",
              "Persistent Rage",
              "Indomitable Might",
            ];
            break;
          case 20:
            features = [
              "Rage",
              "Unarmored Defense",
              "Reckless Attack",
              "Danger Sense",
              "Primal Path",
              "Extra Attack",
              "Fast Movement",
              "Path feature x 3",
              "Feral Instinct",
              "Brutal Critical (3 dice)",
              "Relentless Rage",
              "Persistent Rage",
              "Indomitable Might",
              "Primal Champion",
            ];
            break;
        }
        break;
      case "bard":
        switch (char_level) {
          case 1:
            features = ["Bardic Inspiration(d6)"];
            break;
          case 2:
            features = [
              "Bardic Inspiration(d6)",
              "Jack of All Trades",
              "Song of Rest(d6)",
            ];
            break;
          case 3:
          case 4:
            features = [
              "Bardic Inspiration(d6)",
              "Jack of All Trades",
              "Song of Rest(d6)",
              "Bard College",
              "Expertise",
            ];
            break;
          case 5:
            features = [
              "Bardic Inspiration(d8)",
              "Jack of All Trades",
              "Song of Rest(d6)",
              "Bard College",
              "Expertise",
              "Font of Inspiration",
            ];
            break;
          case 6:
          case 7:
          case 8:
            features = [
              "Bardic Inspiration(d8)",
              "Jack of All Trades",
              "Song of Rest(d6)",
              "Bard College",
              "Expertise",
              "Font of Inspiration",
              "Countercharm",
              "Bard College feature",
            ];
            break;
          case 9:
            features = [
              "Bardic Inspiration(d8)",
              "Jack of All Trades",
              "Song of Rest(d8)",
              "Bard College",
              "Expertise",
              "Font of Inspiration",
              "Countercharm",
              "Bard College feature",
            ];
            break;
          case 10:
          case 11:
          case 12:
            features = [
              "Bardic Inspiration(d10)",
              "Jack of All Trades",
              "Song of Rest(d8)",
              "Bard College",
              "Expertise x 2",
              "Font of Inspiration",
              "Countercharm",
              "Bard College feature",
              "Magical Secrets",
            ];
            break;
          case 13:
            features = [
              "Bardic Inspiration(d10)",
              "Jack of All Trades",
              "Song of Rest(d10)",
              "Bard College",
              "Expertise x 2",
              "Font of Inspiration",
              "Countercharm",
              "Bard College feature",
              "Magical Secrets",
            ];
            break;
          case 14:
            features = [
              "Bardic Inspiration(d10)",
              "Jack of All Trades",
              "Song of Rest(d10)",
              "Bard College",
              "Expertise x 2",
              "Font of Inspiration",
              "Countercharm",
              "Bard College feature x 2",
              "Magical Secrets x 2",
            ];
            break;
          case 15:
          case 16:
            features = [
              "Bardic Inspiration(d12)",
              "Jack of All Trades",
              "Song of Rest(d10)",
              "Bard College",
              "Expertise x 2",
              "Font of Inspiration",
              "Countercharm",
              "Bard College feature x 2",
              "Magical Secrets x 2",
            ];
            break;
          case 17:
            features = [
              "Bardic Inspiration(d12)",
              "Jack of All Trades",
              "Song of Rest(d12)",
              "Bard College",
              "Expertise x 2",
              "Font of Inspiration",
              "Countercharm",
              "Bard College feature x 2",
              "Magical Secrets x 2",
            ];
            break;
          case 18:
          case 19:
            features = [
              "Bardic Inspiration(d12)",
              "Jack of All Trades",
              "Song of Rest(d12)",
              "Bard College",
              "Expertise x 2",
              "Font of Inspiration",
              "Countercharm",
              "Bard College feature x 2",
              "Magical Secrets x 3",
            ];
            break;
          case 20:
            features = [
              "Bardic Inspiration(d12)",
              "Jack of All Trades",
              "Song of Rest(d12)",
              "Bard College",
              "Expertise x 2",
              "Font of Inspiration",
              "Countercharm",
              "Bard College feature x 2",
              "Magical Secrets x 3",
              "Superior Inspiration",
            ];
            break;
        }
        break;
      case "cleric":
        switch (char_level) {
          case 1:
            features = ["Spellcasting", "Divine Domain"];
            break;
          case 2:
          case 3:
          case 4:
            features = [
              "Spellcasting",
              "Divine Domain",
              "Channel Divinity(x1)",
              "Divine Domain feature",
            ];
            break;
          case 5:
            features = [
              "Spellcasting",
              "Divine Domain",
              "Channel Divinity(x1)",
              "Divine Domain feature",
              "Destroy Undead(CR 1/2)",
            ];
            break;
          case 6:
          case 7:
            features = [
              "Spellcasting",
              "Divine Domain",
              "Channel Divinity(x2)",
              "Divine Domain feature x 2",
              "Destroy Undead(CR 1/2)",
            ];
            break;
          case 8:
          case 9:
            features = [
              "Spellcasting",
              "Divine Domain",
              "Channel Divinity(x2)",
              "Divine Domain feature x 2",
              "Destroy Undead(CR 1)",
            ];
            break;
          case 10:
            features = [
              "Spellcasting",
              "Divine Domain",
              "Channel Divinity(x2)",
              "Divine Domain feature x 2",
              "Destroy Undead(CR 1)",
              "Divine Intervention",
            ];
            break;
          case 11:
          case 12:
          case 13:
            features = [
              "Spellcasting",
              "Divine Domain",
              "Channel Divinity(x2)",
              "Divine Domain feature x 2",
              "Destroy Undead(CR 2)",
              "Divine Intervention",
            ];
            break;
          case 14:
          case 15:
          case 16:
            features = [
              "Spellcasting",
              "Divine Domain",
              "Channel Divinity(x2)",
              "Divine Domain feature x 2",
              "Destroy Undead(CR 3)",
              "Divine Intervention",
            ];
            break;
          case 17:
            features = [
              "Spellcasting",
              "Divine Domain",
              "Channel Divinity(x2)",
              "Divine Domain feature x 3",
              "Destroy Undead(CR 4)",
              "Divine Intervention",
            ];
            break;
          case 18:
          case 19:
            features = [
              "Spellcasting",
              "Divine Domain",
              "Channel Divinity(x3)",
              "Divine Domain feature x 3",
              "Destroy Undead(CR 4)",
              "Divine Intervention",
            ];
            break;
          case 20:
            features = [
              "Spellcasting",
              "Divine Domain",
              "Channel Divinity(x3)",
              "Divine Domain feature x 3",
              "Destroy Undead(CR 4)",
              "Divine Intervention",
              "Divine Intervention improvement",
            ];
            break;
        }
        break;
      case "druid":
        switch (char_level) {
          case 1:
            features = ["Druidic"];
            break;
          case 2:
          case 3:
            features = ["Druidic", "Wild Shape", "Druid Circle"];
            break;
          case 4:
          case 5:
            features = [
              "Druidic",
              "Wild Shape",
              "Druid Circle",
              "Wild Shape improvement",
            ];
            break;
          case 6:
          case 7:
            features = [
              "Druidic",
              "Wild Shape",
              "Druid Circle",
              "Wild Shape improvement",
              "Druid Circle feature",
            ];
            break;
          case 8:
          case 9:
            features = [
              "Druidic",
              "Wild Shape",
              "Druid Circle",
              "Wild Shape improvement",
              "Druid Circle feature",
            ];
            break;
          case 10:
          case 11:
          case 12:
          case 13:
            features = [
              "Druidic",
              "Wild Shape",
              "Druid Circle x 2",
              "Wild Shape improvement",
              "Druid Circle feature",
            ];
            break;
          case 14:
          case 15:
          case 16:
          case 17:
            features = [
              "Druidic",
              "Wild Shape",
              "Druid Circle x 3",
              "Wild Shape improvement",
              "Druid Circle feature",
            ];
            break;
          case 18:
          case 19:
            features = [
              "Druidic",
              "Wild Shape",
              "Druid Circle x 3",
              "Wild Shape improvement",
              "Druid Circle feature",
              "Timeless Body",
              "Beast Spells",
            ];
            break;
          case 20:
            features = [
              "Druidic",
              "Wild Shape",
              "Druid Circle x 3",
              "Wild Shape improvement",
              "Druid Circle feature",
              "Timeless Body",
              "Beast Spells",
              "Archdruid",
            ];
            break;
        }
        break;
      case "fighter":
        switch (char_level) {
          case 1:
            features = [];
            break;
          case 2:
            features = ["Action Surge(x1)"];
            break;
          case 3:
          case 4:
            features = ["Action Surge(x1)", "Martial Archetype"];
            break;
          case 5:
          case 6:
            features = [
              "Action Surge(x1)",
              "Martial Archetype",
              "Extra Attack (x1)",
            ];
            break;
          case 7:
          case 8:
            features = [
              "Action Surge(x1)",
              "Martial Archetype",
              "Extra Attack (x1)",
              "Martial Archetype feature",
            ];
            break;
          case 9:
            features = [
              "Action Surge(x1)",
              "Martial Archetype",
              "Extra Attack (x1)",
              "Martial Archetype feature",
              "Indomitable (x1)",
            ];
            break;
          case 10:
            features = [
              "Action Surge(x1)",
              "Martial Archetype",
              "Extra Attack (x1)",
              "Martial Archetype feature x 2",
              "Indomitable (x1)",
            ];
            break;
          case 11:
          case 12:
            features = [
              "Action Surge(x1)",
              "Martial Archetype",
              "Extra Attack (x2)",
              "Martial Archetype feature x 2",
              "Indomitable (x1)",
            ];
            break;
          case 13:
          case 14:
            features = [
              "Action Surge(x1)",
              "Martial Archetype",
              "Extra Attack (x2)",
              "Martial Archetype feature x 2",
              "Indomitable (x2)",
            ];
            break;
          case 15:
          case 16:
            features = [
              "Action Surge(x1)",
              "Martial Archetype",
              "Extra Attack (x2)",
              "Martial Archetype feature x 3",
              "Indomitable (x2)",
            ];
            break;
          case 17:
            features = [
              "Action Surge(x2)",
              "Martial Archetype",
              "Extra Attack (x2)",
              "Martial Archetype feature x 2",
              "Indomitable (x3)",
            ];
            break;
          case 18:
          case 19:
            features = [
              "Action Surge(x2)",
              "Martial Archetype",
              "Extra Attack (x2)",
              "Martial Archetype feature x 3",
              "Indomitable (x3)",
            ];
            break;
          case 20:
            features = [
              "Action Surge(x2)",
              "Martial Archetype",
              "Extra Attack (x3)",
              "Martial Archetype feature x 3",
              "Indomitable (x3)",
            ];
            break;
        }
        break;
      case "monk":
        switch (char_level) {
          case 1:
            features = ["Unarmored Defense", "Martial Arts"];
            break;
          case 2:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
            ];
            break;
          case 3:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
            ];
            break;
          case 4:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
              "Slow Fall",
            ];
            break;
          case 5:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
              "Slow Fall",
              "Extra Attack",
              "Stunning Strike",
            ];
            break;
          case 6:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
              "Slow Fall",
              "Extra Attack",
              "Stunning Strike",
              "Ki-Empowered Strikes",
              "Monastic Tradition feature",
            ];
            break;
          case 7:
          case 8:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
              "Slow Fall",
              "Extra Attack",
              "Stunning Strike",
              "Ki-Empowered Strikes",
              "Monastic Tradition feature",
              "Evasion",
              "Stillness of Mind",
            ];
            break;
          case 9:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
              "Slow Fall",
              "Extra Attack",
              "Stunning Strike",
              "Ki-Empowered Strikes",
              "Monastic Tradition feature",
              "Evasion",
              "Stillness of Mind",
              "Unarmored Movement improvement",
            ];
            break;
          case 10:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
              "Slow Fall",
              "Extra Attack",
              "Stunning Strike",
              "Ki-Empowered Strikes",
              "Monastic Tradition feature",
              "Evasion",
              "Stillness of Mind",
              "Unarmored Movement improvement",
              "Purity of Body",
            ];
            break;
          case 11:
          case 12:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
              "Slow Fall",
              "Extra Attack",
              "Stunning Strike",
              "Ki-Empowered Strikes",
              "Monastic Tradition feature x 2",
              "Evasion",
              "Stillness of Mind",
              "Unarmored Movement improvement",
              "Purity of Body",
            ];
            break;
          case 13:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
              "Slow Fall",
              "Extra Attack",
              "Stunning Strike",
              "Ki-Empowered Strikes",
              "Monastic Tradition feature x 2",
              "Evasion",
              "Stillness of Mind",
              "Unarmored Movement improvement",
              "Purity of Body",
              "Tongue of the Sun and Moon",
            ];
            break;
          case 14:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
              "Slow Fall",
              "Extra Attack",
              "Stunning Strike",
              "Ki-Empowered Strikes",
              "Monastic Tradition feature x 2",
              "Evasion",
              "Stillness of Mind",
              "Unarmored Movement improvement",
              "Purity of Body",
              "Tongue of the Sun and Moon",
              "Diamond Soul",
            ];
            break;
          case 15:
          case 16:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
              "Slow Fall",
              "Extra Attack",
              "Stunning Strike",
              "Ki-Empowered Strikes",
              "Monastic Tradition feature x 2",
              "Evasion",
              "Stillness of Mind",
              "Unarmored Movement improvement",
              "Purity of Body",
              "Tongue of the Sun and Moon",
              "Diamond Soul",
              "Timeless Body",
            ];
            break;
          case 17:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
              "Slow Fall",
              "Extra Attack",
              "Stunning Strike",
              "Ki-Empowered Strikes",
              "Monastic Tradition feature x 3",
              "Evasion",
              "Stillness of Mind",
              "Unarmored Movement improvement",
              "Purity of Body",
              "Tongue of the Sun and Moon",
              "Diamond Soul",
              "Timeless Body",
            ];
            break;
          case 18:
          case 19:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
              "Slow Fall",
              "Extra Attack",
              "Stunning Strike",
              "Ki-Empowered Strikes",
              "Monastic Tradition feature x 3",
              "Evasion",
              "Stillness of Mind",
              "Unarmored Movement improvement",
              "Purity of Body",
              "Tongue of the Sun and Moon",
              "Diamond Soul",
              "Timeless Body",
              "Empty Body",
            ];
            break;
          case 20:
            features = [
              "Unarmored Defense",
              "Martial Arts",
              "Ki",
              "Unarmored Movement",
              "Monastic Tradition",
              "Deflect Missiles",
              "Slow Fall",
              "Extra Attack",
              "Stunning Strike",
              "Ki-Empowered Strikes",
              "Monastic Tradition feature x 3",
              "Evasion",
              "Stillness of Mind",
              "Unarmored Movement improvement",
              "Purity of Body",
              "Tongue of the Sun and Moon",
              "Diamond Soul",
              "Timeless Body",
              "Empty Body",
              "Perfect Self",
            ];
            break;
        }
        break;
      case "paladin":
        switch (char_level) {
          case 1:
            features = ["Divine Sense", "Lay on Hands"];
            break;
          case 2:
            features = [
              "Divine Sense",
              "Lay on Hands",
              "Fighting Style",
              "Divine Smite",
            ];
            break;
          case 3:
          case 4:
            features = [
              "Divine Sense",
              "Lay on Hands",
              "Fighting Style",
              "Divine Smite",
              "Divine Health",
              "Sacred Oath",
            ];
            break;
          case 5:
            features = [
              "Divine Sense",
              "Lay on Hands",
              "Fighting Style",
              "Divine Smite",
              "Divine Health",
              "Sacred Oath",
              "Extra Attack",
            ];
            break;
          case 6:
            features = [
              "Divine Sense",
              "Lay on Hands",
              "Fighting Style",
              "Divine Smite",
              "Divine Health",
              "Sacred Oath",
              "Extra Attack",
              "Aura of Protection",
            ];
            break;
          case 7:
          case 8:
          case 9:
            features = [
              "Divine Sense",
              "Lay on Hands",
              "Fighting Style",
              "Divine Smite",
              "Divine Health",
              "Sacred Oath",
              "Extra Attack",
              "Aura of Protection",
              "Sacred Oath feature",
            ];
            break;
          case 10:
            features = [
              "Divine Sense",
              "Lay on Hands",
              "Fighting Style",
              "Divine Smite",
              "Divine Health",
              "Sacred Oath",
              "Extra Attack",
              "Aura of Protection",
              "Sacred Oath feature",
              "Aura of Courage",
            ];
            break;
          case 11:
          case 12:
          case 13:
            features = [
              "Divine Sense",
              "Lay on Hands",
              "Fighting Style",
              "Divine Smite",
              "Divine Health",
              "Sacred Oath",
              "Extra Attack",
              "Aura of Protection",
              "Sacred Oath feature",
              "Aura of Courage",
              "Improved Divine Smite",
            ];
            break;
          case 14:
            features = [
              "Divine Sense",
              "Lay on Hands",
              "Fighting Style",
              "Divine Smite",
              "Divine Health",
              "Sacred Oath",
              "Extra Attack",
              "Aura of Protection",
              "Sacred Oath feature",
              "Aura of Courage",
              "Improved Divine Smite",
              "Cleansing Touch",
            ];
            break;
          case 15:
          case 16:
          case 17:
            features = [
              "Divine Sense",
              "Lay on Hands",
              "Fighting Style",
              "Divine Smite",
              "Divine Health",
              "Sacred Oath",
              "Extra Attack",
              "Aura of Protection",
              "Sacred Oath feature",
              "Aura of Courage",
              "Improved Divine Smite",
              "Cleansing Touch",
              "Sacred Oath feature",
            ];
            break;
          case 18:
          case 19:
            features = [
              "Divine Sense",
              "Lay on Hands",
              "Fighting Style",
              "Divine Smite",
              "Divine Health",
              "Sacred Oath",
              "Extra Attack",
              "Aura of Protection",
              "Sacred Oath feature",
              "Aura of Courage",
              "Improved Divine Smite",
              "Cleansing Touch",
              "Sacred Oath feature",
              "Aura improvements",
            ];
            break;
          case 20:
            features = [
              "Divine Sense",
              "Lay on Hands",
              "Fighting Style",
              "Divine Smite",
              "Divine Health",
              "Sacred Oath",
              "Extra Attack",
              "Aura of Protection",
              "Sacred Oath feature",
              "Aura of Courage",
              "Improved Divine Smite",
              "Cleansing Touch",
              "Sacred Oath feature",
              "Aura improvements",
              "Sacred Oath feature",
            ];
            break;
        }
        break;
      case "ranger":
        switch (char_level) {
          case 1:
            features = ["Favored Enemy", "Natural Explorer"];
            break;
          case 2:
            features = ["Favored Enemy", "Natural Explorer", "Fighting Style"];
            break;
          case 3:
          case 4:
            features = [
              "Favored Enemy",
              "Natural Explorer",
              "Fighting Style",
              "Primeval Awareness",
              "Ranger Conclave",
            ];
            break;
          case 5:
            features = [
              "Favored Enemy",
              "Natural Explorer",
              "Fighting Style",
              "Primeval Awareness",
              "Ranger Conclave",
              "Extra Attack",
            ];
            break;
          case 6:
            features = [
              "Favored Enemy",
              "Natural Explorer",
              "Fighting Style",
              "Primeval Awareness",
              "Ranger Conclave",
              "Extra Attack",
              "Favored Enemy Improvement",
              "Natural Explorer Improvement",
            ];
            break;
          case 7:
            features = [
              "Favored Enemy",
              "Natural Explorer",
              "Fighting Style",
              "Primeval Awareness",
              "Ranger Conclave",
              "Extra Attack",
              "Favored Enemy Improvement",
              "Natural Explorer Improvement",
              "Ranger Conclave feature",
            ];
            break;
          case 8:
          case 9:
            features = [
              "Favored Enemy",
              "Natural Explorer",
              "Fighting Style",
              "Primeval Awareness",
              "Ranger Conclave",
              "Extra Attack",
              "Favored Enemy Improvement",
              "Natural Explorer Improvement",
              "Ranger Conclave feature",
              "Land's Stride",
            ];
            break;
          case 10:
            features = [
              "Favored Enemy",
              "Natural Explorer",
              "Fighting Style",
              "Primeval Awareness",
              "Ranger Conclave",
              "Extra Attack",
              "Favored Enemy Improvement",
              "Natural Explorer Improvement x 2",
              "Ranger Conclave feature",
              "Land's Stride",
              "Hide in Plain Sight",
            ];
            break;
          case 11:
          case 12:
          case 13:
            features = [
              "Favored Enemy",
              "Natural Explorer",
              "Fighting Style",
              "Primeval Awareness",
              "Ranger Conclave",
              "Extra Attack",
              "Favored Enemy Improvement",
              "Natural Explorer Improvement x 2",
              "Ranger Conclave feature",
              "Land's Stride",
              "Hide in Plain Sight",
              "Ranger Conclave feature",
            ];
            break;
          case 14:
            features = [
              "Favored Enemy",
              "Natural Explorer",
              "Fighting Style",
              "Primeval Awareness",
              "Ranger Conclave",
              "Extra Attack",
              "Favored Enemy Improvement x 2",
              "Natural Explorer Improvement x 2",
              "Ranger Conclave feature",
              "Land's Stride",
              "Hide in Plain Sight",
              "Ranger Conclave feature",
              "Vanish",
            ];
            break;
          case 15:
          case 16:
          case 17:
            features = [
              "Favored Enemy",
              "Natural Explorer",
              "Fighting Style",
              "Primeval Awareness",
              "Ranger Conclave",
              "Extra Attack",
              "Favored Enemy Improvement x 2",
              "Natural Explorer Improvement x 2",
              "Ranger Conclave feature x 2",
              "Land's Stride",
              "Hide in Plain Sight",
              "Ranger Conclave feature",
              "Vanish",
            ];
            break;
          case 18:
          case 19:
            features = [
              "Favored Enemy",
              "Natural Explorer",
              "Fighting Style",
              "Primeval Awareness",
              "Ranger Conclave",
              "Extra Attack",
              "Favored Enemy Improvement x 2",
              "Natural Explorer Improvement x 2",
              "Ranger Conclave feature x 2",
              "Land's Stride",
              "Hide in Plain Sight",
              "Ranger Conclave feature",
              "Vanish",
              "Feral Senses",
            ];
            break;
          case 20:
            features = [
              "Favored Enemy",
              "Natural Explorer",
              "Fighting Style",
              "Primeval Awareness",
              "Ranger Conclave",
              "Extra Attack",
              "Favored Enemy Improvement x 2",
              "Natural Explorer Improvement x 2",
              "Ranger Conclave feature x 2",
              "Land's Stride",
              "Hide in Plain Sight",
              "Ranger Conclave feature",
              "Vanish",
              "Feral Senses",
              "Foe Slayer",
            ];
            break;
        }
        break;
      case "rogue":
        switch (char_level) {
          case 1:
            features = ["Expertise", "Sneak Attack", "Thieves' Cant"];
            break;
          case 2:
            features = [
              "Expertise",
              "Sneak Attack",
              "Thieves' Cant",
              "Cunning Action",
            ];
            break;
          case 3:
          case 4:
            features = [
              "Expertise",
              "Sneak Attack",
              "Thieves' Cant",
              "Cunning Action",
              "Roguish Archetype",
            ];
            break;
          case 5:
            features = [
              "Expertise",
              "Sneak Attack",
              "Thieves' Cant",
              "Cunning Action",
              "Roguish Archetype",
              "Uncanny Dodge",
            ];
            break;
          case 6:
            features = [
              "Expertise",
              "Sneak Attack",
              "Thieves' Cant",
              "Cunning Action",
              "Roguish Archetype",
              "Uncanny Dodge",
              "Expertise",
            ];
            break;
          case 7:
          case 8:
            features = [
              "Expertise",
              "Sneak Attack",
              "Thieves' Cant",
              "Cunning Action",
              "Roguish Archetype",
              "Uncanny Dodge",
              "Expertise",
              "Evasion",
            ];
            break;
          case 9:
          case 10:
            features = [
              "Expertise",
              "Sneak Attack",
              "Thieves' Cant",
              "Cunning Action",
              "Roguish Archetype",
              "Uncanny Dodge",
              "Expertise",
              "Evasion",
              "Roguish Archetype feature",
            ];
            break;
          case 11:
          case 12:
            features = [
              "Expertise",
              "Sneak Attack",
              "Thieves' Cant",
              "Cunning Action",
              "Roguish Archetype",
              "Uncanny Dodge",
              "Expertise",
              "Evasion",
              "Roguish Archetype feature",
              "Reliable Talent",
            ];
            break;
          case 13:
            features = [
              "Expertise",
              "Sneak Attack",
              "Thieves' Cant",
              "Cunning Action",
              "Roguish Archetype",
              "Uncanny Dodge",
              "Expertise",
              "Evasion",
              "Roguish Archetype feature x 2",
              "Reliable Talent",
            ];
            break;
          case 14:
            features = [
              "Expertise",
              "Sneak Attack",
              "Thieves' Cant",
              "Cunning Action",
              "Roguish Archetype",
              "Uncanny Dodge",
              "Expertise",
              "Evasion",
              "Roguish Archetype feature x 2",
              "Reliable Talent",
              "Blindsense",
            ];
            break;
          case 15:
          case 16:
            features = [
              "Expertise",
              "Sneak Attack",
              "Thieves' Cant",
              "Cunning Action",
              "Roguish Archetype",
              "Uncanny Dodge",
              "Expertise",
              "Evasion",
              "Roguish Archetype feature x 2",
              "Reliable Talent",
              "Blindsense",
              "Slippery Mind",
            ];
            break;
          case 17:
            features = [
              "Expertise",
              "Sneak Attack",
              "Thieves' Cant",
              "Cunning Action",
              "Roguish Archetype",
              "Uncanny Dodge",
              "Expertise",
              "Evasion",
              "Roguish Archetype feature x 3",
              "Reliable Talent",
              "Blindsense",
              "Slippery Mind",
            ];
            break;
          case 18:
          case 19:
            features = [
              "Expertise",
              "Sneak Attack",
              "Thieves' Cant",
              "Cunning Action",
              "Roguish Archetype",
              "Uncanny Dodge",
              "Expertise",
              "Evasion",
              "Roguish Archetype feature x 3",
              "Reliable Talent",
              "Blindsense",
              "Slippery Mind",
              "Elusive",
            ];
            break;
          case 20:
            features = [
              "Expertise",
              "Sneak Attack",
              "Thieves' Cant",
              "Cunning Action",
              "Roguish Archetype",
              "Uncanny Dodge",
              "Expertise",
              "Evasion",
              "Roguish Archetype feature x 3",
              "Reliable Talent",
              "Blindsense",
              "Slippery Mind",
              "Elusive",
              "Stroke of Luck",
            ];
            break;
        }
        break;
      case "sorcerer":
        switch (char_level) {
          case 1:
            features = ["Sorcerous Origin"];
            break;
          case 2:
            features = ["Sorcerous Origin", "Font of Magic"];
            break;
          case 3:
          case 4:
          case 5:
            features = ["Sorcerous Origin", "Font of Magic", "Metamagic"];
            break;
          case 6:
          case 7:
          case 8:
          case 9:
            features = [
              "Sorcerous Origin",
              "Font of Magic",
              "Metamagic",
              "Sorcerous Origin feature",
            ];
            break;
          case 10:
          case 11:
          case 12:
          case 13:
            features = [
              "Sorcerous Origin",
              "Font of Magic",
              "Metamagic x 2",
              "Sorcerous Origin feature",
            ];
            break;
          case 14:
          case 15:
          case 16:
            features = [
              "Sorcerous Origin",
              "Font of Magic",
              "Metamagic x 2",
              "Sorcerous Origin feature x 2",
            ];
            break;
          case 17:
            features = [
              "Sorcerous Origin",
              "Font of Magic",
              "Metamagic x 2",
              "Sorcerous Origin feature x 2",
            ];
            break;
          case 18:
          case 19:
            features = [
              "Sorcerous Origin",
              "Font of Magic",
              "Metamagic x 2",
              "Sorcerous Origin feature x 3",
            ];
            break;
          case 20:
            features = [
              "Sorcerous Origin",
              "Font of Magic",
              "Metamagic x 2",
              "Sorcerous Origin feature x 3",
              "Sorcerous Restoration",
            ];
            break;
        }
        break;
      case "warlock":
        switch (char_level) {
          case 1:
            features = ["Otherworldly Patron", "Pact Magic"];
            break;
          case 2:
            features = [
              "Otherworldly Patron",
              "Pact Magic",
              "Eldritch Invocations",
            ];
            break;
          case 3:
          case 4:
          case 5:
            features = [
              "Otherworldly Patron",
              "Pact Magic",
              "Eldritch Invocations",
              "Pact Boon",
            ];
            break;
          case 6:
          case 7:
          case 8:
          case 9:
            features = [
              "Otherworldly Patron",
              "Pact Magic",
              "Eldritch Invocations",
              "Pact Boon",
              "Otherworldly Patron feature",
            ];
            break;
          case 10:
            features = [
              "Otherworldly Patron",
              "Pact Magic",
              "Eldritch Invocations",
              "Pact Boon",
              "Otherworldly Patron feature x 2",
            ];
            break;
          case 11:
          case 12:
            features = [
              "Otherworldly Patron",
              "Pact Magic",
              "Eldritch Invocations",
              "Pact Boon",
              "Otherworldly Patron feature x 2",
              "Mystic Arcanum (6th level)",
            ];
            break;
          case 13:
            features = [
              "Otherworldly Patron",
              "Pact Magic",
              "Eldritch Invocations",
              "Pact Boon",
              "Otherworldly Patron feature x 2",
              "Mystic Arcanum (7th level)",
            ];
            break;
          case 14:
            features = [
              "Otherworldly Patron",
              "Pact Magic",
              "Eldritch Invocations",
              "Pact Boon",
              "Otherworldly Patron feature x 3",
              "Mystic Arcanum (7th level)",
            ];
            break;
          case 15:
          case 16:
            features = [
              "Otherworldly Patron",
              "Pact Magic",
              "Eldritch Invocations",
              "Pact Boon",
              "Otherworldly Patron feature x 3",
              "Mystic Arcanum (8th level)",
            ];
            break;
          case 17:
          case 18:
          case 19:
            features = [
              "Otherworldly Patron",
              "Pact Magic",
              "Eldritch Invocations",
              "Pact Boon",
              "Otherworldly Patron feature x 3",
              "Mystic Arcanum (9th level)",
            ];
            break;
          case 20:
            features = [
              "Otherworldly Patron",
              "Pact Magic",
              "Eldritch Invocations",
              "Pact Boon",
              "Otherworldly Patron feature x 3",
              "Mystic Arcanum (9th level)",
              "Eldritch Master",
            ];
            break;
        }
        break;
      case "wizard":
        switch (char_level) {
          case 1:
            features = ["Arcane Recovery"];
            break;
          case 2:
          case 3:
          case 4:
          case 5:
            features = ["Arcane Recovery", "Arcane Tradition"];
            break;
          case 6:
          case 7:
          case 8:
          case 9:
            features = [
              "Arcane Recovery",
              "Arcane Tradition",
              "Arcane Tradition feature",
            ];
            break;
          case 10:
          case 11:
          case 12:
          case 13:
            features = [
              "Arcane Recovery",
              "Arcane Tradition",
              "Arcane Tradition feature x 2",
            ];
            break;
          case 14:
          case 15:
          case 16:
          case 17:
            features = [
              "Arcane Recovery",
              "Arcane Tradition",
              "Arcane Tradition feature x 3",
            ];
            break;
          case 18:
          case 19:
            features = [
              "Arcane Recovery",
              "Arcane Tradition",
              "Arcane Tradition feature x 3",
              "Spell Mastery",
            ];
            break;
          case 20:
            features = [
              "Arcane Recovery",
              "Arcane Tradition",
              "Arcane Tradition feature x 3",
              "Spell Mastery",
              "Signature Spells",
            ];
            break;
        }
        break;
    }
    return features;
  }

  /* This can be improved by making the slots data in to an external object or array, which is then called by the function.
    If an object, each level can be it's own array and the data can be called based on level without need for a switch.
    Same can be done for a multidimensional array. */
  function spell_slots(char_class, level) {
    let slots;

    switch (char_class) {
      //array key: first dimension is levels from 1 - 20, second dimension is cantrips known, spells known, then spell slots from 1 to 9.
      case "bard":
        slots = [
          [2, 4, 2, 0, 0, 0, 0, 0, 0, 0, 0],
          [2, 5, 3, 0, 0, 0, 0, 0, 0, 0, 0],
          [2, 6, 4, 2, 0, 0, 0, 0, 0, 0, 0],
          [3, 7, 4, 3, 0, 0, 0, 0, 0, 0, 0],
          [3, 8, 4, 3, 2, 0, 0, 0, 0, 0, 0],
          [3, 9, 4, 3, 2, 0, 0, 0, 0, 0, 0],
          [3, 9, 4, 3, 3, 0, 0, 0, 0, 0, 0],
          [3, 10, 4, 3, 3, 1, 0, 0, 0, 0, 0],
          [3, 11, 4, 3, 3, 2, 0, 0, 0, 0, 0],
          [3, 12, 4, 3, 3, 3, 1, 0, 0, 0, 0],
          [4, 15, 4, 3, 3, 3, 2, 1, 0, 0, 0],
          [4, 15, 4, 3, 3, 3, 2, 1, 0, 0, 0],
          [4, 16, 4, 3, 3, 3, 2, 1, 1, 0, 0],
          [4, 18, 4, 3, 3, 3, 2, 1, 1, 0, 0],
          [4, 19, 4, 3, 3, 3, 2, 1, 1, 1, 0],
          [4, 19, 4, 3, 3, 3, 2, 1, 1, 1, 0],
          [4, 20, 4, 3, 3, 3, 2, 1, 1, 1, 1],
          [4, 22, 4, 3, 3, 3, 3, 1, 1, 1, 1],
          [4, 22, 4, 3, 3, 3, 3, 2, 1, 1, 1],
          [4, 22, 4, 3, 3, 3, 3, 2, 2, 1, 1],
        ];
        break;
      case "cleric":
        slots = [
          [3, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
          [3, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0],
          [3, 0, 4, 2, 0, 0, 0, 0, 0, 0, 0],
          [4, 0, 4, 3, 0, 0, 0, 0, 0, 0, 0],
          [4, 0, 4, 3, 2, 0, 0, 0, 0, 0, 0],
          [4, 0, 4, 3, 3, 0, 0, 0, 0, 0, 0],
          [4, 0, 4, 3, 3, 1, 0, 0, 0, 0, 0],
          [4, 0, 4, 3, 3, 2, 0, 0, 0, 0, 0],
          [4, 0, 4, 3, 3, 3, 1, 0, 0, 0, 0],
          [5, 0, 4, 3, 3, 3, 2, 0, 0, 0, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 0, 0, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 0, 0, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 1, 0, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 1, 0, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 1, 1, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 1, 1, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 1, 1, 1],
          [5, 0, 4, 3, 3, 3, 3, 1, 1, 1, 1],
          [5, 0, 4, 3, 3, 3, 3, 2, 1, 1, 1],
          [5, 0, 4, 3, 3, 3, 3, 2, 2, 1, 1],
        ];
        break;
      case "druid":
        slots = [
          [2, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
          [2, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0],
          [2, 0, 4, 2, 0, 0, 0, 0, 0, 0, 0],
          [3, 0, 4, 3, 0, 0, 0, 0, 0, 0, 0],
          [3, 0, 4, 3, 2, 0, 0, 0, 0, 0, 0],
          [3, 0, 4, 3, 3, 0, 0, 0, 0, 0, 0],
          [3, 0, 4, 3, 3, 1, 0, 0, 0, 0, 0],
          [3, 0, 4, 3, 3, 2, 0, 0, 0, 0, 0],
          [3, 0, 4, 3, 3, 3, 1, 0, 0, 0, 0],
          [4, 0, 4, 3, 3, 3, 2, 0, 0, 0, 0],
          [4, 0, 4, 3, 3, 3, 2, 1, 0, 0, 0],
          [4, 0, 4, 3, 3, 3, 2, 1, 0, 0, 0],
          [4, 0, 4, 3, 3, 3, 2, 1, 1, 0, 0],
          [4, 0, 4, 3, 3, 3, 2, 1, 1, 0, 0],
          [4, 0, 4, 3, 3, 3, 2, 1, 1, 1, 0],
          [4, 0, 4, 3, 3, 3, 2, 1, 1, 1, 0],
          [4, 0, 4, 3, 3, 3, 2, 1, 1, 1, 1],
          [4, 0, 4, 3, 3, 3, 3, 1, 1, 1, 1],
          [4, 0, 4, 3, 3, 3, 3, 2, 1, 1, 1],
          [4, 0, 4, 3, 3, 3, 3, 2, 2, 1, 1],
        ];
        break;
      case "paladin":
        slots = [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 3, 2, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 3, 3, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 3, 3, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 3, 3, 1, 0, 0, 0, 0, 0, 0],
          [0, 0, 3, 3, 1, 0, 0, 0, 0, 0, 0],
          [0, 0, 3, 3, 2, 0, 0, 0, 0, 0, 0],
          [0, 0, 3, 3, 2, 0, 0, 0, 0, 0, 0],
          [0, 0, 3, 3, 3, 1, 0, 0, 0, 0, 0],
          [0, 0, 3, 3, 3, 1, 0, 0, 0, 0, 0],
          [0, 0, 3, 3, 3, 2, 0, 0, 0, 0, 0],
          [0, 0, 3, 3, 3, 2, 0, 0, 0, 0, 0],
        ];
        break;
      case "ranger":
        slots = [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [2, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
          [3, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0],
          [3, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0],
          [4, 0, 4, 2, 0, 0, 0, 0, 0, 0, 0],
          [4, 0, 4, 2, 0, 0, 0, 0, 0, 0, 0],
          [5, 0, 4, 3, 0, 0, 0, 0, 0, 0, 0],
          [5, 0, 4, 3, 0, 0, 0, 0, 0, 0, 0],
          [6, 0, 4, 3, 2, 0, 0, 0, 0, 0, 0],
          [6, 0, 4, 3, 2, 0, 0, 0, 0, 0, 0],
          [7, 0, 4, 3, 3, 0, 0, 0, 0, 0, 0],
          [7, 0, 4, 3, 3, 0, 0, 0, 0, 0, 0],
          [8, 0, 4, 3, 3, 1, 0, 0, 0, 0, 0],
          [8, 0, 4, 3, 3, 1, 0, 0, 0, 0, 0],
          [9, 0, 4, 3, 3, 2, 0, 0, 0, 0, 0],
          [9, 0, 4, 3, 3, 2, 0, 0, 0, 0, 0],
          [10, 0, 4, 3, 3, 3, 1, 0, 0, 0, 0],
          [10, 0, 4, 3, 3, 3, 1, 0, 0, 0, 0],
          [11, 0, 4, 3, 3, 3, 2, 0, 0, 0, 0],
          [11, 0, 4, 3, 3, 3, 2, 0, 0, 0, 0],
        ];
        break;
      case "sorcerer":
        slots = [
          [4, 2, 2, 0, 0, 0, 0, 0, 0, 0],
          [4, 3, 3, 0, 0, 0, 0, 0, 0, 0],
          [4, 4, 4, 2, 0, 0, 0, 0, 0, 0],
          [5, 5, 4, 3, 0, 0, 0, 0, 0, 0],
          [5, 6, 4, 3, 2, 0, 0, 0, 0, 0],
          [5, 7, 4, 3, 3, 0, 0, 0, 0, 0],
          [5, 8, 4, 3, 3, 1, 0, 0, 0, 0],
          [5, 9, 4, 3, 3, 2, 0, 0, 0, 0],
          [5, 10, 4, 3, 3, 3, 1, 0, 0, 0],
          [6, 11, 4, 3, 3, 3, 2, 0, 0, 0],
          [6, 12, 4, 3, 3, 3, 2, 1, 0, 0],
          [6, 12, 4, 3, 3, 3, 2, 1, 0, 0],
          [6, 13, 4, 3, 3, 3, 2, 1, 1, 0],
          [6, 13, 4, 3, 3, 3, 2, 1, 1, 0],
          [6, 14, 4, 3, 3, 3, 2, 1, 1, 1],
          [6, 14, 4, 3, 3, 3, 2, 1, 1, 1],
          [6, 15, 4, 3, 3, 3, 2, 1, 1, 1],
          [6, 15, 4, 3, 3, 3, 3, 1, 1, 1],
          [6, 15, 4, 3, 3, 3, 3, 2, 1, 1],
          [6, 15, 4, 3, 3, 3, 3, 2, 2, 1],
        ];
        break;
      case "warlock":
        slots = [
          [2, 2, 1, 0, 0, 0, 0, 0, 0, 0, 0],
          [2, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0],
          [2, 4, 0, 2, 0, 0, 0, 0, 0, 0, 0],
          [3, 5, 0, 2, 0, 0, 0, 0, 0, 0, 0],
          [3, 6, 0, 0, 2, 0, 0, 0, 0, 0, 0],
          [3, 7, 0, 0, 2, 0, 0, 0, 0, 0, 0],
          [3, 8, 0, 0, 0, 2, 0, 0, 0, 0, 0],
          [3, 9, 0, 0, 0, 2, 0, 0, 0, 0, 0],
          [3, 10, 0, 0, 0, 0, 2, 0, 0, 0, 0],
          [3, 10, 0, 0, 0, 0, 2, 0, 0, 0, 0],
          [3, 11, 0, 0, 0, 0, 3, 0, 0, 0, 0],
          [4, 11, 0, 0, 0, 0, 3, 0, 0, 0, 0],
          [4, 12, 0, 0, 0, 0, 3, 0, 0, 0, 0],
          [4, 12, 0, 0, 0, 0, 3, 0, 0, 0, 0],
          [4, 13, 0, 0, 0, 0, 3, 0, 0, 0, 0],
          [4, 13, 0, 0, 0, 0, 3, 0, 0, 0, 0],
          [4, 14, 0, 0, 0, 0, 4, 0, 0, 0, 0],
          [4, 14, 0, 0, 0, 0, 4, 0, 0, 0, 0],
          [4, 15, 0, 0, 0, 0, 4, 0, 0, 0, 0],
          [4, 15, 0, 0, 0, 0, 4, 0, 0, 0, 0],
        ];
        break;
      case "wizard":
        slots = [
          [3, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
          [3, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0],
          [3, 0, 4, 2, 0, 0, 0, 0, 0, 0, 0],
          [4, 0, 4, 3, 0, 0, 0, 0, 0, 0, 0],
          [4, 0, 4, 3, 2, 0, 0, 0, 0, 0, 0],
          [4, 0, 4, 3, 3, 0, 0, 0, 0, 0, 0],
          [4, 0, 4, 3, 3, 1, 0, 0, 0, 0, 0],
          [4, 0, 4, 3, 3, 2, 0, 0, 0, 0, 0],
          [4, 0, 4, 3, 3, 3, 1, 0, 0, 0, 0],
          [5, 0, 4, 3, 3, 3, 2, 0, 0, 0, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 0, 0, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 0, 0, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 1, 0, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 1, 0, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 1, 1, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 1, 1, 0],
          [5, 0, 4, 3, 3, 3, 2, 1, 1, 1, 1],
          [5, 0, 4, 3, 3, 3, 3, 1, 1, 1, 1],
          [5, 0, 4, 3, 3, 3, 3, 2, 1, 1, 1],
          [5, 0, 4, 3, 3, 3, 3, 2, 2, 1, 1],
        ];
        break;
      default:
        slots = [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ];
    }

    slots = slots[level - 1];
    return slots;
  }

  /*
    Things to add
    A random age in the canonical range, above the "minimum adult age"
    size
    */
  function racer(input) {
    let races = [
      "dragonborn",
      "dwarf",
      "elf",
      "gnome",
      "half-elf",
      "halfling",
      "half-orc",
      "human",
    ];
    let randomRace = races[randomize(races)];

    if (input === "random") {
      input = randomRace;
    }

    const race = new Object();

    switch (input) {
      case "dragonborn":
        race.name = input;
        race.abilities = {
          str: 2,
          dex: 0,
          con: 0,
          int: 0,
          wis: 0,
          cha: 1,
        };
        race.speed = 30;
        race.darkvision = false;
        race.traits = ["Draconic Ancestry", "Breath Weapon"];
        race.languages = ["Draconic", "Common"];
        race.skills = [];
        break;
      case "dwarf":
        race.name = input;
        race.abilities = {
          str: 0,
          dex: 0,
          con: 2,
          int: 0,
          wis: 0,
          cha: 0,
        };
        race.speed = 25;
        race.darkvision = true;
        race.traits = [
          "Dwarven Resilience",
          "Dwarven Combat Training",
          "Stonecunning",
        ];
        race.languages = ["Common", "Dwarvish"];
        race.skills = [];
        break;
      case "elf":
        race.name = input;
        race.abilities = {
          str: 0,
          dex: 2,
          con: 0,
          int: 0,
          wis: 0,
          cha: 0,
        };
        race.speed = 30;
        race.darkvision = true;
        race.traits = ["Fey Ancestry", "Trance"];
        race.languages = ["Common", "Elven"];
        race.skills = ["perception"];
        break;
      case "gnome":
        race.name = input;
        race.abilities = {
          str: 0,
          dex: 0,
          con: 0,
          int: 2,
          wis: 0,
          cha: 0,
        };
        race.speed = 25;
        race.darkvision = true;
        race.traits = ["Gnome Cunning"];
        race.languages = ["Common", "Gnomish"];
        race.skills = [];
        break;
      case "half-elf":
        function half_elf_ability_score() {
          let half_elf_base_abil = [0, 0, 0, 0, 0, 2];
          let random_abil_choice = [1, 1, 0, 0, 0, 0];
          function shuffle(a) {
            var j, x, i;
            for (i = a.length - 1; i > 0; i--) {
              j = Math.floor(Math.random() * (i + 1));
              x = a[i];
              a[i] = a[j];
              a[j] = x;
            }
            return a;
          }
          let shuffled_random_abil_choice = shuffle(random_abil_choice);
          let half_elf_abil = shuffled_random_abil_choice.map(
            (a, i) => a + half_elf_base_abil[i]
          );
          return half_elf_abil;
        }
        let halfElfAbilitieScores = half_elf_ability_score();

        race.name = input;
        race.abilities = {
          str: halfElfAbilitieScores[0],
          dex: halfElfAbilitieScores[1],
          con: halfElfAbilitieScores[2],
          int: halfElfAbilitieScores[3],
          wis: halfElfAbilitieScores[4],
          cha: halfElfAbilitieScores[5],
        };
        race.speed = 30;
        race.darkvision = true;
        race.traits = ["Fey Ancestry"];
        race.languages = ["Common", "Elven", "random"];
        race.skills = ["random", "random"];
        break;
      case "halfling":
        race.name = input;
        race.abilities = {
          str: 0,
          dex: 2,
          con: 0,
          int: 0,
          wis: 0,
          cha: 0,
        };
        race.speed = 25;
        race.darkvision = false;
        race.traits = ["Lucky", "Brave", "Nimble"];
        race.languages = ["Common", "Halfling"];
        race.skills = [];
        break;
      case "half-orc":
        race.name = input;
        race.abilities = {
          str: 2,
          dex: 0,
          con: 1,
          int: 0,
          wis: 0,
          cha: 0,
        };
        race.speed = 30;
        race.darkvision = true;
        race.traits = ["Menacing"];
        race.languages = ["Common", "Orc"];
        race.skills = ["intimidation"];
        break;
      case "human":
        race.name = input;
        race.abilities = {
          str: 1,
          dex: 1,
          con: 1,
          int: 1,
          wis: 1,
          cha: 1,
        };
        race.speed = 30;
        race.darkvision = false;
        race.traits = [];
        race.languages = ["Common", "random"];
        race.skills = [];
        break;
    }
    return race;
  }

  /* 
    Things to add
    class traits (rage, ki points, etc)
    */
  function classer(input, level) {
    let classes = [
      "barbarian",
      "bard",
      "cleric",
      "druid",
      "fighter",
      "monk",
      "paladin",
      "ranger",
      "rogue",
      "sorcerer",
      "warlock",
      "wizard",
    ];
    let randomClass = classes[randomize(classes)];

    if (input === "random") {
      input = randomClass;
    }

    const classinfo = new Object();
    switch (input) {
      case "barbarian":
        classinfo.name = "barbarian";
        classinfo.hitDie = 12;
        classinfo.savingThrows = ["strength", "constitution"];
        classinfo.armorProficiency = ["light", "medium", "shields"];
        classinfo.weaponProficiency = ["simple", "martial"];
        classinfo.toolProficiency = [];
        classinfo.skills = [
          "animal handling",
          "athletics",
          "intimidation",
          "nature",
          "survival",
        ];
        classinfo.equipment = [
          "greataxe",
          "2 handaxes",
          "explorer’s pack",
          "4 javelins",
        ];
        classinfo.features = featurer(classinfo.name, level);
        break;
      case "bard":
        classinfo.name = "bard";
        classinfo.hitDie = 8;
        classinfo.savingThrows = ["dexterity", "charisma"];
        classinfo.armorProficiency = ["light"];
        classinfo.weaponProficiency = [
          "simple",
          "hand crossbows",
          "longswords",
          "rapiers",
          "shortswords",
        ];
        classinfo.toolProficiency = ["three musical instruments"];
        classinfo.skills = ["random", "random", "random"];
        classinfo.equipment = [
          "rapier",
          "longsword",
          "any simple weapon",
          "diplomat’s pack",
          "entertainer’s pack",
          "lute",
        ];
        classinfo.features = featurer(classinfo.name, level);
        break;
      case "cleric":
        classinfo.name = "cleric";
        classinfo.hitDie = 8;
        classinfo.savingThrows = ["wisdom", "charisma"];
        classinfo.armorProficiency = ["light", "medium", "shields"];
        classinfo.weaponProficiency = ["simple"];
        classinfo.toolProficiency = [];
        classinfo.skills = [
          "history",
          "insight",
          "medicine",
          "persuasion",
          "religion",
        ];
        classinfo.equipment = [
          "mace",
          "shield",
          "holy symbol",
          "chain mail",
          "5 javelins",
        ];
        classinfo.features = featurer(classinfo.name, level);
        break;
      case "druid":
        classinfo.name = "druid";
        classinfo.hitDie = 8;
        classinfo.savingThrows = ["intelligence", "wisdom"];
        classinfo.armorProficiency = [
          "light",
          "medium (non-metal)",
          "shields (non-metal)",
        ];
        classinfo.weaponProficiency = [
          "clubs",
          "daggers",
          "darts",
          "javelins",
          "maces",
          "quarterstaffs",
          "scimitars",
          "sickles",
          "slings",
          "spears",
        ];
        classinfo.toolProficiency = ["herbalism kit"];
        classinfo.skills = [
          "arcana",
          "animal handling",
          "insight",
          "medicine",
          "nature",
          "perception",
          "religion",
          "survival",
        ];
        classinfo.equipment = [
          "shield",
          "scimitar",
          "leather armor",
          "explorer’s pack",
          "druidic focus",
        ];
        classinfo.features = featurer(classinfo.name, level);
        break;
      case "fighter":
        classinfo.name = "fighter";
        classinfo.hitDie = 8;
        classinfo.savingThrows = ["strength", "constitution"];
        classinfo.armorProficiency = ["light", "medium", "heavy", "shields"];
        classinfo.weaponProficiency = ["simple", "martial"];
        classinfo.toolProficiency = [];
        classinfo.skills = [
          "acrobatics",
          "animal handling",
          "athletics",
          "history",
          "insight",
          "intimidation",
          "perception",
          "survival",
        ];
        classinfo.equipment = [
          "chain mail",
          "shield",
          "longsword",
          "2 handaxes",
          "dungeoneer’s pack",
        ];
        classinfo.features = featurer(classinfo.name, level);
        break;
      case "monk":
        classinfo.name = "monk";
        classinfo.hitDie = 8;
        classinfo.savingThrows = ["strength", "dexterity"];
        classinfo.armorProficiency = ["light"];
        classinfo.weaponProficiency = ["simple", "shortswords"];
        classinfo.toolProficiency = ["artisan’s tools or musical instrument"];
        classinfo.skills = [
          "acrobatics",
          "athletics",
          "history",
          "insight",
          "religion",
          "stealth",
        ];
        classinfo.equipment = ["shortsword", "10 darts", "explorer’s pack"];
        classinfo.features = featurer(classinfo.name, level);
        break;
      case "paladin":
        classinfo.name = "paladin";
        classinfo.hitDie = 8;
        classinfo.savingThrows = ["wisdom", "charisma"];
        classinfo.armorProficiency = ["light", "medium", "heavy", "shields"];
        classinfo.weaponProficiency = ["simple", "martial"];
        classinfo.toolProficiency = [];
        classinfo.skills = [
          "athletics",
          "insight",
          "intimidation",
          "medicine",
          "persuasion",
          "religion",
        ];
        classinfo.equipment = [
          "longsword",
          "shield",
          "5 javelins",
          "priest’s pack",
          "explorer’s pack",
        ];
        classinfo.features = featurer(classinfo.name, level);
        break;
      case "ranger":
        classinfo.name = "ranger";
        classinfo.hitDie = 8;
        classinfo.savingThrows = ["strength", "dexterity"];
        classinfo.armorProficiency = ["light", "medium", "shields"];
        classinfo.weaponProficiency = ["simple", "martial"];
        classinfo.toolProficiency = [];
        classinfo.skills = [
          "animal handling",
          "athletics",
          "insight",
          "investigation",
          "nature",
          "perception",
          "stealth",
          "survival",
        ];
        classinfo.equipment = [
          "scale mail",
          "shortsword",
          "longbow",
          "quiver of 20 arrows",
          "dungeoneer’s pack",
          "explorer’s pack",
        ];
        classinfo.features = featurer(classinfo.name, level);
        break;
      case "rogue":
        classinfo.name = "rogue";
        classinfo.hitDie = 8;
        classinfo.savingThrows = ["dexterity", "intelligence"];
        classinfo.armorProficiency = ["light"];
        classinfo.weaponProficiency = [
          "simple",
          "hand crossbows",
          "longswords",
          "rapiers",
          "shortswords",
        ];
        classinfo.toolProficiency = ["thieves tools"];
        classinfo.skills = [
          "acrobatics",
          "athletics",
          "deception",
          "insight",
          "intimidation",
          "investigation",
          "perception",
          "performance",
          "persuasion",
          "sleight of hand",
          "stealth",
        ];
        classinfo.equipment = [
          "rapier",
          "shortsword",
          "shortbow",
          "quiver of 20 arrows",
          "burglar’s pack",
          "thieves’ tools",
        ];
        classinfo.features = featurer(classinfo.name, level);
        break;
      case "sorcerer":
        classinfo.name = "sorcerer";
        classinfo.hitDie = 8;
        classinfo.savingThrows = ["constitution", "charisma"];
        classinfo.armorProficiency = [];
        classinfo.weaponProficiency = [
          "daggers",
          "darts",
          "slings",
          "quarterstaffs",
          "light crossbows",
        ];
        classinfo.toolProficiency = [];
        classinfo.skills = [
          "arcana",
          "deception",
          "insight",
          "intimidation",
          "persuasion",
          "religion",
        ];
        classinfo.equipment = [
          "light crossbow",
          "20 bolts",
          "component pouch",
          "arcane focus",
          "dungeoneer’s pack",
        ];
        classinfo.features = featurer(classinfo.name, level);
        break;
      case "warlock":
        classinfo.name = "warlock";
        classinfo.hitDie = 8;
        classinfo.savingThrows = ["wisdom", "charisma"];
        classinfo.armorProficiency = ["light"];
        classinfo.weaponProficiency = ["simple"];
        classinfo.toolProficiency = [];
        classinfo.skills = [
          "arcana",
          "deception",
          "history",
          "intimidation",
          "investigation",
          "nature",
          "religion",
        ];
        classinfo.equipment = [
          "light crossbow",
          "20 bolts",
          "any simple weapon",
          "component pouch",
          "arcane focus",
          "scholar’s pack",
        ];
        classinfo.features = featurer(classinfo.name, level);
        break;
      case "wizard":
        classinfo.name = "wizard";
        classinfo.hitDie = 8;
        classinfo.savingThrows = ["intelligence", "wisdom"];
        classinfo.armorProficiency = [];
        classinfo.weaponProficiency = [
          "daggers",
          "darts",
          "slings",
          "quarterstaffs",
          "light crossbows",
        ];
        classinfo.toolProficiency = [];
        classinfo.skills = [
          "arcana",
          "history",
          "insight",
          "investigation",
          "medicine",
          "religion",
        ];
        classinfo.equipment = [
          "quarterstaff",
          "component pouch",
          "arcane focus",
          "scholar’s pack",
        ];
        classinfo.features = featurer(classinfo.name, level);
        break;
    }
    return classinfo;
  }

  /*Done*/
  function backgrounder(input) {
    let backgrounds = [
      "acolyte",
      "charlatan",
      "criminal",
      "entertainer",
      "folk hero",
      "gladiator",
      "guild artisan",
      "guild merchant",
      "hermit",
      "knight",
      "noble",
      "outlander",
      "pirate",
      "sage",
      "sailor",
      "soldier",
      "spy",
      "urchin",
    ];

    if (input === "random") {
      input = backgrounds[randomize(backgrounds)];
    }

    let background = new Object();

    switch (input) {
      case "acolyte":
        background.name = input;
        background.skills = ["insight", "religion"];
        background.languages = ["random", "random"];
        background.equipment = [
          "A Holy Symbol",
          "A Prayer Book",
          "Vestments",
          "A Set Of Common Clothes",
        ];
        background.gold = 15;
        background.feature = "Shelter Of The Faithful";
        break;
      case "charlatan":
        background.name = input;
        background.skills = ["deception", "sleight of hand"];
        background.languages = [];
        background.equipment = [
          "A Set Of Fine Clothes",
          "A Disguise Kit",
          "Con Tools",
        ];
        background.gold = 15;
        background.feature = "False Identity";

        break;
      case "criminal":
        background.name = input;
        background.skills = ["deception", "stealth"];
        background.languages = [];
        background.equipment = [
          "A Gaming Set",
          "Thieves' Tools",
          "A Crowbar",
          "A Set Of Dark Common Clothes Including A Hood",
        ];
        background.gold = 15;
        background.feature = "Criminal Contact";
        break;
      case "entertainer":
        background.name = input;
        background.skills = ["acrobatics", "performance"];
        background.languages = [];
        background.equipment = [
          "A Costume",
          "A Musical Instrument",
          "An Admirer's Favor",
        ];
        background.gold = 15;
        background.feature = "By Popular Demand";
        break;
      case "folk hero":
        background.name = input;
        background.skills = ["animal handling", "survival"];
        background.languages = [];
        background.equipment = [
          "A Set of Artisan's Tools",
          "A Shovel",
          "An Iron Pot",
          "A Set Of Common Clothes",
        ];
        background.gold = 10;
        background.feature = "Rustic Hospitality";
        break;
      case "gladiator":
        background.name = input;
        background.skills = ["acrobatics", "performance"];
        background.languages = [];
        background.equipment = [
          "A Costume",
          "An Unusual But Inexpensive Weapon",
          "An Admirer's Favor",
        ];
        background.gold = 15;
        background.feature = "By Popular Demand";
        break;
      case "guild artisan":
        background.name = input;
        background.skills = ["insight", "persuasion"];
        background.languages = ["random"];
        background.equipment = [
          "A Set Of Artisan Tools",
          "A Guild Letter Of Introduction",
          "A Set Of Traveler's Clothes",
        ];
        background.gold = 15;
        background.feature = "Guild Membership";
        break;
      case "guild merchant":
        background.name = input;
        background.skills = ["insight", "persuasion"];
        background.languages = ["random", "random"];
        background.equipment = [
          "A Cart With Mule",
          "A Guild Letter Of Introduction",
          "A Set Of Traveler's Clothes",
        ];
        background.gold = 15;
        background.feature = "Guild Membership";
        break;
      case "hermit":
        background.name = input;
        background.skills = ["medicine", "religion"];
        background.languages = ["random"];
        background.equipment = [
          "A Scroll Stuffed Full Of Research Notes",
          "A Winter Blanket",
          "A Set Of Common Clothes",
          "An Herbalism Kit",
        ];
        background.gold = 5;
        background.feature = "Discovery";
        break;
      case "knight":
        background.name = input;
        background.skills = ["history", "persuasion"];
        background.languages = ["random"];
        background.equipment = [
          "A Set Of Fine Clothes",
          "A Signet Ring",
          "A Scroll Of Pedigree",
        ];
        background.gold = 25;
        background.feature = "Retainers";
        break;
      case "noble":
        background.name = input;
        background.skills = ["history", "persuasion"];
        background.languages = ["random"];
        background.equipment = [
          "A Set Of Fine Clothes",
          "A Signet Ring",
          "A Scroll Of Pedigree",
        ];
        background.gold = 25;
        background.feature = "Position of Privilege";
        break;
      case "outlander":
        background.name = input;
        background.skills = ["athletics", "survival"];
        background.languages = ["random"];
        background.equipment = [
          "A Staff",
          "A Hunting Trap",
          "A Hunt Trophy",
          "A Set Of Traveler's Clothes",
        ];
        background.gold = 10;
        background.feature = "Wanderer";
        break;
      case "pirate":
        background.name = input;
        background.skills = ["athletics", "perception"];
        background.languages = [];
        background.equipment = [
          "A Belaying Pin",
          "50 Feet Of Silk Rope",
          "A Lucky Charm",
          "A Set Of Common Clothes",
        ];
        background.gold = 10;
        background.feature = "Bad Reputation";
        break;
      case "sage":
        background.name = input;
        background.skills = ["arcana", "history"];
        background.languages = ["random", "random"];
        background.equipment = [
          "A Bottle Of Ink",
          "A Quill",
          "A Small Knife",
          "A Letter From A Dead Colleague Posing A Question You Have Not Yet Been Able To Answer",
          "A Set Of Common Clothes",
        ];
        background.gold = 10;
        background.feature = "Researcher";
        break;
      case "sailor":
        background.name = input;
        background.skills = ["athletics", "perception"];
        background.languages = [];
        background.equipment = [
          "A Belaying Pin",
          "50 Feet Of Silk Rope",
          "A Lucky Charm",
          "A Set Of Common Clothes",
        ];
        background.gold = 10;
        background.feature = "Ship's Passage";
        break;
      case "soldier":
        background.name = input;
        background.skills = ["athletics", "intimidation"];
        background.languages = [];
        background.equipment = [
          "An Insignia Of Rank",
          "A War Trophy",
          "A Set Of Bone Dice",
          "A Set Of Common Clothes",
        ];
        background.gold = 10;
        background.feature = "Military Rank";
        break;
      case "spy":
        background.name = input;
        background.skills = ["deception", "stealth"];
        background.languages = [];
        background.equipment = [
          "A Gaming Set",
          "Thieves' Tools",
          "A Crowbar",
          "A Set Of Dark Common Clothes Including A Hood",
        ];
        background.gold = 15;
        background.feature = "Criminal Contact";
        break;
      case "urchin":
        background.name = input;
        background.skills = ["sleight of hand", "stealth"];
        background.languages = [];
        background.equipment = [
          "A Small Knife",
          "A Map Of Your Home City",
          "A Pet Mouse",
          "A Token From Your Parents",
          "A Set Of Common Clothes",
        ];
        background.gold = 10;
        background.feature = "City Secrets";
        break;
    }
    return background;
  }

  /*Done*/
  function languages(raceLang, backgroundLang) {
    function randomLanguage(lang) {
      var langList = [
        "Common",
        "Dwarvish",
        "Elven",
        "Giant",
        "Gnomish",
        "Goblin",
        "Halfling",
        "Orc",
        "Abyssal",
        "Celestial",
        "Deep Speech",
        "Draconic",
        "Infernal",
        "Primordial",
        "Sylvan",
        "Undercommon",
      ];
      var randomLangList = lang
        .filter((item) => item === "random")
        .map(() => {
          var randomIndex = Math.floor(Math.random() * langList.length);
          var randomLang = langList[randomIndex];
          langList.splice(randomIndex, 1);
          return randomLang;
        });
      return lang
        .filter((item) => item !== "random")
        .concat(randomLangList)
        .filter((item, index, arr) => arr.indexOf(item) === index);
    }
    let languagesKnown = raceLang.concat(backgroundLang);
    languagesKnown = randomLanguage(languagesKnown);
    return languagesKnown;
  }

  /*Output*/

  function character() {
    const character = new Object();

    character.gender = gender();
    character.level = leveler(level);
    character.class = classer(charClass);
    character.race = racer(race);
    character.background = backgrounder(background);
    character.languages = languages(
      character.race.languages,
      character.background.languages
    );
    character.alignment = aligner(alignment);
    character.name = namer(character.gender, character.race.name);
    character.attributes = characterAttributes(
      character.race.name,
      character.class.name,
      character.level
    );
    character.proficiencyBonus = proficiencyBonus(character.level);

    character.attributeMods = modifiers(character.attributes);

    character.strengthSave = character.attributeMods.strengthMod;
    character.dexteritySave = character.attributeMods.dexterityMod;
    character.constitutionSave = character.attributeMods.constitutionMod;
    character.wisdomSave = character.attributeMods.wisdomMod;
    character.intelligenceSave = character.attributeMods.intelligenceMod;
    character.charismaSave = character.attributeMods.charismaMod;

    character.hitDie = character.class.hitDie;
    character.hitpoints = hitpoints(
      character.attributeMods.constitutionMod,
      character.level,
      character.class.name,
      character.hitDie
    );
    character.speed = character.race.speed;
    character.initiative = character.attributeMods.dexterityMod;
    character.proficiencies = proficiencies(
      character.class.skills,
      character.background.skills,
      character.race.skills
    );
    character.skills = skiller(
      character.proficiencyBonus,
      character.attributeMods,
      character.proficiencies
    );
    character.passivePerception = 10 + character.skills.perception;

    character.featuresArray = featurer(character.class.name, character.level);

    character.spellSlots = spell_slots(character.class.name, character.level);
    character.traits = CharacterFlavor();
    return character;
  }

  let newCharacter = character(level, charClass, race, alignment, background);

  return {
    newCharacter,
  };
};

export default CharacterMath;
