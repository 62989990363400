
function capitalizeFirstLetter(string) {
  if (!string) {
    return "";
  }
  const trimmedString = string.trim();
  if (trimmedString.length === 0) {
    return "";
  }
  return trimmedString.charAt(0).toUpperCase() + trimmedString.slice(1);
}

function checker(key, array) {
  let checked = "";

  for (let i = 0; i < array.length; i++) {
    if (array[i].indexOf(key) >= 0) {
      checked = "checked";
    }
  }
  return checked;
}

function plusMinus(mod) {
  let plu_min = "";
  if (mod > 0) {
    plu_min = "+";
  }
  return plu_min;
}

//     function feature_string(char_features) {

//         var feature_string = "";

//         if (char_features.length > 1) {

//             feature_string = "<br>Features:<br>"
//             for (let i = 0; i < char_features.length - 1; i++) {
//                 feature_string += char_features[i];
//                 feature_string += "<br>";
//             }
//         }
//         return feature_string;
//     }

//     function spell_slot_string(char_class, spell_slots, level) {

//         var spell_slot_string = "";

//         switch (char_class) {
//             case "cleric":
//             case "druid":
//             case "wizard":

//                 switch (level) {
//                     case 1:
//                     case 2:
//                         spell_slot_string = "<br>Spell Slots:<br>Cantrips: " + spell_slots[0] + "<br>First: " + spell_slots[2];
//                         break;
//                     case 3:
//                     case 4:
//                         spell_slot_string = "<br>Spell Slots:<br>Cantrips: " + spell_slots[0] + "<br>First: "+ spell_slots[2] + "<br>Second: " + spell_slots[3];
//                         break;
//                     case 5:
//                     case 6:
//                         spell_slot_string = "<br>Spell Slots:<br>Cantrips: "+spell_slots[0]+"<br>First: "+ spell_slots[2]+"<br>Second: "+spell_slots[3]+"<br>Third: "+spell_slots[4];
//                         break;
//                     case 7:
//                     case 8:
//                         spell_slot_string = "<br>Spell Slots:<br>Cantrips: " + spell_slots[0]+"<br>First: " + spell_slots[2]+"<br>Second: " + spell_slots[3] + "<br>Third: " + spell_slots[4] + "<br>Fourth: " + spell_slots[5];
//                         break;

//                     case 9:
//                     case 10:
//                         spell_slot_string = "<br>Spell Slots:<br>Cantrips: "+spell_slots[0]+"<br>First: "+ spell_slots[2]+"<br>Second: " +spell_slots[3]+"<br>Third: "+spell_slots[4]+"<br>Fourth: "+spell_slots[5]+"<br>Fifth: "+spell_slots[6];
//                         break;
//                     case 11:
//                     case 12:
//                         spell_slot_string = "<br>Spell Slots:<br>Cantrips: "+spell_slots[0]+"<br>First: "+ spell_slots[2]+"<br>Second: " +spell_slots[3]+"<br>Third: "+spell_slots[4]+"<br>Fourth: "+spell_slots[5]+"<br>Fifth: "+spell_slots[6]+"<br>Sixth: "+spell_slots[7];
//                         break;
//                     case 13:
//                     case 14:
//                         spell_slot_string = "<br>Spell Slots:<br>Cantrips: "+spell_slots[0]+"<br>First: "+ spell_slots[2]+"<br>Second: " +spell_slots[3]+"<br>Third: "+spell_slots[4]+"<br>Fourth: "+spell_slots[5]+"<br>Fifth: "+spell_slots[6]+"<br>Sixth: "+spell_slots[7]+"<br>Seventh: "+spell_slots[8];
//                         break;
//                     case 15:
//                     case 16:
//                         spell_slot_string = "<br>Spell Slots:<br>Cantrips: " + spell_slots[0] + "<br>First: " + spell_slots[2] + "<br>Second: " + spell_slots[3] + "<br>Third: " + spell_slots[4] + "<br>Fourth: "+spell_slots[5]+"<br>Fifth: "+spell_slots[6]+"<br>Sixth: "+spell_slots[7]+"<br>Seventh: "+spell_slots[8]+"<br>Eigth: "+spell_slots[9];
//                         break;
//                     case 17:
//                     case 18:
//                     case 19:
//                     case 20:
//                         spell_slot_string = "<br>Spell Slots:<br>Cantrips: " + spell_slots[0]+"<br>First: " + spell_slots[2]+"<br>Second: " + spell_slots[3] + "<br>Third: " + spell_slots[4] + "<br>Fourth: " + spell_slots[5] + "<br>Fifth: " + spell_slots[6] + "<br>Sixth: " + spell_slots[7] + "<br>Seventh: " + spell_slots[8] + "<br>Eigth: " + spell_slots[9] + "<br>Ninth: " + spell_slots[10];
//                         break;
//                 }
//                 break;

//             case "warlock":

//                 switch (level) {
//                     case 1:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: "+spell_slots[0]+"<br>Cantrips: "+spell_slots[0]+"<br>First Level Spells: "+spell_slots[2]
//                         break;
//                     case 2:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: "+spell_slots[0]+"<br>Cantrips: "+spell_slots[0]+"<br>First Level Spells: "+spell_slots[2]+" Invocations Known: 2"
//                         break;
//                     case 3:
//                     case 4:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: "+spell_slots[0]+"<br>Cantrips: "+spell_slots[0]+"<br>Second Level Spells: "+spell_slots[3]+" Invocations Known: 2"
//                         break;
//                     case 5:
//                     case 6:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: "+spell_slots[0]+"<br>Cantrips: "+spell_slots[0]+"<br>Third Level Spells: "+spell_slots[4]+" Invocations Known: 3"
//                         break;
//                     case 7:
//                     case 8:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: "+spell_slots[0]+"<br>Cantrips: "+spell_slots[0]+"<br>Third Level Spells: "+spell_slots[5]+" Invocations Known: 4"
//                         break;
//                     case 9:
//                     case 10:
//                     case 11:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: "+spell_slots[0]+"<br>Cantrips: "+spell_slots[0]+"<br>Third Level Spells: "+spell_slots[6]+" Invocations Known: 5"
//                         break;
//                     case 12:
//                     case 13:
//                     case 14:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: "+spell_slots[0]+"<br>Cantrips: "+spell_slots[0]+"<br>Third Level Spells: "+spell_slots[6]+" Invocations Known: 6"
//                         break;
//                     case 15:
//                     case 16:
//                     case 17:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: "+spell_slots[0]+"<br>Cantrips: "+spell_slots[0]+"<br>Third Level Spells: "+spell_slots[6]+" Invocations Known: 7"
//                         break;
//                     case 18:
//                     case 19:
//                     case 20:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: "+spell_slots[0]+"<br>Cantrips: "+spell_slots[0]+"<br>Third Level Spells: "+spell_slots[6]+" Invocations Known: 8"
//                         break;
//                 }
//                 break;

//             case "bard":
//             case "sorcerer":
//                 switch (level) {
//                     case 1:
//                     case 2:
//                         spell_slot_string = "<br>Spells Known: "+spell_slots[2]+"<br>Spell Slots:<br>Cantrips: " + spell_slots[0] + "<br>First: " + spell_slots[2];
//                         break;
//                     case 3:
//                     case 4:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: " + spell_slots[0] + "<br>First: " + spell_slots[2] + "<br>Second: " + spell_slots[3];
//                         break;
//                     case 5:
//                     case 6:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: " + spell_slots[0] + "<br>First: " + spell_slots[2] + "<br>Second: " + spell_slots[3] + "<br>Third: " + spell_slots[4];
//                         break;
//                     case 7:
//                     case 8:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: " + spell_slots[0] + "<br>First: " + spell_slots[2] + "<br>Second: " + spell_slots[3] + "<br>Third: " + spell_slots[4] + "<br>Fourth: " + spell_slots[5];
//                         break;

//                     case 9:
//                     case 10:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: " + spell_slots[0] + "<br>First: " + spell_slots[2] + "<br>Second: " + spell_slots[3] + "<br>Third: " + spell_slots[4] + "<br>Fourth: " + spell_slots[5] + "<br>Fifth: " + spell_slots[6];
//                         break;
//                     case 11:
//                     case 12:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: " + spell_slots[0] + "<br>First: " + spell_slots[2] + "<br>Second: " + spell_slots[3] + "<br>Third: " + spell_slots[4] + "<br>Fourth: " + spell_slots[5] + "<br>Fifth: " + spell_slots[6] + "<br>Sixth: " + spell_slots[7];
//                         break;
//                     case 13:
//                     case 14:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: " + spell_slots[0] + "<br>First: " + spell_slots[2] + "<br>Second: " + spell_slots[3] + "<br>Third: " + spell_slots[4] + "<br>Fourth: " + spell_slots[5] + "<br>Fifth: " + spell_slots[6] + "<br>Sixth: " + spell_slots[7] + "<br>Seventh: " + spell_slots[8];
//                         break;
//                     case 15:
//                     case 16:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: " + spell_slots[0] + "<br>First: " + spell_slots[2] + "<br>Second: " + spell_slots[3] + "<br>Third: " + spell_slots[4] + "<br>Fourth: " + spell_slots[5] + "<br>Fifth: " + spell_slots[6] + "<br>Sixth: " + spell_slots[7] + "<br>Seventh: " + spell_slots[8] + "<br>Eigth: " + spell_slots[9];
//                         break;
//                     case 17:
//                     case 18:
//                     case 19:
//                     case 20:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>Cantrips: " + spell_slots[0] + "<br>First: " + spell_slots[2] + "<br>Second: " + spell_slots[3] + "<br>Third: " + spell_slots[4] + "<br>Fourth: " + spell_slots[5] + "<br>Fifth: " + spell_slots[6] + "<br>Sixth: " + spell_slots[7] + "<br>Seventh: " + spell_slots[8] + "<br>Eigth: " + spell_slots[9] + "<br>Ninth: " + spell_slots[10];
//                         break;
//                 }
//                 break;

//             case "ranger":

//                 switch (level) {
//                     case 1:
//                         break;
//                     case 2:
//                     case 3:
//                     case 4:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>First: "+spell_slots[2];
//                         break;
//                     case 5:
//                     case 6:
//                     case 7:
//                     case 8:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] +"<br>Spell Slots:<br>First: "+spell_slots[2]+"<br>Second: "+spell_slots[3];
//                         break;
//                     case 9:
//                     case 10:
//                     case 11:
//                     case 12:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] + "<br>Spell Slots:<br>First: " + spell_slots[2] + "<br>Second: " + spell_slots[3] +"<br>Third: "+spell_slots[4];
//                         break;
//                     case 13:
//                     case 14:
//                     case 15:
//                     case 16:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] + "<br>Spell Slots:<br>First: " + spell_slots[2] + "<br>Second: " + spell_slots[3] + "<br>Third: " + spell_slots[4] +"<br>Fourth: "+spell_slots[5];
//                         break;
//                     case 17:
//                     case 18:
//                     case 19:
//                     case 20:
//                         spell_slot_string = "<br>Spells Known: " + spell_slots[2] + "<br>Spell Slots:<br>First: " + spell_slots[2] + "<br>Second: "+spell_slots[3]+" <br> Third: "+spell_slots[4]+"<br>Fourth: " + spell_slots[5] +"<br>Fifth: "+spell_slots[6];

//                         break;
//                 }
//                 break;

//             case "paladin":

//                 switch (level) {
//                     case 1:
//                         break;
//                     case 2:
//                     case 3:
//                     case 4:
//                         spell_slot_string = "<br>Spell Slots:<br>First: "+spell_slots[2];
//                         break;
//                     case 5:
//                     case 6:
//                     case 7:
//                     case 8:
//                         spell_slot_string = "<br>Spell Slots:<br>First: "+spell_slots[2]+"<br>Second: "+spell_slots[3];
//                         break;
//                     case 9:
//                     case 10:
//                     case 11:
//                     case 12:
//                         spell_slot_string = "<br>Spell Slots:<br>First: "+spell_slots[2]+"<br>Second: "+spell_slots[3]+"<br>Third: "+spell_slots[4];
//                         break;
//                     case 13:
//                     case 14:
//                     case 15:
//                     case 16:
//                         spell_slot_string = "Spell Slots:<br>First: "+spell_slots[2]+"<br>Second: "+spell_slots[3]+"<br>Third: "+spell_slots[4]+"<br>Fourth: "+spell_slots[5];
//                         break;
//                     case 17:
//                     case 18:
//                     case 19:
//                     case 20:
//                         spell_slot_string = "<br>Spell Slots:<br>First: "+spell_slots[2]+"<br>Second: "+spell_slots[3]+"<br>Third: "+spell_slots[4]+"<br>Fourth: "+spell_slots[5]+"<br>Fifth: "+spell_slots[6];
//                         break;
//                 }

//                 break;

//             case "barbarian":
//                 switch (level) {
//                     case 1:
//                     case 2:
//                         spell_slot_string = "<br>Rages: 2<br>Rage Damage: +2";
//                         break;
//                     case 3:
//                     case 4:
//                     case 5:
//                         spell_slot_string = "<br>Rages: 3<br>Rage Damage: +2";
//                         break;
//                     case 6:
//                     case 7:
//                     case 8:
//                         spell_slot_string = "<br>Rages: 4<br>Rage Damage: +2";
//                         break;
//                     case 9:
//                     case 10:
//                     case 11:
//                         spell_slot_string = "<br>Rages: 4<br>Rage Damage: +3";
//                         break;
//                     case 12:
//                     case 13:
//                     case 14:
//                     case 15:
//                         spell_slot_string = "<br>Rages: 5<br>Rage Damage: +3";
//                         break;
//                     case 16:
//                         spell_slot_string = "<br>Rages: 5<br>Rage Damage: +4";
//                         break;
//                     case 17:
//                     case 18:
//                     case 19:
//                         spell_slot_string = "<br>Rages: 6<br>Rage Damage: +4";
//                         break;
//                     case 20:
//                         spell_slot_string = "<br>Rages: Unlimited<br>Rage Damage: +4";
//                         break;
//                 }
//                 break;

//             case "monk":
//                 switch (level) {
//                     case 1:
//                         break;
//                     case 2:
//                     case 3:
//                     case 4:
//                     case 5:
//                     case 6:
//                     case 7:
//                     case 8:
//                     case 9:
//                     case 10:
//                     case 11:
//                     case 12:
//                     case 13:
//                     case 14:
//                     case 15:
//                     case 16:
//                     case 17:
//                     case 18:
//                     case 19:
//                     case 20:
//                         spell_slot_string = "<br>Ki Points: " + level;
//                         break;
//                 }
//                 break;

//             case "rogue":
//                 switch (level) {
//                     case 1:
//                     case 2:
//                         spell_slot_string = "<br>Sneak Attack: 1d6";
//                         break;
//                     case 3:
//                     case 4:
//                         spell_slot_string = "<br>Sneak Attack: 2d6";
//                         break;
//                     case 5:
//                     case 6:
//                         spell_slot_string = "<br>Sneak Attack: 3d6";
//                         break;
//                     case 7:
//                     case 8:
//                         spell_slot_string = "<br>Sneak Attack: 4d6";
//                         break;
//                     case 9:
//                     case 10:
//                         spell_slot_string = "<br>Sneak Attack: 5d6";
//                         break;
//                     case 11:
//                     case 12:
//                         spell_slot_string = "<br>Sneak Attack: 6d6";
//                         break;
//                     case 13:
//                     case 14:
//                         spell_slot_string = "<br>Sneak Attack: 7d6";
//                         break;
//                     case 15:
//                     case 16:
//                         spell_slot_string = "<br>Sneak Attack: 8d6";
//                         break;
//                     case 17:
//                     case 18:
//                         spell_slot_string = "<br>Sneak Attack: 9d6";
//                         break;
//                     case 19:
//                     case 20:
//                         spell_slot_string = "<br>Sneak Attack: 10d6";
//                         break;
//                 }
//                 break;
//             case "fighter":
//                 break;
//         }
//         return spell_slot_string;
//     }

function languageString(characterLanguages) {
  var languageString = "";

  if (characterLanguages.length === 1) {
    languageString += characterLanguages[0];
  } else if (characterLanguages.length === 2) {
    languageString += characterLanguages[0];
    languageString += " and ";
    languageString += characterLanguages[1];
  } else {
    for (let i = 0; i < characterLanguages.length - 1; i++) {
      languageString += characterLanguages[i];
      languageString += ", ";
    }
    languageString += "and ";
    languageString += characterLanguages[characterLanguages.length - 1];
  }
  return languageString;
}

//     function proficiency_string(char_proficiencies) {
//         var prof_string = "";

//         if (char_proficiencies.length === 1) {
//             prof_string += capitalizeFirstLetter(char_proficiencies[0]);
//         } else if (char_proficiencies.length === 2) {
//             prof_string += capitalizeFirstLetter(char_proficiencies[0]);
//             prof_string += " and ";
//             prof_string += capitalizeFirstLetter(char_proficiencies[1]);
//         } else {
//             for (let i = 0; i < char_proficiencies.length - 1; i++) {
//                 prof_string += capitalizeFirstLetter(char_proficiencies[i]);
//                 prof_string += ", ";
//             }
//             prof_string += "and ";
//             prof_string += capitalizeFirstLetter(char_proficiencies[char_proficiencies.length - 1]);
//         }
//         return prof_string;
//     }
const CharSheet = ({ newCharacter }) => {

  return (
    <div className="w-[800] h-[971]">
      <form className="charsheet">
        <header>
          <section className="charname">
            <label htmlFor="charname">Character Name</label>
            <input
              name="charname"
              placeholder={`${newCharacter.name.fullName}`}
            />
          </section>
          <section className="misc">
            <ul>
              <li>
                <label htmlFor="classlevel">Class & Level</label>
                <input
                  id="classlevel"
                  name="classlevel"
                  placeholder={`${capitalizeFirstLetter(
                    newCharacter.class.name
                  )} ${newCharacter.level}`}
                />
              </li>
              <li>
                <label htmlFor="background">Background</label>
                <input
                  id="background"
                  name="background"
                  placeholder={`${newCharacter.background.name}`}
                />
              </li>
              <li>
                <label htmlFor="playername">Player Name</label>
                <input id="playername" name="playername" />
              </li>
              <li>
                <label htmlFor="race">Race</label>
                <input
                  id="race"
                  name="race"
                  placeholder={`${capitalizeFirstLetter(
                    newCharacter.race.name
                  )}`}
                />
              </li>
              <li>
                <label htmlFor="alignment">Alignment</label>
                <input
                  id="alignment"
                  name="alignment"
                  placeholder={`${newCharacter.alignment}`}
                />
              </li>
              <li>
                <label htmlFor="xp">Experience Points</label>
                <input id="xp" name="xp" />
              </li>
            </ul>
          </section>
        </header>
        <main>
          <section>
            <section className="attributes">
              <div className="scores">
                <ul>
                  <li>
                    <div className="score">
                      <label htmlFor="Strengthscore">Strength</label>
                      <input
                        name="Strengthscore"
                        placeholder={`${plusMinus(
                          newCharacter.attributeMods.strengthMod
                        )} ${newCharacter.attributeMods.strengthMod}`}
                      />
                    </div>
                    <div className="modifier">
                      <input
                        name="Strengthmod"
                        placeholder={`${newCharacter.attributes.strength}`}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="score">
                      <label htmlFor="Dexterityscore">Dexterity</label>
                      <input
                        name="Dexterityscore"
                        placeholder={`${plusMinus(
                          newCharacter.attributeMods.dexterityMod
                        )} ${newCharacter.attributeMods.dexterityMod}`}
                      />
                    </div>
                    <div className="modifier">
                      <input
                        name="Dexteritymod"
                        placeholder={`${newCharacter.attributes.dexterity}`}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="score">
                      <label htmlFor="Constitutionscore">Constitution</label>
                      <input
                        name="Constitutionscore"
                        placeholder={`${plusMinus(
                          newCharacter.attributeMods.constitutionMod
                        )} ${newCharacter.attributeMods.constitutionMod}`}
                      />
                    </div>
                    <div className="modifier">
                      <input
                        name="Constitutionmod"
                        placeholder={`${newCharacter.attributes.constitution}`}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="score">
                      <label htmlFor="Wisdomscore">Wisdom</label>
                      <input
                        name="Wisdomscore"
                        placeholder={`${plusMinus(
                          newCharacter.attributeMods.wisdomMod
                        )} ${newCharacter.attributeMods.wisdomMod}`}
                      />
                    </div>
                    <div className="modifier">
                      <input
                        name="Wisdommod"
                        placeholder={`${newCharacter.attributes.wisdom}`}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="score">
                      <label htmlFor="Intelligencescore">Intelligence</label>
                      <input
                        name="Intelligencescore"
                        placeholder={`${plusMinus(
                          newCharacter.attributeMods.intelligenceMod
                        )} ${newCharacter.attributeMods.intelligenceMod}`}
                      />
                    </div>
                    <div className="modifier">
                      <input
                        name="Intelligencemod"
                        placeholder={`${newCharacter.attributes.intelligence}`}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="score">
                      <label htmlFor="Charismascore">Charisma</label>
                      <input
                        name="Charismascore"
                        placeholder={`${plusMinus(
                          newCharacter.attributeMods.charismaMod
                        )} ${newCharacter.attributeMods.charismaMod}`}
                      />
                    </div>
                    <div className="modifier">
                      <input
                        name="Charismamod"
                        placeholder={`${newCharacter.attributes.charisma}`}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="attr-applications">
                <div className="inspiration box">
                  <div
                    className="label-container"
                    style={{
                      marginTop: "11px",
                      backgroundColor: "#eee",
                      borderRadius: "5px",
                      border: "0",
                    }}
                  >
                    <label htmlFor="inspiration">Inspiration</label>
                  </div>
                  <input
                    style={{ borderRadius: "17px" }}
                    name="inspiration"
                    type="checkbox"
                  />
                </div>
                <div className="proficiencybonus box">
                  <div
                    style={{ borderRadius: "2px" }}
                    className="label-container"
                  >
                    <label htmlFor="proficiencybonus">Proficiency Bonus</label>
                  </div>
                  <input
                    name="proficiencybonus"
                    placeholder={`${plusMinus(newCharacter.proficiencyBonus)} ${
                      newCharacter.proficiencyBonus
                    }`}
                  />
                </div>
                <div className="saves list-section box">
                  <ul>
                    <li>
                      <label htmlFor="Strength-save">Strength</label>
                      <input
                        name="Strength-save"
                        type="text"
                        placeholder={`${plusMinus(newCharacter.strengthSave)} ${
                          newCharacter.strengthSave
                        }`}
                      />
                      <input name="Strength-save-prof" type="checkbox" />
                    </li>
                    <li>
                      <label htmlFor="Dexterity-save">Dexterity</label>
                      <input
                        name="Dexterity-save"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.dexteritySave
                        )} ${newCharacter.dexteritySave}`}
                      />
                      <input name="Dexterity-save-prof" type="checkbox" />
                    </li>
                    <li>
                      <label htmlFor="Constitution-save">Constitution</label>
                      <input
                        name="Constitution-save"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.constitutionSave
                        )} ${newCharacter.constitutionSave}`}
                      />
                      <input name="Constitution-save-prof" type="checkbox" />
                    </li>
                    <li>
                      <label htmlFor="Wisdom-save">Wisdom</label>
                      <input
                        name="Wisdom-save"
                        type="text"
                        placeholder={`${plusMinus(newCharacter.wisdomSave)} ${
                          newCharacter.wisdomSave
                        }`}
                      />
                      <input name="Wisdom-save-prof" type="checkbox" />
                    </li>
                    <li>
                      <label htmlFor="Intelligence-save">Intelligence</label>
                      <input
                        name="Intelligence-save"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.intelligenceSave
                        )} ${newCharacter.intelligenceSave}`}
                      />
                      <input name="Intelligence-save-prof" type="checkbox" />
                    </li>
                    <li>
                      <label htmlFor="Charisma-save">Charisma</label>
                      <input
                        name="Charisma-save"
                        type="text"
                        placeholder={`${plusMinus(newCharacter.charismaSave)} ${
                          newCharacter.charismaSave
                        }`}
                      />
                      <input name="Charisma-save-prof" type="checkbox" />
                    </li>
                  </ul>
                  <div className="label">Saving Throws</div>
                </div>
                <div className="skills list-section box">
                  <ul>
                    <li>
                      <label htmlFor="Acrobatics">
                        Acrobatics <span className="skill">(Dex)</span>
                      </label>
                      <input
                        name="Acrobatics"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.acrobatics
                        )} ${newCharacter.skills.acrobatics}`}
                      />
                      <input
                        name="Acrobatics-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "acrobatics",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Animal Handling">
                        Animal Handling <span className="skill">(Wis)</span>
                      </label>
                      <input
                        name="Animal Handling"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.animalHandling
                        )} ${newCharacter.skills.animalHandling}`}
                      />
                      <input
                        name="Animal Handling-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "animal handling",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Arcana">
                        Arcana <span className="skill">(Int)</span>
                      </label>
                      <input
                        name="Arcana"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.arcana
                        )} ${newCharacter.skills.arcana}`}
                      />
                      <input
                        name="Arcana-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "arcana",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Athletics">
                        Athletics <span className="skill">(Str)</span>
                      </label>
                      <input
                        name="Athletics"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.athletics
                        )} ${newCharacter.skills.athletics}`}
                      />
                      <input
                        name="Athletics-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "athletics",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Deception">
                        Deception <span className="skill">(Cha)</span>
                      </label>
                      <input
                        name="Deception"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.deception
                        )} ${newCharacter.skills.deception}`}
                      />
                      <input
                        name="Deception-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "deception",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="History">
                        History <span className="skill">(Int)</span>
                      </label>
                      <input
                        name="History"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.history
                        )} ${newCharacter.skills.history}`}
                      />
                      <input
                        name="History-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "history",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Insight">
                        Insight <span className="skill">(Wis)</span>
                      </label>
                      <input
                        name="Insight"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.insight
                        )} ${newCharacter.skills.insight}`}
                      />
                      <input
                        name="Insight-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "insight",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Intimidation">
                        Intimidation <span className="skill">(Cha)</span>
                      </label>
                      <input
                        name="Intimidation"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.intimidation
                        )} ${plusMinus()}${newCharacter.skills.intimidation}`}
                      />
                      <input
                        name="Intimidation-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "intimidation",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Investigation">
                        Investigation <span className="skill">(Int)</span>
                      </label>
                      <input
                        name="Investigation"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.investigation
                        )} ${newCharacter.skills.investigation}`}
                      />
                      <input
                        name="Investigation-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "investigation",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Medicine">
                        Medicine <span className="skill">(Wis)</span>
                      </label>
                      <input
                        name="Medicine"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.medicine
                        )} ${newCharacter.skills.medicine}`}
                      />
                      <input
                        name="Medicine-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "medicine",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Nature">
                        Nature <span className="skill">(Int)</span>
                      </label>
                      <input
                        name="Nature"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.nature
                        )} ${newCharacter.skills.nature}`}
                      />
                      <input
                        name="Nature-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "nature",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Perception">
                        Perception <span className="skill">(Wis)</span>
                      </label>
                      <input
                        name="Perception"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.perception
                        )} ${newCharacter.skills.perception}`}
                      />
                      <input
                        name="Perception-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "perception",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Performance">
                        Performance <span className="skill">(Cha)</span>
                      </label>
                      <input
                        name="Performance"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.performance
                        )} ${newCharacter.skills.performance}`}
                      />
                      <input
                        name="Performance-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "performance",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Persuasion">
                        Persuasion <span className="skill">(Cha)</span>
                      </label>
                      <input
                        name="Persuasion"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.persuasion
                        )} ${newCharacter.skills.persuasion}`}
                      />
                      <input
                        name="Persuasion-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "persuasion",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Religion">
                        Religion <span className="skill">(Int)</span>
                      </label>
                      <input
                        name="Religion"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.religion
                        )} ${newCharacter.skills.religion}`}
                      />
                      <input
                        name="Religion-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "religion",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Sleight of Hand">
                        Sleight of Hand <span className="skill">(Dex)</span>
                      </label>
                      <input
                        name="Sleight of Hand"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.sleightOfHand
                        )} ${newCharacter.skills.sleightOfHand}`}
                      />
                      <input
                        name="Sleight of Hand-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "sleight of hand",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Stealth">
                        Stealth <span className="skill">(Dex)</span>
                      </label>
                      <input
                        name="Stealth"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.stealth
                        )} ${newCharacter.skills.stealth}`}
                      />
                      <input
                        name="Stealth-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "stealth",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                    <li>
                      <label htmlFor="Survival">
                        Survival <span className="skill">(Wis)</span>
                      </label>
                      <input
                        name="Survival"
                        type="text"
                        placeholder={`${plusMinus(
                          newCharacter.skills.survival
                        )} ${newCharacter.skills.survival}`}
                      />
                      <input
                        name="Survival-prof"
                        type="checkbox"
                        defaultChecked={checker(
                          "survival",
                          newCharacter.proficiencies
                        )}
                      />
                    </li>
                  </ul>
                  <div className="label">Skills</div>
                </div>
              </div>
            </section>
            <div className="passive-perception box">
              <div
                style={{ marginTop: "6px", borderRadius: "2px" }}
                className="label-container"
              >
                <label htmlFor="passiveperception">
                  Passive Wisdom (Perception)
                </label>
              </div>
              <input
                name="passiveperception"
                placeholder={`${plusMinus(newCharacter.passivePerception)} ${
                  newCharacter.passivePerception
                }`}
              />
            </div>
            <div
              style={{
                marginTop: "5px",
                padding: "5px",
                border: "1px solid black",
                borderRadius: "2px",
              }}
              className="otherprofs flex flex-col"
            >
              <textarea
                style={{ border: "0", height: "13.05rem", width: "100%", fontSize: "20px" }}
                name="otherprofs"
                placeholder={`${languageString(newCharacter.languages)}`}
              ></textarea>
              <label
                htmlFor="otherprofs"

                style={{ textAlign: "center", margin: "25px" }}
              >
                Other Proficiencies and Languages
              </label>
            </div>
          </section>
          <section>
            <section className="combat">
              <div className="armorclass">
                <div>
                  <label htmlFor="ac">Armor Class</label>
                  <input name="ac" type="text" />
                </div>
              </div>
              <div className="initiative">
                <div>
                  <label htmlFor="initiative">Initiative</label>
                  <input
                    name="initiative"
                    type="text"
                    placeholder={`${plusMinus(newCharacter.initiative)} ${
                      newCharacter.initiative
                    }`}
                  />
                </div>
              </div>
              <div className="speed">
                <div>
                  <label htmlFor="speed">Speed</label>
                  <input
                    name="speed"
                    type="text"
                    placeholder={`${newCharacter.race.speed}`}
                  />
                </div>
              </div>
              <div className="hp">
                <div className="regular">
                  <div className="max">
                    <label htmlFor="maxhp">Hit Point Maximum</label>
                    <input
                      name="maxhp"
                      type="text"
                      placeholder={`${newCharacter.hitpoints}`}
                    />
                  </div>
                  <div className="current">
                    <label htmlFor="currenthp">Current Hit Points</label>
                    <input
                      name="currenthp"
                      type="text"
                      placeholder={`${newCharacter.hitpoints}`}
                    />
                  </div>
                </div>
                <div className="temporary">
                  <label htmlFor="temphp">Temporary Hit Points</label>
                  <input name="temphp" type="text" />
                </div>
              </div>
              <div className="hitdice">
                <div>
                  <div className="total">
                    <label htmlFor="totalhd">Total</label>
                    <input
                      name="totalhd"
                      type="text"
                      placeholder={`${newCharacter.level}d ${newCharacter.hitDie}`}
                    />
                  </div>
                  <div className="remaining">
                    <label htmlFor="remaininghd">Hit Dice</label>
                    <input name="remaininghd" type="text" />
                  </div>
                </div>
              </div>
              <div className="deathsaves">
                <div>
                  <div className="label">
                    <label>Death Saves</label>
                  </div>
                  <div className="marks">
                    <div className="deathsuccesses">
                      <label>Successes</label>
                      <div className="bubbles">
                        <input
                          name="deathsuccess1"
                          type="checkbox"
                          className="deathsaves"
                        />
                        <input
                          name="deathsuccess2"
                          type="checkbox"
                          className="deathsaves"
                        />
                        <input
                          name="deathsuccess3"
                          type="checkbox"
                          className="deathsaves"
                        />
                      </div>
                    </div>
                    <div className="deathfails">
                      <label>Failures</label>
                      <div className="bubbles">
                        <input
                          name="deathfail1"
                          type="checkbox"
                          className="deathsaves"
                        />
                        <input
                          name="deathfail2"
                          type="checkbox"
                          className="deathsaves"
                        />
                        <input
                          name="deathfail3"
                          type="checkbox"
                          className="deathsaves"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="attacksandspellcasting">
              <div>
                <label>Attacks & Spellcasting</label>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Atk Bonus</th>
                      <th>Damage/Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input name="atkname1" type="text" />
                      </td>
                      <td>
                        <input name="atkbonus1" type="text" />
                      </td>
                      <td>
                        <input name="atkdamage1" type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input name="atkname2" type="text" />
                      </td>
                      <td>
                        <input name="atkbonus2" type="text" />
                      </td>
                      <td>
                        <input name="atkdamage2" type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input name="atkname3" type="text" />
                      </td>
                      <td>
                        <input name="atkbonus3" type="text" />
                      </td>
                      <td>
                        <input name="atkdamage3" type="text" />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <textarea
                  style={{ height: "10em" }}
                  id="attacks_and_spellcasting"
                ></textarea>
              </div>
            </section>
            <section className="equipment">
              <div>
                <label>Equipment</label>
                <div className="money">
                  <ul>
                    <li>
                      <label htmlFor="cp">CP</label>
                      <input name="cp" />
                    </li>
                    <li>
                      <label htmlFor="sp">SP</label>
                      <input name="sp" />
                    </li>
                    <li>
                      <label htmlFor="ep">EP</label>
                      <input name="ep" />
                    </li>
                    <li>
                      <label htmlFor="gp">GP</label>
                      <input
                        name="gp"
                        placeholder={`${newCharacter.background.gold}`}
                      />
                    </li>
                    <li>
                      <label htmlFor="pp">PP</label>
                      <input name="pp" />
                    </li>
                  </ul>
                </div>
                <textarea id="equipment" style={{ height: "19.2em" }}></textarea>
              </div>
            </section>
          </section>
          <section>
            <section className="flavor">
              <div className="personality ">
                <label htmlFor="personality">Personality</label>
                <textarea
                  className="w-full "
                  name="personality"
                  placeholder={`${newCharacter.traits.personalityOne} ${newCharacter.traits.personalityTwo}`}
                ></textarea>
              </div>
              <div className="ideals">
                <label htmlFor="ideals">Ideals</label>
                <textarea
                  name="ideals"
                  placeholder={`${newCharacter.traits.ideal}`}
                ></textarea>
              </div>
              <div className="bonds">
                <label htmlFor="bonds">Bonds</label>
                <textarea
                  name="bonds"
                  placeholder={`${newCharacter.traits.bond}`}
                ></textarea>
              </div>
              <div className="flaws w-full">
                <label htmlFor="flaws">Flaws</label>
                <textarea
                  name="flaws"
                  placeholder={`${newCharacter.traits.flaw}`}
                ></textarea>
              </div>
            </section>
            <section className="features">
              <div>
                <label htmlFor="features">Features & Traits</label>
                <textarea
                  style={{ height: "46.3em" }}
                  name="features"
                  placeholder={`${newCharacter.featuresArray}`}
                ></textarea>
              </div>
            </section>
          </section>
        </main>
        <footer></footer>
      </form>
    </div>
  );
};
export default CharSheet;
