import CharacterMath from "../components/CharacterMath";
import GenerateCharSheet from "../components/GenerateCharSheet";
import CharSummary from "../components/CharSummary";
import { useState, useEffect } from "react";

const CharacterGenerator = () => {
  const [inputs, setInputs] = useState(
    () =>
      JSON.parse(localStorage.getItem("CHAR_GEN_INPUTS")) || [
        {
          level: "random",
          charClass: "random",
          race: "random",
          alignment: "random",
          background: "random",
        },
      ]
  );

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value.toLowerCase() }));
  };

  const [outputs, setOutputs] = useState({
    showSheetButton: false,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      level = "random",
      charClass = "random",
      race = "random",
      alignment = "random",
      background = "random",
    } = inputs;
    const { newCharacter } = CharacterMath({
      level,
      charClass,
      race,
      alignment,
      background,
    });
    setOutputs({
      newCharacter: newCharacter,
    });
  };

  useEffect(() => {
    localStorage.setItem("CHAR_GEN_INPUTS", JSON.stringify(inputs));
  }, [inputs]);

  return (
    <div className="mx-auto max-w-7xl lg:py-5">
      <div className="m-4  mx-auto  max-w-fit rounded-sm bg-light-sixty">
        <div className="m-4 p-4">
          <div className=" mb-2 pb-2 text-center font-noirReg text-6xl text-light-thirty">
            D&D 5E Character Generator
          </div>
          <div className=" rounded-sm p-2 font-less">
            <form onSubmit={handleSubmit}>
              <div className="inline-block p-1">
                <label htmlFor="level" className="flex h-12 items-center">
                  <div className=" inline-block h-full rounded-tl-sm rounded-bl-sm border-2 border-light-thirty bg-light-thirty p-3 font-bold text-light-sixty">
                    Level
                  </div>
                  <select
                    className="my-1 inline-block h-full rounded-tr-sm rounded-br-sm border-2 border-light-thirty py-3 text-center bg-light-sixty"
                    name="level"
                    id="level"
                    value={inputs.level}
                    onChange={handleChange}
                  >
                    <option value="random">Random</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                  </select>
                </label>
              </div>
              <div className="inline-block p-1">
                <label htmlFor="class" className="flex h-12 items-center">
                  <div className="inline-block h-full rounded-tl-sm rounded-bl-sm border-2 border-light-thirty bg-light-thirty p-3 font-bold text-light-sixty">
                    Class
                  </div>
                  <select
                    className="my-1 inline-block h-full rounded-tr-sm rounded-br-sm border-2 border-light-thirty py-3 text-center bg-light-sixty"
                    name="charClass"
                    id="class"
                    value={inputs.charClass}
                    onChange={handleChange}
                  >
                    <option value="random">Random</option>
                    <option value="barbarian">Barbarian</option>
                    <option value="bard">Bard</option>
                    <option value="cleric">Cleric</option>
                    <option value="druid">Druid</option>
                    <option value="fighter">Fighter</option>
                    <option value="monk">Monk</option>
                    <option value="paladin">Paladin</option>
                    <option value="ranger">Ranger</option>
                    <option value="rogue">Rogue</option>
                    <option value="sorcerer">Sorcerer</option>
                    <option value="warlock">Warlock</option>
                    <option value="wizard">Wizard</option>
                  </select>
                </label>
              </div>
              <div className="inline-block p-1">
                <label htmlFor="race" className="flex h-12 items-center">
                  <div className="inline-block h-full rounded-tl-sm rounded-bl-sm border-2 border-light-thirty bg-light-thirty p-3 font-bold text-light-sixty">
                    Race
                  </div>
                  <select
                    className="my-1 inline-block h-full rounded-tr-sm rounded-br-sm border-2 border-light-thirty py-3 text-center bg-light-sixty"
                    name="race"
                    id="race"
                    value={inputs.race}
                    onChange={handleChange}
                  >
                    <option value="random">Random</option>
                    <option value="dragonborn">Dragonborn</option>
                    <option value="dwarf">Dwarf</option>
                    <option value="elf">Elf</option>
                    <option value="gnome">Gnome</option>
                    <option value="half-elf">Half-Elf</option>
                    <option value="halfling">Halfling</option>
                    <option value="half-orc">Half-Orc</option>
                    <option value="human">Human</option>
                  </select>
                </label>
              </div>
              <div className="inline-block p-1">
                <label htmlFor="alignment" className="flex h-12 items-center">
                  <div className="inline-block h-full rounded-tl-sm rounded-bl-sm border-2 border-light-thirty bg-light-thirty p-3 font-bold text-light-sixty">
                    Alignment
                  </div>
                  <select
                    className="my-1 inline-block h-full rounded-tr-sm rounded-br-sm border-2 border-light-thirty py-3 text-center bg-light-sixty"
                    name="alignment"
                    id="alignment"
                    value={inputs.alignment}
                    onChange={handleChange}
                  >
                    <option value="random">Random</option>
                    <option value="lawful good">Lawful Good</option>
                    <option value="lawful neutral">Lawful Neutral</option>
                    <option value="lawful evil">Lawful Evil</option>
                    <option value="neutral good">Neutral Good</option>
                    <option value="true neutral">True Neutral</option>
                    <option value="neutral evil">Neutral Evil</option>
                    <option value="chaotic good">Chaotic Good</option>
                    <option value="chaotic neutral">Chaotic Neutral</option>
                    <option value="chaotic evil">Chaotic Evil</option>
                  </select>
                </label>
              </div>
              <div className="inline-block p-1">
                <label htmlFor="background" className="flex h-12 items-center">
                  <div className="inline-block h-full rounded-tl-sm rounded-bl-sm border-2 border-light-thirty bg-light-thirty p-3 font-bold text-light-sixty">
                    Background
                  </div>
                  <select
                    className="my-1 inline-block h-full rounded-tr-sm rounded-br-sm border-2 border-light-thirty py-3 text-center bg-light-sixty"
                    name="background"
                    id="background"
                    value={inputs.background}
                    onChange={handleChange}
                  >
                    <option value="random">Random</option>
                    <option value="acolyte">Acolyte</option>
                    <option value="charlatan">Charlatan</option>
                    <option value="criminal">Criminal</option>
                    <option value="entertainer">Entertainer</option>
                    <option value="folk hero">Folk hero</option>
                    <option value="gladiator">Gladiator</option>
                    <option value="guild artisan">Guild artisan</option>
                    <option value="guild merchant">Guild merchant</option>
                    <option value="hermit">Hermit</option>
                    <option value="knight">Knight</option>
                    <option value="noble">Noble</option>
                    <option value="outlander">Outlander</option>
                    <option value="pirate">Pirate</option>
                    <option value="sage">Sage</option>
                    <option value="sailor">Sailor</option>
                    <option value="soldier">Soldier</option>
                    <option value="spy">Spy</option>
                    <option value="urchin">Urchin</option>
                  </select>
                </label>
              </div>
              <div className="lg:text-center">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className=" m-1 h-12 rounded-sm border-2 border-transparent bg-light-ten p-3 px-2 font-bold text-light-sixty hover:border-light-ten hover:bg-light-sixty hover:text-light-ten"
                >
                  Generate Character
                </button>
              </div>
            </form>
            <div>
              {outputs.newCharacter && (
                <div className="box-border max-w-6xl bg-light-sixty px-4 py-8 lg:text-2xl">
                  <CharSummary newCharacter={outputs.newCharacter} />
                </div>
              )}
            </div>
            <div className="flex justify-center p-1">
              {outputs.newCharacter && (
                <div>
                  <div className="m-1 h-12 flex-col items-center justify-center rounded-sm border-2 border-transparent bg-light-ten p-3 px-2 font-bold text-light-sixty hover:border-light-ten hover:bg-light-sixty hover:text-light-ten">
                    <GenerateCharSheet newCharacter={outputs.newCharacter} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CharacterGenerator;
