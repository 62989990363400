import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
//Make sure to use the /compat version of imports, as used above. 
//The new version of firebase seems to not like the "correct" way as described below. True as of 3/27/23


// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

//Connects to "Personal Website Backend"

const firebaseConfig = {
  apiKey: "AIzaSyCJ75HnnYeAh_PunjpJSBCJ4ZM3k2yO9iQ",
  authDomain: "flash-spark-381002.firebaseapp.com",
  projectId: "flash-spark-381002",
  storageBucket: "flash-spark-381002.appspot.com",
  messagingSenderId: "987517172637",
  appId: "1:987517172637:web:7c28f8f2b464dbceed0d74",
  measurementId: "G-4K1FLLDZ85"
}

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

const app = firebase.initializeApp(firebaseConfig);
const firestore = app.firestore();
const storage = app.storage();

export { app, firestore, storage };