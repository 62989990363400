

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function capitalizeEachWord(strings) {
  let newStrings = strings.slice();
  for (let i = 0; i < newStrings.length; i++) {
    newStrings[i] =
      newStrings[i].charAt(0).toUpperCase() + newStrings[i].slice(1);
  }
  return newStrings;
}

function plusMinus(mod) {
  let plusMinus = "";
  if (mod > 0) {
    plusMinus = "+";
  }
  return plusMinus;
}

function isAre(gender) {
  var isAre = "";
  if (gender === "male") {
    isAre = "is";
  } else if (gender === "female") {
    isAre = "is";
  } else if (gender === "either") {
    isAre = "are";
  }
  return isAre;
}

function haveHas(gender) {
  var haveHas;
  switch (gender) {
    case "male":
    case "female":
      haveHas = "has";
      break;
    case "either":
      haveHas = "have";
      break;
  }
  return haveHas;
}

function pronoun(gender) {
  let pronoun = new Object();
  switch (gender) {
    case "male":
      pronoun.one = "he";
      pronoun.two = "him";
      pronoun.three = "his";
      break;
    case "female":
      pronoun.one = "she";
      pronoun.two = "her";
      pronoun.three = "her";
      break;
    case "either":
      pronoun.one = "they";
      pronoun.two = "them";
      pronoun.three = "their";
      break;
  }
  return pronoun;
}
// function feature_string(char_features) {

//     var feature_string = "";

//     if (char_features.length > 1) {

//         feature_string = "<br />Features:<br />"
//         for (let i = 0; i < char_features.length - 1; i++) {
//             feature_string += char_features[i];
//             feature_string += "<br />";
//         }
//     }
//     return feature_string;
// }

// function spell_slot_string(char_class, spell_slots, level) {

//     var spell_slot_string = "";

//     switch (char_class) {
//         case "cleric":
//         case "druid":
//         case "wizard":

//             switch (level) {
//                 case 1:
//                 case 2:
//                     spell_slot_string = "<br />Spell Slots:<br />Cantrips: " + spell_slots[0] + "<br />First: " + spell_slots[2];
//                     break;
//                 case 3:
//                 case 4:
//                     spell_slot_string = "<br />Spell Slots:<br />Cantrips: " + spell_slots[0] + "<br />First: "+ spell_slots[2] + "<br />Second: " + spell_slots[3];
//                     break;
//                 case 5:
//                 case 6:
//                     spell_slot_string = "<br />Spell Slots:<br />Cantrips: "+spell_slots[0]+"<br />First: "+ spell_slots[2]+"<br />Second: "+spell_slots[3]+"<br />Third: "+spell_slots[4];
//                     break;
//                 case 7:
//                 case 8:
//                     spell_slot_string = "<br />Spell Slots:<br />Cantrips: " + spell_slots[0]+"<br />First: " + spell_slots[2]+"<br />Second: " + spell_slots[3] + "<br />Third: " + spell_slots[4] + "<br />Fourth: " + spell_slots[5];
//                     break;

//                 case 9:
//                 case 10:
//                     spell_slot_string = "<br />Spell Slots:<br />Cantrips: "+spell_slots[0]+"<br />First: "+ spell_slots[2]+"<br />Second: " +spell_slots[3]+"<br />Third: "+spell_slots[4]+"<br />Fourth: "+spell_slots[5]+"<br />Fifth: "+spell_slots[6];
//                     break;
//                 case 11:
//                 case 12:
//                     spell_slot_string = "<br />Spell Slots:<br />Cantrips: "+spell_slots[0]+"<br />First: "+ spell_slots[2]+"<br />Second: " +spell_slots[3]+"<br />Third: "+spell_slots[4]+"<br />Fourth: "+spell_slots[5]+"<br />Fifth: "+spell_slots[6]+"<br />Sixth: "+spell_slots[7];
//                     break;
//                 case 13:
//                 case 14:
//                     spell_slot_string = "<br />Spell Slots:<br />Cantrips: "+spell_slots[0]+"<br />First: "+ spell_slots[2]+"<br />Second: " +spell_slots[3]+"<br />Third: "+spell_slots[4]+"<br />Fourth: "+spell_slots[5]+"<br />Fifth: "+spell_slots[6]+"<br />Sixth: "+spell_slots[7]+"<br />Seventh: "+spell_slots[8];
//                     break;
//                 case 15:
//                 case 16:
//                     spell_slot_string = "<br />Spell Slots:<br />Cantrips: " + spell_slots[0] + "<br />First: " + spell_slots[2] + "<br />Second: " + spell_slots[3] + "<br />Third: " + spell_slots[4] + "<br />Fourth: "+spell_slots[5]+"<br />Fifth: "+spell_slots[6]+"<br />Sixth: "+spell_slots[7]+"<br />Seventh: "+spell_slots[8]+"<br />Eigth: "+spell_slots[9];
//                     break;
//                 case 17:
//                 case 18:
//                 case 19:
//                 case 20:
//                     spell_slot_string = "<br />Spell Slots:<br />Cantrips: " + spell_slots[0]+"<br />First: " + spell_slots[2]+"<br />Second: " + spell_slots[3] + "<br />Third: " + spell_slots[4] + "<br />Fourth: " + spell_slots[5] + "<br />Fifth: " + spell_slots[6] + "<br />Sixth: " + spell_slots[7] + "<br />Seventh: " + spell_slots[8] + "<br />Eigth: " + spell_slots[9] + "<br />Ninth: " + spell_slots[10];
//                     break;
//             }
//             break;

//         case "warlock":

//             switch (level) {
//                 case 1:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: "+spell_slots[0]+"<br />Cantrips: "+spell_slots[0]+"<br />First Level Spells: "+spell_slots[2]
//                     break;
//                 case 2:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: "+spell_slots[0]+"<br />Cantrips: "+spell_slots[0]+"<br />First Level Spells: "+spell_slots[2]+" Invocations Known: 2"
//                     break;
//                 case 3:
//                 case 4:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: "+spell_slots[0]+"<br />Cantrips: "+spell_slots[0]+"<br />Second Level Spells: "+spell_slots[3]+" Invocations Known: 2"
//                     break;
//                 case 5:
//                 case 6:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: "+spell_slots[0]+"<br />Cantrips: "+spell_slots[0]+"<br />Third Level Spells: "+spell_slots[4]+" Invocations Known: 3"
//                     break;
//                 case 7:
//                 case 8:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: "+spell_slots[0]+"<br />Cantrips: "+spell_slots[0]+"<br />Third Level Spells: "+spell_slots[5]+" Invocations Known: 4"
//                     break;
//                 case 9:
//                 case 10:
//                 case 11:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: "+spell_slots[0]+"<br />Cantrips: "+spell_slots[0]+"<br />Third Level Spells: "+spell_slots[6]+" Invocations Known: 5"
//                     break;
//                 case 12:
//                 case 13:
//                 case 14:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: "+spell_slots[0]+"<br />Cantrips: "+spell_slots[0]+"<br />Third Level Spells: "+spell_slots[6]+" Invocations Known: 6"
//                     break;
//                 case 15:
//                 case 16:
//                 case 17:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: "+spell_slots[0]+"<br />Cantrips: "+spell_slots[0]+"<br />Third Level Spells: "+spell_slots[6]+" Invocations Known: 7"
//                     break;
//                 case 18:
//                 case 19:
//                 case 20:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: "+spell_slots[0]+"<br />Cantrips: "+spell_slots[0]+"<br />Third Level Spells: "+spell_slots[6]+" Invocations Known: 8"
//                     break;
//             }
//             break;

//         case "bard":
//         case "sorcerer":
//             switch (level) {
//                 case 1:
//                 case 2:
//                     spell_slot_string = "<br />Spells Known: "+spell_slots[2]+"<br />Spell Slots:<br />Cantrips: " + spell_slots[0] + "<br />First: " + spell_slots[2];
//                     break;
//                 case 3:
//                 case 4:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: " + spell_slots[0] + "<br />First: " + spell_slots[2] + "<br />Second: " + spell_slots[3];
//                     break;
//                 case 5:
//                 case 6:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: " + spell_slots[0] + "<br />First: " + spell_slots[2] + "<br />Second: " + spell_slots[3] + "<br />Third: " + spell_slots[4];
//                     break;
//                 case 7:
//                 case 8:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: " + spell_slots[0] + "<br />First: " + spell_slots[2] + "<br />Second: " + spell_slots[3] + "<br />Third: " + spell_slots[4] + "<br />Fourth: " + spell_slots[5];
//                     break;

//                 case 9:
//                 case 10:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: " + spell_slots[0] + "<br />First: " + spell_slots[2] + "<br />Second: " + spell_slots[3] + "<br />Third: " + spell_slots[4] + "<br />Fourth: " + spell_slots[5] + "<br />Fifth: " + spell_slots[6];
//                     break;
//                 case 11:
//                 case 12:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: " + spell_slots[0] + "<br />First: " + spell_slots[2] + "<br />Second: " + spell_slots[3] + "<br />Third: " + spell_slots[4] + "<br />Fourth: " + spell_slots[5] + "<br />Fifth: " + spell_slots[6] + "<br />Sixth: " + spell_slots[7];
//                     break;
//                 case 13:
//                 case 14:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: " + spell_slots[0] + "<br />First: " + spell_slots[2] + "<br />Second: " + spell_slots[3] + "<br />Third: " + spell_slots[4] + "<br />Fourth: " + spell_slots[5] + "<br />Fifth: " + spell_slots[6] + "<br />Sixth: " + spell_slots[7] + "<br />Seventh: " + spell_slots[8];
//                     break;
//                 case 15:
//                 case 16:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: " + spell_slots[0] + "<br />First: " + spell_slots[2] + "<br />Second: " + spell_slots[3] + "<br />Third: " + spell_slots[4] + "<br />Fourth: " + spell_slots[5] + "<br />Fifth: " + spell_slots[6] + "<br />Sixth: " + spell_slots[7] + "<br />Seventh: " + spell_slots[8] + "<br />Eigth: " + spell_slots[9];
//                     break;
//                 case 17:
//                 case 18:
//                 case 19:
//                 case 20:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />Cantrips: " + spell_slots[0] + "<br />First: " + spell_slots[2] + "<br />Second: " + spell_slots[3] + "<br />Third: " + spell_slots[4] + "<br />Fourth: " + spell_slots[5] + "<br />Fifth: " + spell_slots[6] + "<br />Sixth: " + spell_slots[7] + "<br />Seventh: " + spell_slots[8] + "<br />Eigth: " + spell_slots[9] + "<br />Ninth: " + spell_slots[10];
//                     break;
//             }
//             break;

//         case "ranger":

//             switch (level) {
//                 case 1:
//                     break;
//                 case 2:
//                 case 3:
//                 case 4:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />First: "+spell_slots[2];
//                     break;
//                 case 5:
//                 case 6:
//                 case 7:
//                 case 8:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] +"<br />Spell Slots:<br />First: "+spell_slots[2]+"<br />Second: "+spell_slots[3];
//                     break;
//                 case 9:
//                 case 10:
//                 case 11:
//                 case 12:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] + "<br />Spell Slots:<br />First: " + spell_slots[2] + "<br />Second: " + spell_slots[3] +"<br />Third: "+spell_slots[4];
//                     break;
//                 case 13:
//                 case 14:
//                 case 15:
//                 case 16:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] + "<br />Spell Slots:<br />First: " + spell_slots[2] + "<br />Second: " + spell_slots[3] + "<br />Third: " + spell_slots[4] +"<br />Fourth: "+spell_slots[5];
//                     break;
//                 case 17:
//                 case 18:
//                 case 19:
//                 case 20:
//                     spell_slot_string = "<br />Spells Known: " + spell_slots[2] + "<br />Spell Slots:<br />First: " + spell_slots[2] + "<br />Second: "+spell_slots[3]+" <br /> Third: "+spell_slots[4]+"<br />Fourth: " + spell_slots[5] +"<br />Fifth: "+spell_slots[6];

//                     break;
//             }
//             break;

//         case "paladin":

//             switch (level) {
//                 case 1:
//                     break;
//                 case 2:
//                 case 3:
//                 case 4:
//                     spell_slot_string = "<br />Spell Slots:<br />First: "+spell_slots[2];
//                     break;
//                 case 5:
//                 case 6:
//                 case 7:
//                 case 8:
//                     spell_slot_string = "<br />Spell Slots:<br />First: "+spell_slots[2]+"<br />Second: "+spell_slots[3];
//                     break;
//                 case 9:
//                 case 10:
//                 case 11:
//                 case 12:
//                     spell_slot_string = "<br />Spell Slots:<br />First: "+spell_slots[2]+"<br />Second: "+spell_slots[3]+"<br />Third: "+spell_slots[4];
//                     break;
//                 case 13:
//                 case 14:
//                 case 15:
//                 case 16:
//                     spell_slot_string = "Spell Slots:<br />First: "+spell_slots[2]+"<br />Second: "+spell_slots[3]+"<br />Third: "+spell_slots[4]+"<br />Fourth: "+spell_slots[5];
//                     break;
//                 case 17:
//                 case 18:
//                 case 19:
//                 case 20:
//                     spell_slot_string = "<br />Spell Slots:<br />First: "+spell_slots[2]+"<br />Second: "+spell_slots[3]+"<br />Third: "+spell_slots[4]+"<br />Fourth: "+spell_slots[5]+"<br />Fifth: "+spell_slots[6];
//                     break;
//             }

//             break;

//         case "barbarian":
//             switch (level) {
//                 case 1:
//                 case 2:
//                     spell_slot_string = "<br />Rages: 2<br />Rage Damage: +2";
//                     break;
//                 case 3:
//                 case 4:
//                 case 5:
//                     spell_slot_string = "<br />Rages: 3<br />Rage Damage: +2";
//                     break;
//                 case 6:
//                 case 7:
//                 case 8:
//                     spell_slot_string = "<br />Rages: 4<br />Rage Damage: +2";
//                     break;
//                 case 9:
//                 case 10:
//                 case 11:
//                     spell_slot_string = "<br />Rages: 4<br />Rage Damage: +3";
//                     break;
//                 case 12:
//                 case 13:
//                 case 14:
//                 case 15:
//                     spell_slot_string = "<br />Rages: 5<br />Rage Damage: +3";
//                     break;
//                 case 16:
//                     spell_slot_string = "<br />Rages: 5<br />Rage Damage: +4";
//                     break;
//                 case 17:
//                 case 18:
//                 case 19:
//                     spell_slot_string = "<br />Rages: 6<br />Rage Damage: +4";
//                     break;
//                 case 20:
//                     spell_slot_string = "<br />Rages: Unlimited<br />Rage Damage: +4";
//                     break;
//             }
//             break;

//         case "monk":
//             switch (level) {
//                 case 1:
//                     break;
//                 case 2:
//                 case 3:
//                 case 4:
//                 case 5:
//                 case 6:
//                 case 7:
//                 case 8:
//                 case 9:
//                 case 10:
//                 case 11:
//                 case 12:
//                 case 13:
//                 case 14:
//                 case 15:
//                 case 16:
//                 case 17:
//                 case 18:
//                 case 19:
//                 case 20:
//                     spell_slot_string = "<br />Ki Points: " + level;
//                     break;
//             }
//             break;

//         case "rogue":
//             switch (level) {
//                 case 1:
//                 case 2:
//                     spell_slot_string = "<br />Sneak Attack: 1d6";
//                     break;
//                 case 3:
//                 case 4:
//                     spell_slot_string = "<br />Sneak Attack: 2d6";
//                     break;
//                 case 5:
//                 case 6:
//                     spell_slot_string = "<br />Sneak Attack: 3d6";
//                     break;
//                 case 7:
//                 case 8:
//                     spell_slot_string = "<br />Sneak Attack: 4d6";
//                     break;
//                 case 9:
//                 case 10:
//                     spell_slot_string = "<br />Sneak Attack: 5d6";
//                     break;
//                 case 11:
//                 case 12:
//                     spell_slot_string = "<br />Sneak Attack: 6d6";
//                     break;
//                 case 13:
//                 case 14:
//                     spell_slot_string = "<br />Sneak Attack: 7d6";
//                     break;
//                 case 15:
//                 case 16:
//                     spell_slot_string = "<br />Sneak Attack: 8d6";
//                     break;
//                 case 17:
//                 case 18:
//                     spell_slot_string = "<br />Sneak Attack: 9d6";
//                     break;
//                 case 19:
//                 case 20:
//                     spell_slot_string = "<br />Sneak Attack: 10d6";
//                     break;
//             }
//             break;
//         case "fighter":
//             break;
//     }
//     return spell_slot_string;
// }

function languageString(characterLanguage) {
  let newCharacterLanguage = characterLanguage.slice();
  capitalizeEachWord(newCharacterLanguage);
  var languageString = "";

  if (newCharacterLanguage.length === 1) {
    languageString += newCharacterLanguage[0];
  } else if (newCharacterLanguage.length === 2) {
    languageString += newCharacterLanguage[0];
    languageString += " and ";
    languageString += newCharacterLanguage[1];
  } else {
    for (let i = 0; i < newCharacterLanguage.length - 1; i++) {
      languageString += newCharacterLanguage[i];
      languageString += ", ";
    }
    languageString += "and ";
    languageString += newCharacterLanguage[newCharacterLanguage.length - 1];
  }
  return languageString;
}

function proficiencyString(characterProficiences) {
  let newCharacterProficiencies = characterProficiences.slice();
  newCharacterProficiencies = capitalizeEachWord(newCharacterProficiencies);
  let proficiencyString = "";

  if (newCharacterProficiencies.length === 1) {
    proficiencyString += newCharacterProficiencies[0];
  } else if (newCharacterProficiencies.length === 2) {
    proficiencyString += newCharacterProficiencies[0];
    proficiencyString += " and ";
    proficiencyString += newCharacterProficiencies[1];
  } else {
    for (let i = 0; i < newCharacterProficiencies.length - 1; i++) {
      proficiencyString += newCharacterProficiencies[i];
      proficiencyString += ", ";
    }
    proficiencyString += "and ";
    proficiencyString +=
      newCharacterProficiencies[newCharacterProficiencies.length - 1];
  }
  return proficiencyString;
}

const CharSummary = ({ newCharacter }) => {
  newCharacter.pronoun = pronoun(newCharacter.gender);

  

  console.log(newCharacter.traits.personalityOne, "summary");

  if (newCharacter)
    return (
      <div className="display: .md:flex">
        Your character's name is {`${newCharacter.name.fullName}`}.
        <br />{" "}
        {`${capitalizeFirstLetter(newCharacter.pronoun.one)} ${isAre(
          newCharacter.gender
        )}`}{" "}
        a level{" "}
        {`${newCharacter.level} ${capitalizeFirstLetter(
          newCharacter.race.name
        )} ${capitalizeFirstLetter(newCharacter.class.name)}`}
        .
        <br />{" "}
        {`${capitalizeFirstLetter(newCharacter.pronoun.one)} ${isAre(
          newCharacter.gender
        )} ${newCharacter.alignment}`}
        .
        <br /> {`${capitalizeFirstLetter(newCharacter.pronoun.three)}`} Ability
        Scores are:
        <br /> Str:{" "}
        {`${newCharacter.attributes.strength} (${plusMinus(
          newCharacter.attributeMods.strengthMod
        )}${newCharacter.attributeMods.strengthMod})`}
        <br /> Dex:{" "}
        {`${newCharacter.attributes.dexterity} (${plusMinus(
          newCharacter.attributeMods.dexterityMod
        )}${newCharacter.attributeMods.dexterityMod})`}
        <br /> Con:{" "}
        {`${newCharacter.attributes.constitution} (${plusMinus(
          newCharacter.attributeMods.constitutionMod
        )}${newCharacter.attributeMods.constitutionMod})`}
        <br /> Int:{" "}
        {`${newCharacter.attributes.intelligence} (${plusMinus(
          newCharacter.attributeMods.intelligenceMod
        )}${newCharacter.attributeMods.intelligenceMod})`}
        <br /> Wis:{" "}
        {`${newCharacter.attributes.wisdom} (${plusMinus(
          newCharacter.attributeMods.wisdomMod
        )}${newCharacter.attributeMods.wisdomMod})`}
        <br /> Cha:{" "}
        {`${newCharacter.attributes.charisma} (${plusMinus(
          newCharacter.attributeMods.charismaMod
        )}${newCharacter.attributeMods.charismaMod})`}
        <br />{" "}
        {`${capitalizeFirstLetter(newCharacter.pronoun.one)} ${haveHas(
          newCharacter.gender
        )} ${newCharacter.hitpoints}`}{" "}
        hitpoints.
        <br />
        <br />
        {`${capitalizeFirstLetter(newCharacter.pronoun.one)}`} can speak{" "}
        {`${languageString(newCharacter.languages)}`}.
        <br />
        {`${capitalizeFirstLetter(newCharacter.pronoun.one)} ${isAre(
          newCharacter.gender
        )}`}{" "}
        proficient in {`${proficiencyString(newCharacter.proficiencies)}`}{" "}
        <br />
        <br /> {`${capitalizeFirstLetter(newCharacter.pronoun.three)}`}{" "}
        background is as a {`${newCharacter.background.name}`}.
        <br />
        <br />
        {`${newCharacter.traits.personalityOne}`}{" "}
        {`${newCharacter.traits.personalityTwo}`}{" "}
        {`${newCharacter.traits.flaw}`}{" "}
        {`${newCharacter.traits.bond}`}{" "}
        {`${newCharacter.traits.ideal}`}
        {/* <br />{`${spell_slot_string(char_class, char_spell_slots, char_level)}`}
        <br />{`${feature_string(char_features_array)}`} */}
      </div>
    );
};
export default CharSummary;
