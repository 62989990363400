const TankMath = ({inches, fractions}) => {

    const milkInches = [[0, 0, 0, 0, 0, 0, 161, 164, 166, 163, 172, 174, 177, 179, 182, 184, 187, 189, 192, 194, 197, 199, 202, 204, 207, 210, 212, 215, 218, 221, 223, 226],
[229, 231, 234, 237, 239, 242, 245, 247, 250, 253, 256, 259, 262, 265, 268, 271, 274, 277, 280, 283, 286, 289, 292, 295, 298, 301, 304, 307, 310, 313, 316, 319],
[322, 324, 327, 330, 333, 337, 340, 343, 346, 349, 352, 356, 359, 362, 365, 368, 372, 375, 378, 381, 384, 388, 391, 394, 397, 400, 403, 407, 410, 413, 416, 419],
[423, 426, 430, 433, 437, 440, 443, 447, 450, 454, 457, 461, 464, 468, 471, 474, 478, 481, 485, 488, 492, 495, 499, 502, 505, 509, 513, 516, 520, 523, 527, 530],
[534, 538, 541, 545, 548, 552, 556, 559, 563, 566, 570, 573, 577, 581, 584, 588, 591, 595, 599, 603, 606, 610, 614, 618, 622, 625, 629, 633, 637, 640, 644, 648],
[652, 656, 659, 663, 667, 671, 674, 678, 682, 686, 689, 693, 697, 701, 705, 708, 712, 716, 720, 723, 727, 731, 735, 738, 742, 746, 750, 754, 757, 761, 765, 769],
[772, 776, 780, 784, 788, 791, 795, 799, 803, 806, 811, 815, 819, 823, 827, 831, 835, 839, 844, 848, 852, 856, 860, 864, 868, 873, 877, 881, 885, 889, 893, 897],
[901, 906, 910, 914, 918, 922, 926, 930, 935, 939, 943, 947, 951, 955, 959, 964, 968, 972, 976, 980, 984, 988, 992, 997, 1001, 1005, 1009, 1013, 1017, 1021, 1026, 1030],
[1034, 1039, 1043, 1047, 1051, 1056, 1060, 1064, 1069, 1073, 1077, 1082, 1086, 1090, 1094, 1099, 1103, 1107, 1112, 1116, 1120, 1125, 1129, 1133, 1137, 1142, 1146, 1150, 1155, 1159, 1163, 1168],
[1172, 1176, 1180, 1185, 1189, 1193, 1198, 1202, 1206, 1211, 1215, 1219, 1223, 1228, 1232, 1236, 1241, 1245, 1250, 1254, 1259, 1263, 1268, 1272, 1277, 1281, 1286, 1290, 1295, 1299, 1304, 1308],
[1313, 1317, 1321, 1326, 1330, 1335, 1339, 1344, 1348, 1353, 1357, 1362, 1366, 1371, 1375, 1380, 1384, 1389, 1393, 1398, 1402, 1407, 1411, 1416, 1420, 1425, 1429, 1433, 1438, 1442, 1447, 1451],
[1456, 1461, 1465, 1470, 1475, 1479, 1484, 1489, 1493, 1498, 1503, 1507, 1512, 1517, 1521, 1526, 1531, 1536, 1540, 1545, 1550, 1554, 1559, 1564, 1568, 1573, 1578, 1582, 1587, 1592, 1596, 1601],
[1606, 1610, 1615, 1620, 1624, 1629, 1634, 1638, 1643, 1648, 1652, 1657, 1662, 1666, 1671, 1676, 1680, 1685, 1690, 1694, 1699, 1704, 1708, 1713, 1718, 1722, 1727, 1732, 1736, 1741, 1746, 1751],
[1755, 1760, 1765, 1769, 1774, 1774, 1779, 1783, 1788, 1793, 1797, 1802, 1807, 1811, 1816, 1821, 1825, 1830, 1835, 1839, 1844, 1849, 1853, 1858, 1863, 1867, 1872, 1877, 1881, 1886, 1891, 1896],
[1906, 1910, 1915, 1920, 1925, 1930, 1935, 1939, 1944, 1949, 1954, 1959, 1964, 1968, 1973, 1978, 1983, 1988, 1993, 1997, 2002, 2007, 2012, 2017, 2022, 2026, 2031, 2036, 2041, 2046, 2050, 2055],
[2060, 2065, 2070, 2075, 2079, 2084, 2089, 2094, 2099, 2104, 2108, 2113, 2118, 2123, 2128, 2133, 2137, 2142, 2147, 2152, 2157, 2162, 2166, 2171, 2176, 2181, 2186, 2191, 2195, 2200, 2205, 2210],
[2215, 2220, 2224, 2229, 2234, 2239, 2244, 2249, 2253, 2258, 2263, 2268, 2273, 2278, 2282, 2287, 2292, 2297, 2302, 2307, 2311, 2316, 2321, 2326, 2331, 2336, 2341, 2346, 2350, 2355, 2360, 2365],
[2370, 2375, 2380, 2385, 2390, 2394, 2399, 2404, 2409, 2414, 2419, 2424, 2429, 2434, 2438, 2443, 2448, 2453, 2458, 2463, 2468, 2473, 2478, 2482, 2487, 2492, 2497, 2502, 2507, 2512, 2517, 2522],
[2526, 2531, 2536, 2541, 2546, 2551, 2556, 2561, 2565, 2570, 2575, 2580, 2585, 2590, 2595, 2600, 2605, 2609, 2614, 2619, 2624, 2629, 2634, 2639, 2644, 2649, 2653, 2658, 2663, 2668, 2673, 2678],
[2683, 2688, 2693, 2697, 2702, 2707, 2712, 2717, 2722, 2727, 2732, 2737, 2741, 2746, 2751, 2756, 2761, 2766, 2771, 2776, 2780, 2785, 2790, 2795, 2800, 2805, 2810, 2815, 2820, 2824, 2829, 2834],
[2839, 2844, 2849, 2854, 2859, 2864, 2868, 2873, 2878, 2883, 2888, 2893, 2898, 2903, 2908, 2912, 2917, 2922, 2927, 2932, 2937, 2942, 2947, 2952, 2956, 2961, 2966, 2971, 2976, 2981, 2986, 2991],
[2995, 3000, 3005, 3010, 3015, 3020, 3025, 3030, 3035, 3039, 3044, 3049, 3054, 3059, 3064, 3069, 3074, 3079, 3083, 3088, 3093, 3098, 3103, 3108, 3113, 3118, 3123, 3127, 3132, 3137, 3142, 3147],
[3152, 3157, 3162, 3167, 3171, 3176, 3181, 3186, 3191, 3195, 3200, 3205, 3210, 3214, 3218, 3224, 3229, 3234, 3238, 3243, 3248, 3253, 3257, 3262, 3267, 3272, 3277, 3281, 3286, 3291, 3296, 3300],
[3305, 3310, 3315, 3320, 3324, 3329, 3334, 3339, 3343, 3348, 3353, 3358, 3363, 3367, 3372, 3377, 3382, 3386, 3391, 3396, 3401, 3406, 3410, 3415, 3420, 3425, 3429, 3434, 3439, 3444, 3449, 3453],
[3458, 3463, 3468, 3472, 3477, 3482, 3487, 3492, 3496, 3501, 3506, 3511, 3515, 3520, 3525, 3530, 3535, 3539, 3544, 3549, 3554, 3558, 3563, 3568, 3573, 3578, 3582, 3587, 3592, 3597, 3601, 3606],
[3611, 3615, 3620, 3624, 3629, 3633, 3638, 3643, 3647, 3652, 3656, 3661, 3665, 3670, 3675, 3679, 3684, 3688, 3693, 3697, 3702, 3707, 3711, 3716, 3720, 3725, 3729, 3734, 3739, 3743, 3748, 3752],
[3757, 3761, 3766, 3771, 3775, 3780, 3784, 3789, 3794, 3798, 3803, 3807, 3812, 3816, 3821, 3825, 3830, 3834, 3838, 3843, 3847, 3851, 3856, 3860, 3865, 3869, 3873, 3878, 3882, 3887, 3891, 3895],
[3900, 3904, 3909, 3913, 3917, 3922, 3926, 3930, 3935, 3939, 3944, 3948, 3952, 3957, 3961, 3966, 3970, 3974, 3979, 3983, 3988, 3992, 3996, 4001, 4005, 4009, 4014, 4018, 4023, 4027, 4031, 4036],
[4040, 4044, 4048, 4052, 4057, 4061, 4065, 4069, 4074, 4078, 4082, 4086, 4090, 4095, 4099, 4103, 4107, 4111, 4116, 4120, 4124, 4128, 4133, 4137, 4141, 4145, 4149, 4154, 4158, 4162, 4166, 4171],
[4175, 4179, 4183, 4187, 4192, 4196, 4200, 4204, 4208, 4213, 4217, 4221, 4225, 4230, 4234, 4238, 4242, 4246, 4250, 4255, 4259, 4263, 4267, 4271, 4275, 4279, 4283, 4287, 4291, 4295, 4299, 4303],
[4307, 4311, 4315, 4319, 4323, 4328, 4332, 4336, 4340, 4344, 4348, 4352, 4356, 4360, 4364, 4368, 4372, 4376, 4380, 4384, 4388, 4392, 4396, 4401, 4405, 4409, 4413, 4417, 4421, 4425, 4429, 4432],
[4437, 4441, 4445, 4449, 4453, 4457, 4461, 4466, 4470, 4474, 4479, 4483, 4487, 4491, 4496, 4500, 4504, 4509, 4513, 4517, 4522, 4526, 4530, 4534, 4539, 4543, 4547, 4552, 4556, 4560, 4565, 4569],
[4573, 4577, 4582, 4586, 4590, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];

    let milkPounds = milkInches[inches][fractions];
    let liters = Math.round((milkPounds * 0.4401) * 100) / 100;
    let gallons = Math.round(((Math.round((milkPounds * 0.4401) * 100) / 100) / 3.78541) * 100) / 100;

    return {
        milkPounds: milkPounds,
        liters: liters,
        gallons: gallons
      };
}
export default TankMath