import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const Layout = () => {
  return (
    <div className="bg-light-sixty min-h-screen">
      <Header />
      <div>
        <Outlet />
      </div>
      <div className="sticky top-[100vh]"><Footer /></div>
    </div>
  );
};

export default Layout;